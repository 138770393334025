const stealer = {
  en: {
    translation: {
      attack_details: "Attack details",
      add_hidden_results: "Add hidden results",
      nation: "Nation",
      date_from: "Start date",
      date_to: "End date",
      date: "Date",
      domain: "Domain",
      reason: "Stolen data",
      complete: "Complete",
      incomplete: "Incomplete",
      completes: "Completes",
      incompletes: "Incompletes",
      info: "Info",
      all: "All",
      to_manage: "To manage",
      stealer_to_manage: "Stealer to manage",
      stealer_edited_on: "Stealer edited on ",
      country: "Country",
      tags: "Tags",
      duplicate: "Duplicate",
      machine_id: "Machine ID",
      hash: "Hash",
      originalItemId: "Original Item ID",
      so: "SO",
      ip_address: "IP Address",
      date_of_compromise: "Date of compromise",
      acquisition_date: "Acquisition date",
      machine_name: "Machine name",
      reason_visual: "Stolen data",
      username: "Username",
      timezone: "Timezone",
      usage_type: "Usage type",
      city: "City",
      organisation: "Organisation",
      as_name: "ASname",
      filelist: "File list",
      open_details: "Open details",
      path: "Path",
      organization: "Organization",
      urls: "URLS",
      credentials: "Credentials",
      cookies: "Cookies",
      edit_stealer: "Edit stealer",
      ip_geo_data_text: "* Geolocalization data was retrieved by ip, may not be totally accurate",
      operative_system: "Operative system",
      language: "Language",
      record_updated: "Record updated",
      elements_displayed: "Elements displayed:",
      record_updated_text: "Record has been updated succesfully",
      no_stealer_yet: "There are no stealers yet",
      no_customer_associated: "No customer associated to the stealer",
      is_really_duplicate: "Is the element really duplicate?",
      make_stealer_visible: "Make stealer visible",
      hide_stealer: "Hide stealer",
      download_stealers_csv: "Download csv file with stealers data",
      request_details: "Request a detailed incident response report.",
      request_details_text: "Will be mailed to you in about 8 working hours",
      request: "Request",
      print: "Print",
      already_requested_pending: "You have already requested a detailed incident response report.",
      already_requested_pending_text:
        "We are currently processing your request, please, wait a little more",
      already_requested: "You have already requested a detailed incident response report.",
      already_requested_text: "We have processed your request, check your eamil",
      login: "Login",
      open_file_details: "Open details",
      file_details: "File details",
      password: "Password",
      password_strength: "Password strength",
      cookie_name: "Cookie name",
      cookie_value: "Cookie value",
      characters_length: "characters long",
      stolen_credentials: "Stolen digital identity",
      credentials_label1: "stolen",
      credentials_label2: "credentials",
      ytd_label1: "total attacks",
      ytd_label2: "YTD",
      passwords_label1: "stolen",
      passwords_label2: "passwords",
      cookies_label1: "stolen",
      cookies_label2: "cookies",
      last_period_label1: "attacks",
      last_period_label2: "last 3 months",
      error_retrieve_file_from_s3:
        "Error retrieving details file, try again later or contact our support at +39 0583 164 7166",
    },
  },
  it: {
    translation: {
      attack_details: "Dettagli attacco",
      add_hidden_results: "Aggiungi risultati nascosti",
      nation: "Nazione",
      date_from: "Data inizio",
      date_to: "Data fine",
      date: "Data",
      domain: "Dominio",
      reason: "Dato rubato",
      complete: "Completo",
      incomplete: "Incompleto",
      completes: "Completi",
      incompletes: "Incompleti",
      info: "Info",
      all: "Tutti",
      to_manage: "Da gestire",
      stealer_to_manage: "Stealer da gestire",
      stealer_edited_on: "Stealer modificato il ",
      country: "Paese",
      tags: "Tags",
      duplicate: "Duplicate",
      machine_id: "Machine ID",
      hash: "Hash",
      originalItemId: "Original Item ID",
      so: "SO",
      ip_address: "Indirizzo IP",
      date_of_compromise: "Data di compromissione",
      acquisition_date: "Data di acquisizione",
      machine_name: "Nome macchina",
      reason_visual: "Dato rubato",
      username: "Username",
      timezone: "Timezone",
      usage_type: "Usage type",
      city: "Città",
      organisation: "Organizzazione",
      as_name: "ASname",
      filelist: "File list",
      open_details: "Apri dettaglio",
      path: "Path",
      organization: "Organizzazione",
      urls: "URL",
      credentials: "Credenziali",
      cookies: "Cookies",
      edit_stealer: "Modifica stealer",
      ip_geo_data_text:
        "* I dati della localizzazione sono ricavati tramite IP, quindi potrebbero non essere totalmente affidabili",
      operative_system: "Sistema operativo",
      language: "Lingua",
      record_updated: "Riga aggiornata",
      elements_displayed: "Elementi visualizzati:",
      record_updated_text: "La riga è stata aggiornata con successo",
      no_stealer_yet: "Non ci sono ancora instanze di stealer",
      no_customer_associated: "Nessun cliente associato allo stealer",
      is_really_duplicate: "Questo elemento è effettivamente un duplicato?",
      make_stealer_visible: "Rendi lo stealer visibile",
      hide_stealer: "Nascondi lo stealer",
      download_stealers_csv: "Scarica il file csv con i dati degli stealer",
      request_details: "Richiedi un report dettagliato dell'incidente.",
      request_details_text: "Ti sarà mandato in circa 8 ore lavorative",
      request: "Richiedi",
      print: "Stampa",
      already_requested_pending: "Hai già richiesto un report dettagliato dell'incidente.",
      already_requested_pending_text:
        "Stiamo processando la tua richiesta, per favore, aspetta un altro po'",
      already_requested: "Hai già richiesto un report dettagliato dell'incidente.",
      already_requested_text: "Abbiamo processato la tua richiesta, controlla la tua email",
      login: "Login",
      open_file_details: "Apri dettaglio",
      file_details: "Dettagli file",
      password: "Password",
      password_strength: "Efficacia password",
      cookie_name: "Nome cookie",
      cookie_value: "Valore cookie",
      characters_length: "caratteri",
      stolen_credentials: "Identità digitali rubate",
      stolen: "rubate",
      credentials_label1: "credenziali",
      credentials_label2: "rubate",
      ytd_label1: "attacchi totali",
      ytd_label2: "da un anno",
      passwords_label1: "password",
      passwords_label2: "rubate",
      cookies_label1: "cookies",
      cookies_label2: "rubati",
      last_period_label1: "attacchi",
      last_period_label2: "ultimi 3 mesi",
      error_retrieve_file_from_s3:
        "Errore nel recupero del dettaglio del file, riprova in sequito o contatta la nostra assistenza al +39 0583 164 7166",
    },
  },
};

export default stealer;
