/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";

// porp-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-chartjs-2 components
import { Line } from "react-chartjs-2";

// @mui material components
import Card from "@mui/material/Card";
// import Divider from "@mui/material/Divider";
// import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Popover from "@mui/material/Popover";

// ReportsLineChart configurations
import configs from "examples/Charts/LineCharts/ReportsLineChart/configs";

// Import icons
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

function ReportsLineChart({ color, chart }) {
  const { data, options } = configs(chart.labels || [], chart.datasets || {});
  const { t } = useTranslation();

  useEffect(() => {
    const ctx =
      document && document.getElementById("home-credentials-chart")
        ? document.getElementById("home-credentials-chart").getContext("2d")
        : null;

    // Background gradient
    const backgroundGradient = ctx.createLinearGradient(0, 0, 0, 100);
    backgroundGradient.addColorStop(0.5, "rgb(250, 218, 255, 0.7)");
    backgroundGradient.addColorStop(1, "rgb(181, 210, 255, 0.3)");

    // Border gradient
    const borderGradient = ctx.createLinearGradient(0, 0, 0, 100);
    borderGradient.addColorStop(0.4, "rgb(220, 58, 246)");
    borderGradient.addColorStop(1, "rgb(84, 26, 207)");

    if (data && data.datasets && data.datasets[0]) {
      data.datasets[0].backgroundColor = backgroundGradient;
      data.datasets[0].borderColor = borderGradient;
    }
  }, []);

  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Card sx={{ height: "100%", background: "transparent", boxShadow: "none" }}>
      <MDBox pt={2} pl={4}>
        <MDTypography variant="h6" textTransform="capitalize" sx={{ lineHeight: "1" }}>
          {t("analytics.title_stolen_credentials")}
        </MDTypography>
        <MDTypography
          component="div"
          variant="caption"
          fontSize="small"
          fontWeight="regular"
          sx={{ color: "#669FF6" }}
        >
          {t("analytics.last_week")}
        </MDTypography>
        <HelpOutlineIcon
          className="home-question-mark"
          aria-owns={open ? "mouse-over-popover" : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        />
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: "none",
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <MDTypography fontSize="small">{t("analytics.credentials_question_mark")}</MDTypography>
        </Popover>
      </MDBox>
      <MDBox style={{ background: "transparent" }}>
        {useMemo(
          () => (
            <MDBox
              variant="gradient"
              borderRadius="lg"
              coloredShadow="none"
              style={{ background: "transparent" }}
              py={2}
              px={3}
            >
              <Line id="home-credentials-chart" data={data} options={options} />
            </MDBox>
          ),
          [chart, color]
        )}
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of ReportsLineChart
ReportsLineChart.defaultProps = {
  color: "dark",
  // description: "",
};

// Typechecking props for the ReportsLineChart
ReportsLineChart.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  // title: PropTypes.string.isRequired,
  // description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  // date: PropTypes.string.isRequired,
  chart: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.array, PropTypes.object])).isRequired,
};

export default ReportsLineChart;
