import config from "config/config";
import getTokens from "api/getTokens";

const { baseUrl } = config;
/* *********************

********************** */

export default function refreshApikeyTokens(user) {
  const clientUrl = process.env.REACT_APP_ACCESS_CONTROL_ALLOW_ORIGIN || "";
  // console.log("refresh dentro la funzione", refresh.token);
  const endpoint = `${baseUrl}/v1/auth/delete-apikey-tokens`;
  let access = "";
  const tokens = getTokens();
  if (tokens && tokens.access && tokens.access.token) {
    access = tokens.access.token;
  } else {
    window.location.href = "/login";
  }
  const options = {
    method: "POST",
    mode: "cors",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `${clientUrl}`,
      "Access-Control-Allow-Credentials": true,
      Authorization: `bearer ${access}`,
    },
    body: JSON.stringify({
      user: user || {},
    }),
  };
  // console.log('refreshTokens endpoint', {endpoint, refresh, access });
  return fetch(endpoint, options)
    .then((response) =>
      // console.log('refreshTokens response', response);
      response.json()
    )
    .then((responseJson) => responseJson)
    .catch((e) => {
      if (e.message === "Network request failed") {
        // updateNetworkFailed(true);
      }
    });
}
