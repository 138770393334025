// @mui material components
// import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import getStealerById from "api/stealer/getStealerById";
import editStealer from "api/stealer/editStealer";
// import getCookieByExternalId from "api/cookie/getCookieById";
import getFileCookieByExternalId from "api/cookie/getFileCookieById";
import getAsname from "api/asname/getAsname";
import TableContentFull from "layouts/dashboards/detailsStealer/tableContentFull";
import { useMaterialUIController } from "context";

// Material Dashboard 2 PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Icon from "@mui/material/Icon";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import LanIcon from "@mui/icons-material/Lan";
import FolderIcon from "@mui/icons-material/Folder";
import ReportIcon from "@mui/icons-material/Report";
import PersonIcon from "@mui/icons-material/Person";
import DevicesIcon from "@mui/icons-material/Devices";
import GroupsIcon from "@mui/icons-material/Groups";
// import ImportantDevicesIcon from "@mui/icons-material/ImportantDevices";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import BusinessIcon from "@mui/icons-material/Business";
import LanguageIcon from "@mui/icons-material/Language";
import TagIcon from "@mui/icons-material/Tag";
import CookieIcon from "@mui/icons-material/Cookie";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import RouteIcon from "@mui/icons-material/Route";
import BrowserUpdatedIcon from "@mui/icons-material/BrowserUpdated";
import MDInput from "components/MDInput";
import IconButton from "@mui/material/IconButton";
import SweetAlert from "react-bootstrap-sweetalert";
import Tooltip from "@mui/material/Tooltip";
import moment from "moment";

// Import images
// import Calendar from "assets/images/credentials-details-icons/calendar.png";
// import Location from "assets/images/credentials-details-icons/location.png";
import Report from "assets/images/credentials-details-icons/report.png";

function Stealers() {
  // eslint-disable-next-line
  const [controller, dispatch] = useMaterialUIController();
  const [stealer, setStealer] = useState([]);
  const [openStealerFromS3, setOpenStealerFromS3] = useState(false);
  const [stealerFromS3, setStealerFromS3] = useState(null);
  const [showDomains, setShowDomains] = useState(false);
  const [showFileList, setShowFileList] = useState(false);
  const [editing, setEditing] = useState(false);
  const [popupSuccess, setPopupSuccess] = useState(false);
  const [errorRetrieveFileFromS3, setErrorRetrieveFileFromS3] = useState(false);
  const [IPAddress, setIPAddress] = useState("");
  const [Country, setCountry] = useState("");
  const [machineID, setMachineID] = useState("");
  const [machineName, setMachineName] = useState("");
  const [username, setUsername] = useState("");
  const [OS, setOS] = useState("");
  const [asData, setAsData] = useState(null);
  const [isHidden, setIsHidden] = useState(false);
  const [path, setPath] = useState("");
  const [timezone, setTimezone] = useState("");
  // eslint-disable-next-line
  const [showPasswordsAdmin, setShowPasswordsAdmin] = useState(null);
  // eslint-disable-next-line
  const [showCookiesAdmin, setShowCookiesAdmin] = useState(null);
  // eslint-disable-next-line
  const { client, user } = controller;
  const { t } = useTranslation();

  useEffect(() => {
    const url = window && window.location && window.location.href ? window.location.href : "";
    let stealerId = "";
    if (url && url.length > 0 && url.includes("?")) {
      const arrayDivide = url.split("?");
      if (arrayDivide && arrayDivide.length > 0) {
        // eslint-disable-next-line
        stealerId = arrayDivide[1];
      }
    }
    getStealerById(stealerId)
      .then((stealerFound) => {
        if (stealerFound) {
          setStealer(stealerFound);
          if (stealerFound.originalItemId) {
            if (
              stealerFound.date &&
              stealerFound.originalItemId &&
              stealerFound.pathS3 &&
              stealerFound.indexKey
            ) {
              // const yearHash = moment(stealerFound.date).format("YYYY");
              // const weekHash = moment(stealerFound.date).format("W");
              getFileCookieByExternalId(
                stealerFound.pathS3.replaceAll("/", "_"),
                stealerFound.indexKey
                // `payloads-${yearHash}-${weekHash}-${stealerFound.originalItemId}`
              )
                .then((stealerFile) => {
                  console.log("stealer file --->", stealerFile);
                  if (stealerFile) {
                    if (stealerFile) {
                      setStealerFromS3(stealerFile);
                    }
                    let newStealerFile = { ...stealerFound, ...stealerFile };
                    if (stealerFile.cookie && stealerFile.cookie.length > 0) {
                      const domains = [];
                      if (stealerFile.cookie && stealerFile.cookie.length > 0) {
                        const cookiesOrdered = [].concat(stealerFile.cookie);
                        cookiesOrdered.sort((a, b) => {
                          let firstPresent = false;
                          firstPresent =
                            stealerFound.cookies &&
                            stealerFound.cookies.some((sfc) => sfc.domain === a.domain);
                          let secondPresent = false;
                          secondPresent =
                            stealerFound.cookies &&
                            stealerFound.cookies.some((sfc) => sfc.domain === b.domain);
                          if (firstPresent && !secondPresent) {
                            return -1;
                          }
                          if (secondPresent && !firstPresent) {
                            return 1;
                          }
                          return a.domain.localeCompare(b.domain);
                        });
                        Object.assign(newStealerFile, { cookie: cookiesOrdered });
                        stealerFile.cookie.forEach((ck) => {
                          if (ck.domain && !domains.includes(ck.domain)) {
                            domains.push(ck.domain);
                          }
                        });
                      }
                      if (stealerFile.passwords && stealerFile.passwords.length > 0) {
                        stealerFile.passwords.forEach((pw) => {
                          if (pw.URL && !domains.includes(pw.URL)) {
                            domains.push(pw.URL);
                          }
                        });
                        const passwordsOrdered = [].concat(stealerFile.passwords);
                        passwordsOrdered.sort((a, b) => {
                          let firstPresent = false;
                          firstPresent =
                            stealerFound.passwords &&
                            stealerFound.passwords.some((sfp) => sfp.URL === a.domain);
                          let secondPresent = false;
                          secondPresent =
                            stealerFound.passwords &&
                            stealerFound.passwords.some((sfp) => sfp.URL === b.domain);
                          if (firstPresent && !secondPresent) {
                            return -1;
                          }
                          if (secondPresent && !firstPresent) {
                            return 1;
                          }
                          return a.URL.localeCompare(b.URL);
                        });
                        Object.assign(newStealerFile, { passwords: passwordsOrdered });
                      }
                      if (domains.length > 0) {
                        if (client && client.domains && client.domains.length > 0) {
                          newStealerFile.domains = domains.filter((el) =>
                            client.domains.some((clientUrl) => clientUrl === el)
                          );
                          newStealerFile.domains = domains;
                        }
                        setStealer(newStealerFile);
                        if (newStealerFile.asNumber) {
                          getAsname(newStealerFile.asNumber)
                            .then((asnumber) => {
                              if (asnumber && asnumber.asnumber) {
                                setAsData(asnumber);
                              }
                            })
                            // eslint-disable-next-line
                          .catch((err) => console.log("error", err));
                        }
                      } else if (stealerFile) {
                        newStealerFile = { ...stealerFound, ...stealerFile };
                        setStealer(newStealerFile);
                      }
                    }
                    setStealer(newStealerFile);
                  }
                  if (
                    stealerFile &&
                    stealerFile.error &&
                    stealerFile.status &&
                    stealerFile.status === "500"
                  ) {
                    setErrorRetrieveFileFromS3(true);
                  }
                })
                // eslint-disable-next-line
                .catch((err) => console.log("error", err));
            }
          }
        }
      })
      // eslint-disable-next-line
  .catch((err) => console.log("error", err));
  }, []);

  function getDomainShown() {
    let output = stealer.domains[0].length > 30 ? `${stealer.domains[0]}` : stealer.domains[0];
    if (
      (client && client.passwords && client.passwords.length > 0) ||
      (client.cookies && client.cookies.length) > 0
    ) {
      const indexTofind = stealer.domains.findIndex(
        (el) =>
          (client.passwords &&
            client.passwords.length > 0 &&
            client.passwords.some((inner) => inner === el)) ||
          (client.cookie &&
            client.cookie.length > 0 &&
            client.cookie.some((inner) => inner.url === el))
      );
      if (indexTofind >= 0) {
        output =
          stealer.domains[indexTofind].length > 30
            ? `${stealer.domains[indexTofind]}`
            : stealer.domains[indexTofind];
      } else {
        output = "";
      }
    } else if (stealer.domains.length > 1) {
      output += ` + ${stealer.domains.length - 1}`;
    }
    return output;
  }

  function getPassword(passwords) {
    return passwords.map((pass, index) => {
      if (client.passwords.some((cli) => pass.URL && pass.URL.includes(cli))) {
        return (
          <div className="detailsStealerTableRow" key={`password-for-client-${index.toString()}`}>
            <span className="label-cell">{pass.username}</span>
            <span className="elem-cell">
              {pass && pass.password && pass.password.length > 8
                ? `${pass.password.substring(0, 2)}****${pass.password.slice(-2)}`
                : "******"}
            </span>
          </div>
        );
      }
      return null;
    });
  }

  function getCookieToView(cookies) {
    return cookies.map((cook, index) => {
      if (
        client.cookies.some(
          (cli) =>
            /* cli.url === cook.domain */
            cook.domain && cli.url && cook.domain.includes(cli.url) && cli.name === cook.name
        )
      ) {
        return (
          <div className="detailsStealerTableRow" key={`cookie-for-client-${index.toString()}`}>
            <span className="label-cell">{cook.domain}</span>
            <span className="elem-cell">{cook.name}</span>
          </div>
        );
      }
      return null;
    });
  }

  function getTags() {
    return stealer.tags.map((tag, index) => (
      <span key={`stealer-details-tag-${index.toString()}`}>{tag}</span>
    ));
  }

  function getFileList(fileList) {
    return fileList.map((file, fileIndex) => {
      if (file.children && file.children.length > 0) {
        return (
          <div
            key={`file-list-folder-row-${fileIndex.toString()}`}
            className="file-list-folder-row"
          >
            <div className="file-list-folder-icon-label-container">
              <Icon fontSize="medium">folder</Icon>
              <span className="file-list-folder-label">{file.name ? file.name : ""}</span>
            </div>
            <div className="file-list-folder-children-container">{getFileList(file.children)}</div>
          </div>
        );
      }
      if (file.name) {
        return (
          <div key={`file-list-file-row-${fileIndex.toString()}`} className="file-list-folder-row">
            <div className="file-list-folder-icon-label-container">
              <Icon fontSize="medium">article</Icon>
              <span className="file-list-folder-label">{file.name ? file.name : ""}</span>
            </div>
          </div>
        );
      }
      return null;
    });
  }

  function getInput(label, value, operation) {
    return (
      <div style={{ marginBottom: "20px" }}>
        <MDInput
          type="input"
          label={label}
          fullWidth
          value={value}
          onChange={(e) => {
            operation(e.target.value);
          }}
        />
      </div>
    );
  }

  function getElementToEdit() {
    return (
      <SweetAlert
        style={{ display: "block", position: "relative", textAlign: "left" }}
        title=""
        onConfirm={() => {
          // console.log("ora devo modificare");
          const newObject = { updateDate: new Date() };
          if (IPAddress) {
            Object.assign(newObject, { IPAddress });
          }
          if (Country) {
            Object.assign(newObject, { nation: Country });
          }
          if (machineID) {
            Object.assign(newObject, { machineID });
          }
          if (machineName) {
            Object.assign(newObject, { machineName });
          }
          if (username) {
            Object.assign(newObject, { username });
          }
          if (OS) {
            Object.assign(newObject, { OS });
          }
          if (isHidden) {
            Object.assign(newObject, { isHidden: true });
          } else {
            Object.assign(newObject, { isHidden: false });
          }
          editStealer(newObject, stealer.id)
            .then(() => {
              setEditing(false);
              setPopupSuccess(true);
              setStealer(Object.assign(stealer, newObject));
            })
            // eslint-disable-next-line
            .catch((err) => console.log("error", err));
        }}
        onCancel={() => {
          setEditing(false);
          if (stealer.IPAddress) {
            setIPAddress(stealer.IPAddress);
          }
          if (stealer.nation) {
            setCountry(stealer.nation);
          }
          if (stealer.machineID) {
            setMachineID(stealer.machineID);
          }
          if (stealer.machineName) {
            setMachineName(stealer.machineName);
          }
          if (stealer.username) {
            setUsername(stealer.username);
          }
          if (stealer.OS) {
            setOS(stealer.OS);
          }
          if (stealer.path) {
            setPath(stealer.path);
          }
          if (stealer.timezone) {
            setTimezone(stealer.timezone);
          }
          if (stealer.isHidden) {
            setIsHidden(stealer.isHidden);
          } else {
            setIsHidden(false);
          }
        }}
        closeOnClickOutside
        showCancel
        cancelBtnText={t("cancel")}
        confirmBtnText={t("save")}
        confirmBtnCssClass="popupCloseButton"
        confirmBtnStyle={{
          boxShadow: "none",
        }}
        cancelBtnCssClass="popupCloseButton"
        cancelBtnStyle={{
          boxShadow: "none",
        }}
      >
        <div style={{ textAlign: "left" }}>
          <div style={{ fontSize: "16px", marginBottom: "20px" }}>
            {stealer.date
              ? `${moment(stealer.date).format("DD")}/${moment(stealer.date).format("MM")}/${moment(
                  stealer.date
                ).format("YYYY")}`
              : ""}
          </div>
          <h2 style={{ fontSize: "20px", marginBottom: "30px" }}>
            {stealer.domains && stealer.domains.length > 0 ? getDomainShown(stealer.domains) : ""}
          </h2>
          {getInput("IP Address", IPAddress, setIPAddress)}
          {getInput("Nation", Country, setCountry)}
          {getInput("Machine ID", machineID, setMachineID)}
          {getInput("Machine Name", machineName, setMachineName)}
          {getInput("Username", username, setUsername)}
          {getInput("OS", OS, setOS)}
          {getInput("Path", path, setPath)}
          {getInput("Timezone", timezone, setTimezone)}
          {/* bottone per la modifica della visibilità dello stealer */}
          <div>
            <IconButton
              onClick={() => {
                setIsHidden(!isHidden);
              }}
              size="small"
              disableRipple
            >
              {isHidden ? (
                <>
                  <VisibilityOffIcon fontSize="medium" style={{ marginRight: "10px" }} />{" "}
                  {t("stealers.make_stealer_visible")}
                </>
              ) : (
                <>
                  <Icon fontSize="medium" style={{ marginRight: "10px" }}>
                    visibility
                  </Icon>{" "}
                  {t("stealers.hide_stealer")}
                </>
              )}
            </IconButton>
          </div>
        </div>
        <div className="popupButtonEdit">
          <Tooltip title={t("close")} placement="top">
            <IconButton
              onClick={() => {
                setEditing(false);
              }}
              size="small"
              disableRipple
            >
              <Icon fontSize="medium">close</Icon>
            </IconButton>
          </Tooltip>
        </div>
      </SweetAlert>
    );
  }

  if (!stealer) {
    return null;
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {stealer !== "error" ? (
        <>
          <div className="popupInfoContainer">
            {showDomains ? (
              <SweetAlert
                style={{ display: "block", position: "relative", textAlign: "left" }}
                title=""
                onConfirm={() => {
                  setShowDomains(false);
                }}
                confirmBtnText={t("close")}
                confirmBtnCssClass="popupCloseButton"
                confirmBtnStyle={{
                  boxShadow: "none",
                }}
              >
                {stealer.domains && stealer.domains.length > 0 ? (
                  <div className="detail-domains-to-show">
                    {stealer.domains.map((dom, ind) => (
                      <div
                        className={`detail-domain-single ${ind % 2 === 0 ? "" : "even"}`}
                        key={`dom-to-show-${ind.toString()}`}
                      >
                        {dom}
                      </div>
                    ))}
                  </div>
                ) : null}
                <div className="popupButtonEdit">
                  <Tooltip title={t("close")} placement="top">
                    <IconButton
                      onClick={() => {
                        setShowDomains(false);
                      }}
                      size="small"
                      disableRipple
                    >
                      <Icon fontSize="medium">close</Icon>
                    </IconButton>
                  </Tooltip>
                </div>
              </SweetAlert>
            ) : null}
            {showFileList ? (
              <SweetAlert
                style={{ display: "block", position: "relative", textAlign: "left" }}
                title=""
                onConfirm={() => {
                  setShowFileList(false);
                }}
                confirmBtnText={t("close")}
                confirmBtnCssClass="popupCloseButton"
                confirmBtnStyle={{
                  boxShadow: "none",
                }}
              >
                <div style={{ textAlign: "left" }}>
                  {getFileList(
                    stealer.archiveFileList && stealer.archiveFileList.legnth > 0
                      ? stealer.archiveFileList
                      : stealer.fileList
                  )}
                </div>
                <div className="popupButtonEdit">
                  <Tooltip title={t("close")} placement="top">
                    <IconButton
                      onClick={() => {
                        setShowFileList(false);
                      }}
                      size="small"
                      disableRipple
                    >
                      <Icon fontSize="medium">close</Icon>
                    </IconButton>
                  </Tooltip>
                </div>
              </SweetAlert>
            ) : null}
            {openStealerFromS3 ? (
              <SweetAlert
                style={{ display: "block" }}
                title={t("stealers.file_details")}
                onConfirm={() => {
                  setOpenStealerFromS3(false);
                }}
                confirmBtnText="Ok"
                confirmBtnCssClass="popupCloseButton"
                confirmBtnStyle={{
                  boxShadow: "none",
                }}
              >
                {JSON.stringify(stealerFromS3, null, 2)}
              </SweetAlert>
            ) : null}
            {editing && stealer.id ? getElementToEdit() : null}
            {popupSuccess ? (
              <SweetAlert
                style={{ display: "block" }}
                title={t("stealers.record_updated")}
                success
                onConfirm={() => {
                  setPopupSuccess(false);
                }}
                confirmBtnText="Ok"
                confirmBtnCssClass="popupCloseButton"
                confirmBtnStyle={{
                  boxShadow: "none",
                }}
              >
                {t("stealers.record_updated_text")}
              </SweetAlert>
            ) : null}
          </div>
          <div className="attack-details-container">
            <h2 className="attack-details-title">{t("stealers.attack_details")}</h2>
          </div>
          <div className="stealer-details-container">
            {stealer.date ? (
              <div className="popupLabel">
                <CalendarTodayIcon fontSize="medium" style={{ marginRight: "10px" }} />
                {/* <img src={Calendar} alt="calendar-img" className="details-pages-icons" /> */}
                <span style={{ fontWeight: "bold" }}>{t(`stealers.date_of_compromise`)}</span>
                <span>
                  {moment(new Date(stealer.date)).format("DD")}/
                  {moment(new Date(stealer.date)).format("MM")}/
                  {moment(new Date(stealer.date)).format("YYYY")}
                  {" - "}
                  {moment(new Date(stealer.date)).format("HH")}:
                  {moment(new Date(stealer.date)).format("mm")}
                  {stealer.timezone ? ` (${stealer.timezone})` : null}
                </span>
              </div>
            ) : null}
            {stealer.dateProcessed ? (
              <div className="popupLabel">
                <BrowserUpdatedIcon fontSize="medium" style={{ marginRight: "10px" }} />
                {/* <img src={Calendar} alt="calendar-img" className="details-pages-icons" /> */}
                <span style={{ fontWeight: "bold" }}>{t(`stealers.acquisition_date`)}</span>
                <span>
                  {moment.utc(stealer.dateProcessed).format("DD")}/
                  {moment.utc(new Date(stealer.dateProcessed)).format("MM")}/
                  {moment.utc(new Date(stealer.dateProcessed)).format("YYYY")}
                  {" - "}
                  {moment.utc(new Date(stealer.dateProcessed)).format("HH")}:
                  {moment.utc(new Date(stealer.dateProcessed)).format("mm")} UTC
                </span>
              </div>
            ) : null}
            {stealer.countryCode ? (
              <div className="popupLabel">
                <LocationOnIcon fontSize="medium" style={{ marginRight: "10px" }} />
                {/* <img src={Location} alt="calendar-img" className="details-pages-icons" /> */}
                {/* <span>{t("stealers.country")}: </span> */}
                <span style={{ fontWeight: "bold" }}>
                  {t(`countryCode.${stealer.countryCode}`)}
                </span>
                {stealer.geoInfo && stealer.geoInfo.stateProv ? (
                  <span> - {stealer.geoInfo.stateProv}</span>
                ) : null}
                {stealer.geoInfo && stealer.geoInfo.district ? (
                  <span> - {stealer.geoInfo.district}*</span>
                ) : null}
              </div>
            ) : null}
            {/* {stealer.language ? (
              <div className="popupLabel">
                <Icon fontSize="medium">flag</Icon>
                <span>{t("stealers.language")}: </span>
                <span style={{ fontWeight: "bold" }}>{stealer.language}</span>
              </div>
            ) : null} */}
            {/* {stealer.OS ? (
              <div className="popupLabel">
                <Icon fontSize="medium">computer</Icon>
                <span>{t("stealers.operative_system")}: </span>
                <span style={{ fontWeight: "bold" }}>{stealer.OS}</span>
              </div>
            ) : null} */}
            {/* {stealer.IPAddress || stealer.IP ? (
              <div className="popupLabel">
                <LanIcon fontSize="medium" style={{ marginRight: "10px" }} />
                <span>{t("stealers.ip_address")}: </span>
                <span style={{ fontWeight: "bold" }}>{stealer.IPAddress || stealer.IP}</span>
              </div>
            ) : null}
            {stealer.machineID ? (
              <div className="popupLabel">
                <ImportantDevicesIcon fontSize="medium" style={{ marginRight: "10px" }} />
                <span>{t("stealers.machine_id")}: </span>
                <span
                  style={{
                    fontWeight: "bold",
                    maxWidth: "calc(100% - 170px)",
                    display: "block",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {stealer.machineID}
                </span>
              </div>
            ) : null} */}
            {stealer.machineName ? (
              <div className="popupLabel">
                <DevicesIcon fontSize="medium" style={{ marginRight: "10px" }} />
                <span>{t("stealers.machine_name")}: </span>
                <span style={{ fontWeight: "bold" }}>{stealer.machineName}</span>
              </div>
            ) : null}
            {stealer.reason ? (
              <div className="popupLabel">
                <ReportIcon fontSize="medium" style={{ marginRight: "10px" }} />
                <span>{t("stealers.reason_visual")}: </span>
                <span style={{ fontWeight: "bold" }}>{stealer.reason}</span>
              </div>
            ) : null}
            {stealer.username ? (
              <div className="popupLabel">
                <PersonIcon fontSize="medium" style={{ marginRight: "10px" }} />
                <span>{t("stealers.username")}: </span>
                <span style={{ fontWeight: "bold" }}>{stealer.username}</span>
              </div>
            ) : null}
            {stealer.timezone ? (
              <div className="popupLabel">
                <MoreTimeIcon fontSize="medium" style={{ marginRight: "10px" }} />
                <span>{t("stealers.timezone")}: </span>
                <span style={{ fontWeight: "bold" }}>{stealer.timezone}</span>
              </div>
            ) : null}
            {stealer.tags && stealer.tags.length ? (
              <div className="popupLabel">
                <TagIcon fontSize="medium" style={{ marginRight: "10px" }} />
                <span>{t("stealers.tags")}: </span>
                <span style={{ fontWeight: "bold" }}>{getTags()}</span>
              </div>
            ) : null}
            {stealer.usagetype ? (
              <div className="popupLabel">
                <BusinessIcon fontSize="medium" style={{ marginRight: "10px" }} />
                <span>{t("stealers.usage_type")}: </span>
                <span style={{ fontWeight: "bold" }}>{stealer.usagetype}</span>
              </div>
            ) : null}
            {stealer.city ? (
              <div className="popupLabel">
                <LocationOnIcon fontSize="medium" style={{ marginRight: "10px" }} />
                <span>{t("stealers.city")}: </span>
                <span style={{ fontWeight: "bold" }}>{stealer.city}*</span>
              </div>
            ) : null}
            {stealer.organisation ? (
              <div className="popupLabel">
                <BusinessIcon fontSize="medium" style={{ marginRight: "10px" }} />
                <span>{t("stealers.organisation")}: </span>
                <span style={{ fontWeight: "bold" }}>{stealer.organisation}*</span>
              </div>
            ) : null}
            {asData && asData.asname ? (
              <div className="popupLabel">
                <LanIcon fontSize="medium" style={{ marginRight: "10px" }} />
                <span>{t("stealers.as_name")}: </span>
                <span style={{ fontWeight: "bold" }}>{asData.asname}</span>
              </div>
            ) : null}
            {stealer && stealer.organization ? (
              <div className="popupLabel">
                <GroupsIcon fontSize="medium" style={{ marginRight: "10px" }} />
                <span>{t("stealers.organization")}: </span>
                <span style={{ fontWeight: "bold" }}>{stealer.organization}</span>
              </div>
            ) : null}
            {(stealer.fileList && stealer.fileList.length > 0) ||
            (stealer.archiveFileList && stealer.archiveFileList.length > 0) ? (
              <div className="popupLabel">
                <FolderIcon fontSize="medium" style={{ marginRight: "10px" }} />
                <span>{t("stealers.filelist")}: </span>
                <span style={{ fontWeight: "bold" }}>
                  <button
                    type="button"
                    className="emply-label-button"
                    style={{ fontWeight: "bold" }}
                    onClick={() => {
                      setShowFileList(true);
                    }}
                  >
                    {t("stealers.open_details")}
                  </button>
                </span>
              </div>
            ) : null}
            {stealer.domains && stealer.domains.length > 0 ? (
              <div className="popupLabel">
                <LanguageIcon fontSize="medium" style={{ marginRight: "10px" }} />
                <span>{t("stealers.urls")}: </span>
                <button
                  type="button"
                  style={{ fontWeight: "bold" }}
                  className="emply-label-button"
                  onClick={() => {
                    setShowDomains(true);
                  }}
                >
                  {getDomainShown()}
                </button>
              </div>
            ) : (
              ""
            )}
            {stealer.path ? (
              <div className="popupLabel">
                <RouteIcon fontSize="medium" style={{ marginRight: "10px" }} />
                <span>Path: </span>
                <span style={{ fontWeight: "bold" }}>{stealer.path}</span>
              </div>
            ) : null}
          </div>
          {/* {stealer.path ? (
            <div className="popupLabel" style={{ marginTop: "15px" }}>
              <FolderIcon fontSize="medium" style={{ marginRight: "10px" }} />
              <span>{t("stealers.path")}: </span>
              <span style={{ fontWeight: "bold" }}>{stealer.path}</span>
            </div>
          ) : null} */}
          {/* {stealer && stealer.id ? (
            <RequestReport
              stealer={stealer.id}
              user={user.id}
              pathS3={stealer.pathS3 || ""}
              indexKey={stealer.indexKey || 0}
            />
          ) : null} */}
          {stealer.passwords && stealer.passwords.length > 0 ? (
            <TableContentFull elements={stealer.passwords} type="passwords" />
          ) : null}
          {stealer.cookies && stealer.cookies.length > 0 ? (
            <TableContentFull elements={stealer.cookies} type="cookies" />
          ) : null}
          {stealer.reason &&
          stealer.reason.includes("password") &&
          stealer.passwords &&
          stealer.passwords.length > 0 &&
          client &&
          client.domains &&
          client.domains.length > 0 ? (
            <>
              <div className="popupLabel" style={{ marginTop: "50px" }}>
                <Icon fontSize="medium" style={{ marginRight: "10px" }}>
                  visibility
                </Icon>
                <img src={Report} alt="report-img" className="details-pages-icons" />
                <span>{t("stealers.credentials")}: </span>
              </div>
              <div className="detailsStealerTable">{getPassword(stealer.passwords)}</div>
            </>
          ) : null}
          {stealer.reason &&
          stealer.reason.includes("cookie") &&
          stealer.cookie &&
          stealer.cookie.length > 0 &&
          client &&
          client.domains &&
          client.domains.length > 0 ? (
            <>
              <div className="popupLabel" style={{ marginTop: "50px" }}>
                <CookieIcon fontSize="medium" style={{ marginRight: "10px" }} />
                <span>{t("stealers.cookies")}: </span>
              </div>
              <div className="detailsStealerTable">{getCookieToView(stealer.cookie)}</div>
            </>
          ) : null}
          {/* bottone per la modifica dello stealer */}
          <div>
            <IconButton
              onClick={() => {
                setEditing(true);
                if (stealer.IPAddress) {
                  setIPAddress(stealer.IPAddress);
                }
                if (stealer.nation) {
                  setCountry(stealer.nation);
                }
                if (stealer.machineID) {
                  setMachineID(stealer.machineID);
                }
                if (stealer.machineName) {
                  setMachineName(stealer.machineName);
                }
                if (stealer.username) {
                  setUsername(stealer.username);
                }
                if (stealer.OS) {
                  setOS(stealer.OS);
                }
                if (stealer.path) {
                  setPath(stealer.path);
                }
                if (stealer.timezone) {
                  setTimezone(stealer.timezone);
                }
                if (stealer.isHidden) {
                  setIsHidden(stealer.isHidden);
                }
              }}
              size="small"
              disableRipple
              style={{
                fontWeight: "bold",
                padding: "10px 30px",
                marginTop: "30px",
                border: "solid 1px #344767",
                color: "#344767",
                borderRadius: "5px",
              }}
            >
              <Icon fontSize="medium" style={{ marginRight: "10px" }}>
                edit
              </Icon>{" "}
              {t("stealers.edit_stealer")}
            </IconButton>
          </div>
          {user && user.role && user.role === "admin" && stealerFromS3 ? (
            <div style={{ marginTop: "50px" }}>
              <button
                type="button"
                className="request-report-button"
                onClick={() => {
                  setOpenStealerFromS3(true);
                }}
              >
                {t("stealers.open_file_details")}
              </button>
            </div>
          ) : null}
          <div className="stealer-details-notes">{t("stealers.ip_geo_data_text")}</div>
          {errorRetrieveFileFromS3 ? (
            <div className="stealer-details-notes error-get-file-from-s3">
              {t("stealers.error_retrieve_file_from_s3")}
            </div>
          ) : null}
        </>
      ) : (
        <h4>Invalid id</h4>
      )}
    </DashboardLayout>
  );
}

export default Stealers;
