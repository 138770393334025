const configuration = {
  development: {
    baseUrl: "http://localhost:3008",
    clientUrl: "http://localhost:3000",
    // baseUrl: "http://192.168.1.8:3005",
  },
  staging: {
    baseUrl: "https://backoffice-backend.srv01.defenda.dev",
    clientUrl: "https://backoffice-frontend.srv01.defenda.dev",
  },
  production: {
    baseUrl: "https://api.defenda.tech",
    clientUrl: "https://dash.defenda.tech",
  },
};

export default configuration;
