import config from "config/config";
import getTokens from "api/getTokens";

const { baseUrl } = config;

/* *********************
  email
  password
********************** */

export default function getIncompleteStealers(
  page,
  limit = 50,
  nation = "",
  domain = "",
  reason = "",
  hash = "",
  showHidden = false,
  dateFrom = "",
  dateTo = "",
  orderBy = ""
) {
  const clientUrl = process.env.REACT_APP_ACCESS_CONTROL_ALLOW_ORIGIN || "";
  let order = "sortDisabled";
  if (orderBy === "domain") {
    order = "domain:asc";
  } else if (orderBy === "domainReverse") {
    order = "domain:desc";
  } else if (orderBy === "date") {
    order = "date:asc";
  } else if (orderBy === "dateReverse") {
    order = "date:desc";
  } else if (orderBy === "nation") {
    order = "countryCode:asc";
  } else if (orderBy === "nationReverse") {
    order = "countryCode:desc";
  } else if (orderBy === "hash") {
    order = "filelistHash:asc";
  } else if (orderBy === "hashReverse") {
    order = "filelistHash:desc";
  }
  const endpoint = `${baseUrl}/v1/stealers/incomplete/?limit=${limit}&page=${page}&sortBy=${order}&nation=${nation}&domain=${domain}&reason=${reason}&hash=${hash}&showHidden=${showHidden}&dateFrom=${dateFrom}&dateTo=${dateTo}`;
  let access = "";
  const tokens = getTokens();
  if (tokens && tokens.access && tokens.access.token) {
    access = tokens.access.token;
  } else {
    window.location.href = "/login";
  }
  const options = {
    method: "GET",
    mode: "cors",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `${clientUrl}`,
      "Access-Control-Allow-Credentials": true,
      Authorization: `bearer ${access}`,
    },
  };
  // console.log('login endpoint', {endpoint, BODY });
  return fetch(endpoint, options)
    .then((response) => {
      // console.log('login response', response);
      if (response.status !== 200 && response.status !== 201 && response.status !== 204) {
        throw new Error();
      }
      return response.json();
    })
    .catch((e) => {
      // eslint-disable-next-line
      console.log("error", e);
    });
}
