/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";
import { useTranslation } from "react-i18next";

import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

import logo from "assets/images/logo-light.png";

import forgotPassword from "api/auth/forgotPassword";
import addLog from "api/logs/addLog";
import MDTypography from "components/MDTypography";

// Import icons
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  // const [error, setError] = useState(false);
  const [passwordSent, setpasswordSent] = useState(false);
  const [invalidPasswordError, setInvalidPasswordError] = useState(false);
  const { t } = useTranslation();

  const validateEmail = (em) =>
    String(em)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );

  const requestChangePassword = () =>
    forgotPassword(email)
      .then((resp) => {
        // eslint-disable-next-line
        console.log("login success", resp);
        // setError(false);
        const logToSend = {
          email,
          type: "Forgot password",
          date: new Date(),
        };
        addLog(logToSend);
        if (validateEmail(email)) {
          setpasswordSent(true);
          setInvalidPasswordError(false);
        } else {
          setInvalidPasswordError(true);
        }
      })
      .catch((err) => {
        // eslint-disable-next-line
        console.log("error login", err);
        // setError(true);
        const logToSend = {
          email,
          type: "Forgot Password Failed",
          date: new Date(),
        };
        addLog(logToSend);
      });
  // console.log("error?", error);

  // const handleSetRememberMe = () => setRememberMe(!rememberMe);
  // TODO gestire errori

  return (
    <BasicLayout>
      <Card>
        <img src={logo} className="auth-defenda-logo" alt="defenda-logo" />
        <MDBox pt={5} pb={3} px={3}>
          <MDBox component="form" role="form">
            {!passwordSent ? (
              <>
                <MDBox mb={2}>
                  <MDInput
                    label="Email"
                    autoFocus
                    fullWidth
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && !passwordSent) {
                        e.preventDefault();
                        requestChangePassword();
                      }
                    }}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </MDBox>
                <MDBox mt={4} mb={1}>
                  <MDButton
                    style={{ background: "#669FF6", color: "white" }}
                    fullWidth
                    onClick={() => {
                      requestChangePassword();
                    }}
                  >
                    {t("auth.reset_password")}
                  </MDButton>
                </MDBox>
              </>
            ) : (
              <MDBox sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <CheckCircleOutlineIcon
                  sx={{
                    fontSize: "18px !important",
                    marginRight: "5px",
                    color: "rgb(102, 159, 246)",
                  }}
                />
                <MDTypography variant="caption">{t("auth.email_sent")}</MDTypography>
              </MDBox>
            )}
            {invalidPasswordError ? (
              <MDBox mt={4} mb={1}>
                <MDTypography variant="h6">{t("auth.forgot_password_error_title")}</MDTypography>
                <MDTypography fontSize="small">{t("auth.forgot_password_error_text")}</MDTypography>
              </MDBox>
            ) : null}
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default ForgotPassword;
