import { useState, useEffect, useRef } from "react";

// @mui material components
import Card from "@mui/material/Card";
// import CardHeader from '@mui/material/CardHeader';
// import Switch from "@mui/material/Switch";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
import { useMaterialUIController } from "context";

import getTokens from "api/getTokens";

import mfaQrCode from "api/auth/mfaQrCode";

// Two-factor authtentication input
import AuthCode from "react-auth-code-input";

// Images
import twoFactor from "api/auth/twoFactor";
import addLog from "api/logs/addLog";
import getUser from "api/users/getUser";
import logo from "assets/images/logo-light.png";

// Icons
// import ArrowRightIcon from "@mui/icons-material/ArrowRight";
// import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
// import Popover from "@mui/material/Popover";
import DoneIcon from "@mui/icons-material/Done";

// Translation
// import { useTranslation } from "react-i18next";

function Mfa() {
  const ref = useRef(null);
  // eslint-disable-next-line
  const [controller, dispatch] = useMaterialUIController();
  const { user } = controller;
  const [mfaSecret, setMfaSecret] = useState(user && user.mfaSecret ? user.mfaSecret : "");
  const [twoFactorCode, setTwoFactorCode] = useState("");
  const [qrCode, setQrCode] = useState(null);
  const [error, setError] = useState(false);
  const [secretKeyDisplay, SetSecretKeyDisplay] = useState(false);
  const [copiedKey, setCopiedKey] = useState(false);
  // const [result, setResult] = useState();

  // const { t } = useTranslation();

  useEffect(() => {
    const tokens = getTokens();
    if (user && !user.mfaEnabled) {
      mfaQrCode(user)
        .then((res) => {
          const file = res;
          // Encode the file using the FileReader API
          const reader = new FileReader(file);
          reader.readAsDataURL(file);
          reader.onloadend = () => {
            setQrCode(reader.result);
            // Logs data:<type>;base64,wL2dvYWwgbW9yZ...
          };
          setTimeout(() => {
            getUser(user.id).then((userRes) => {
              if (userRes && userRes.mfaSecret) {
                setMfaSecret(userRes.mfaSecret);
              }
            });
          }, 1000);
          // console.log("imageToDisplay", imageToDisplay);
          // setQrCode(res);
        })
        // eslint-disable-next-line
        .catch((err) => console.log("error mfaQrCode", err));
    } else if (user && user.mfaVerified && tokens.mfa) {
      window.location.href = "/";
    }
  }, [user]);

  const validateTwoFactor = () => {
    // console.log("email ", email);
    // console.log("password ", password);
    // console.log("two factor code", twoFactorCode);
    if (user) {
      // const dataToSend = {
      //   user
      //   twoFactorCode,
      // };
      return twoFactor(user, twoFactorCode)
        .then((res) => {
          // console.log("res two factor", res);
          if (res) {
            const logToSend = {
              email: user && user.email ? user.email : "",
              type: "Two factor authentication success",
              date: new Date(),
            };
            setError(false);
            addLog(logToSend).then(() => {
              window.location.href = "/";
            });
          } else {
            const logToSend = {
              email: user && user.email ? user.email : "",
              type: "Two factor authentication failed",
              date: new Date(),
            };
            setError(true);
            addLog(logToSend).then(() => {
              // window.location.href = "/mfa";
            });
          }
        })
        .catch((err) => {
          // eslint-disable-next-line
          console.log("error login", err);
          const logToSend = {
            email: user && user.email ? user.email : "",
            type: "Two factor authentication failed",
            date: new Date(),
          };
          setError(true);
          addLog(logToSend).then(() => {
            // window.location.href = "/mfa";
          });
        });
    }
    return null;
  };

  // const handleSetRememberMe = () => setRememberMe(!rememberMe);
  // TODO gestire errori

  // Move the focus on the "verify" button when the input fields are filled
  useEffect(() => {
    if (twoFactorCode.length === 6) ref.current.focus();
  }, [twoFactorCode]);

  // Handle input change
  const handleOnChange = (res: string) => {
    setTwoFactorCode(res);
  };

  // const [anchorEl, setAnchorEl] = useState(null);

  // const handlePopoverOpen = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handlePopoverClose = () => {
  //   setAnchorEl(null);
  // };

  // const open = Boolean(anchorEl);

  return (
    <BasicLayout>
      <Card>
        <img src={logo} className="auth-defenda-logo" alt="defenda-logo" />
        {qrCode ? (
          <>
            <h5
              style={{
                textAlign: "center",
                padding: "20px 0",
                lineHeight: "1.3em",
              }}
            >
              OTP Initial setup
            </h5>
            <MDBox pb={3} style={{ display: "flex" }}>
              <div className="mfa-step">
                <MDTypography variant="subtitle1" fontSize="small" fontWeight="medium">
                  Step 1:
                </MDTypography>
              </div>
              <div>
                <MDTypography variant="subtitle1" fontSize="small">
                  Download a password authenticator app (Google Authenticator, Free OTP)
                </MDTypography>
              </div>
            </MDBox>
            <MDBox pb={3} style={{ display: "flex" }}>
              <div className="mfa-step">
                <MDTypography variant="subtitle1" fontSize="small" fontWeight="medium">
                  Step 2:
                </MDTypography>
              </div>
              <div>
                <MDTypography variant="subtitle1" fontSize="small">
                  Scan the QR code
                </MDTypography>
              </div>
            </MDBox>
            <div style={{ textAlign: "center" }}>
              <img src={qrCode} title="qrmfa" alt="qr-mfa" style={{ maxWidth: "120px" }} />
            </div>
            <MDBox pb={2} textAlign="center">
              {mfaSecret && (
                <>
                  {!secretKeyDisplay ? (
                    <MDButton disableRipple onClick={() => SetSecretKeyDisplay(true)}>
                      <MDTypography
                        fontSize="small"
                        style={{
                          textDecoration: "underline",
                          color: "#669FF6",
                        }}
                      >
                        Can&apos;t scan the code?
                      </MDTypography>
                    </MDButton>
                  ) : (
                    <div className="qrcode-string">
                      <MDTypography variant="subtitle1" fontSize="small" pb={1}>
                        Copy and paste the key on your password manager
                      </MDTypography>
                      {mfaSecret}
                      <MDButton
                        disableRipple
                        size="small"
                        style={{ margin: "10px 0" }}
                        onClick={() => {
                          navigator.clipboard.writeText(mfaSecret);
                          setCopiedKey(true);
                        }}
                      >
                        {copiedKey ? (
                          <span>
                            <DoneIcon /> Copied
                          </span>
                        ) : (
                          <span>Copy</span>
                        )}
                      </MDButton>
                    </div>
                  )}
                </>
              )}
            </MDBox>
            <MDBox pb={2} style={{ display: "flex" }}>
              <div className="mfa-step">
                <MDTypography variant="subtitle1" fontSize="small" fontWeight="medium">
                  Step 3:
                </MDTypography>
              </div>
              <div>
                <MDTypography variant="subtitle1" fontSize="small">
                  Enter the generated code below
                </MDTypography>
              </div>
            </MDBox>
          </>
        ) : (
          <>
            <h5
              style={{
                textAlign: "center",
                padding: "10px 0",
                lineHeight: "1.3em",
              }}
            >
              Enter the code
            </h5>
            <MDBox pb={2} py={2}>
              <MDTypography variant="subtitle1" fontSize="small" textAlign="center">
                Enter the 6-digit security code indicated in the authenticator app
              </MDTypography>
            </MDBox>
          </>
        )}
        <MDBox pb={3}>
          <MDBox /* component="form" role="form" */>
            <MDBox mb={2}>
              <AuthCode inputClassName="auth-boxes" onChange={handleOnChange} />
            </MDBox>
            <MDBox mt={3} mb={1}>
              <MDButton
                style={{ background: "#669FF6", color: "white" }}
                fullWidth
                ref={ref}
                onClick={() => {
                  validateTwoFactor();
                }}
              >
                Verifica
              </MDButton>
            </MDBox>
            {error ? (
              <MDBox mt={4} mb={1}>
                <MDTypography variant="h6" fontSize="13px" color="error">
                  Ops, qualcosa è andato storto!
                </MDTypography>
                <MDTypography fontSize="11px" color="error">
                  Controlla i dati immessi o riprova fra qualche minuto
                </MDTypography>
              </MDBox>
            ) : null}
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Mfa;
