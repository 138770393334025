/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";
import { useTranslation } from "react-i18next";

// @mui material components
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import BasicLayout from "layouts/authentication/components/BasicLayout";

import logo from "assets/images/logo-light.png";

import resetPassword from "api/auth/resetPassword";
import MDTypography from "components/MDTypography";

function ResetPassword() {
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [password, setPassword] = useState("");
  const [errorPasswordNotMatching, setErrorPasswordNotMatching] = useState("");
  const [error, setError] = useState(false);
  const { t } = useTranslation();

  const performResetPassword = () => {
    const url = window.location.href;
    const splitted = url.split("token=");
    let token = "";
    let simpleUrl = "";
    if (splitted && splitted[1]) {
      // eslint-disable-next-line
      [simpleUrl, token] = splitted;
    }
    if (password === passwordConfirm) {
      return resetPassword(token, password)
        .then((resp) => {
          // eslint-disable-next-line
          console.log("reset password", resp);
          window.location.href = "/login";
        })
        .catch((err) => {
          // eslint-disable-next-line
          console.log("error reset password", err);
          setError(true);
        });
    }
    setErrorPasswordNotMatching(true);
    return null;
  };

  return (
    <BasicLayout>
      <Card>
        <img src={logo} className="auth-defenda-logo" alt="defenda-logo" />

        <MDBox pt={5} pb={3} px={3}>
          <MDBox component="form" role="form" sx={{ textAlign: "center" }}>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                autoFocus
                fullWidth
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    performResetPassword();
                  }
                }}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password Confirm"
                fullWidth
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    performResetPassword();
                  }
                }}
                onChange={(e) => {
                  setPasswordConfirm(e.target.value);
                }}
              />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                style={{ background: "#669FF6", color: "white" }}
                fullWidth
                onClick={() => {
                  performResetPassword();
                }}
              >
                {t("auth.reset_password")}
              </MDButton>
            </MDBox>
            {errorPasswordNotMatching ? (
              <MDBox mt={4} mb={1}>
                <MDTypography fontSize="small">{t("auth.passwords_not_matching")}</MDTypography>
              </MDBox>
            ) : null}
            {error ? (
              <MDBox mt={4} mb={1}>
                <MDTypography variant="h6">{t("auth.forgot_password_error_title")}</MDTypography>
                <MDTypography fontSize="small">{t("auth.forgot_password_error_text")}</MDTypography>
              </MDBox>
            ) : null}
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default ResetPassword;
