import { useState } from "react";
import { useTranslation } from "react-i18next";
import SweetAlert from "react-bootstrap-sweetalert";
import refreshClientsDomains from "api/clients/updateClientsDomains";
import PropTypes from "prop-types";

function TokenExpiredPopup({ closePopup }) {
  const [requesting, setRequesting] = useState(false);
  const [requested, setRequested] = useState(false);
  const [errorRequesting, setErrorRequesting] = useState(false);
  const { t } = useTranslation();

  if (!requested && !requesting && !errorRequesting) {
    // IF NOT REQUESTING, REQUESTED OR HAD ERROR SHOW POPUP TO START WORKER OR TO CANCEL
    return (
      <SweetAlert
        style={{ display: "block", position: "relative", textAlign: "left" }}
        title={t("admin.start_script_domains")}
        onConfirm={() => {
          setRequesting(true);
          // ON CONFIRM SKIP TO REQUESTING STATUS
          refreshClientsDomains()
            .then((res) => {
              if (res && res.code === 200) {
                // IF EVERYTHING IS ALRIGHT REMOVE THE REQUESTING STATUS AND SET REQUESTED
                setRequested(true);
                setRequesting(false);
              } else {
                // eslint-disable-next-line
                console.log("error while starting worker for clients domains, generic", res);
                // IF ERROR SHOW POPUP FOR ERROR
                setErrorRequesting(true);
                setRequesting(false);
              }
            })
            .catch((err) => {
              // eslint-disable-next-line
              console.log("error while starting worker for clients domains", err);
              // IF ERROR SHOW POPUP FOR ERROR
              setErrorRequesting(true);
              setRequesting(false);
            });
        }}
        onCancel={() => {
          closePopup();
        }}
        showCancel
        confirmBtnText={t("admin.start_script_domains_confirm")}
        cancelBtnText={t("admin.start_script_domains_cancel")}
        confirmBtnCssClass="popupCloseButton"
        confirmBtnStyle={{
          boxShadow: "none",
        }}
        cancelBtnCssClass="popupCloseButton"
        cancelBtnStyle={{
          boxShadow: "none",
        }}
      >
        {t("admin.start_script_domains_text")}
      </SweetAlert>
    );
  }
  if (requesting) {
    // IF REQUESTING STATUS SHOW POPUP THAT IT MAY TAKE TIME FOR SERVER RESPONSE
    // NO POSSIBILITY TO CHANGE STATUS OR CLOSE POPUP NEEDED HERE
    return (
      <SweetAlert
        style={{ display: "block", position: "relative", textAlign: "left" }}
        title={t("admin.worker_requesting_title")}
        onConfirm={() => {
          closePopup();
        }}
        confirmBtnText={t("")}
        confirmBtnCssClass="popupCloseButton"
        confirmBtnStyle={{
          boxShadow: "none",
          display: "none",
        }}
      >
        {t("admin.worker_requesting_text")}
      </SweetAlert>
    );
  }
  if (errorRequesting) {
    // IF ERROR STATUS SHOW POPUP TO NOTICE THAT SOMETHING WENT WRONG, YOU CAN CLOSE THE POPUP
    // AND IT WILL BE LIKE IF YOU DIDN'T START THE WORKER IN THE FIRST PLACE
    return (
      <SweetAlert
        style={{ display: "block", position: "relative", textAlign: "left" }}
        title={t("admin.worker_requesting_error_title")}
        onConfirm={() => {
          closePopup();
        }}
        confirmBtnText={t("ok")}
        confirmBtnCssClass="popupCloseButton"
        confirmBtnStyle={{
          boxShadow: "none",
        }}
      >
        {t("admin.worker_requesting_error_text")}
      </SweetAlert>
    );
  }
  return (
    // WORKER STARTED, EVERYTHING IS FINE, YOU CAN CLOSE THE POPUP AND CONTINUE THE NAVIGATION
    <SweetAlert
      style={{ display: "block", position: "relative", textAlign: "left" }}
      title={t("admin.worker_started_title")}
      onConfirm={() => {
        closePopup();
      }}
      confirmBtnText={t("ok")}
      confirmBtnCssClass="popupCloseButton"
      confirmBtnStyle={{
        boxShadow: "none",
      }}
    >
      {t("admin.worker_started_text")}
    </SweetAlert>
  );
}

TokenExpiredPopup.propTypes = {
  closePopup: PropTypes.func.isRequired,
};

export default TokenExpiredPopup;
