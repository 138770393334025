/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

import { useState } from "react";

// Material Dashboard 2 PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import TabNumbers from "layouts/dashboards/sales/components/TabNumbers";
// import WorldMap from "react-svg-worldmap";

import { useMaterialUIController, setStatisticsElement } from "context";
import moment from "moment";

function detailStatisticsNations() {
  // eslint-disable-next-line
  const [controller, dispatch] = useMaterialUIController();
  // eslint-disable-next-line
  const { allStatistics, statisticsElement } = controller;
  const [statisticsChoice, setStatisticsChoice] = useState(statisticsElement || "stealers");

  const isoCountries = {
    Afghanistan: "AF",
    "Aland Islands": "AX",
    Albania: "AL",
    Algeria: "DZ",
    "American Samoa": "AS",
    Andorra: "AD",
    Angola: "AO",
    Anguilla: "AI",
    Antarctica: "AQ",
    "Antigua And Barbuda": "AG",
    Argentina: "AR",
    Armenia: "AM",
    Aruba: "AW",
    Australia: "AU",
    Austria: "AT",
    Azerbaijan: "AZ",
    Bahamas: "BS",
    Bahrain: "BH",
    Bangladesh: "BD",
    Barbados: "BB",
    Belarus: "BY",
    Belgium: "BE",
    Belize: "BZ",
    Benin: "BJ",
    Bermuda: "BM",
    Bhutan: "BT",
    Bolivia: "BO",
    "Bosnia And Herzegovina": "BA",
    Botswana: "BW",
    "Bouvet Island": "BV",
    Brazil: "BR",
    "British Indian Ocean Territory": "IO",
    "Brunei Darussalam": "BN",
    Bulgaria: "BG",
    "Burkina Faso": "BF",
    Burundi: "BI",
    Cambodia: "KH",
    Cameroon: "CM",
    Canada: "CA",
    "Cape Verde": "CV",
    "Cayman Islands": "KY",
    "Central African Republic": "CF",
    Chad: "TD",
    Chile: "CL",
    China: "CN",
    "Christmas Island": "CX",
    "Cocos (Keeling) Islands": "CC",
    Colombia: "CO",
    Comoros: "KM",
    Congo: "CG",
    "Congo, Democratic Republic": "CD",
    "Cook Islands": "CK",
    "Costa Rica": "CR",
    "Cote D'Ivoire": "CI",
    Croatia: "HR",
    Cuba: "CU",
    Cyprus: "CY",
    "Czech Republic": "CZ",
    Denmark: "DK",
    Djibouti: "DJ",
    Dominica: "DM",
    "Dominican Republic": "DO",
    Ecuador: "EC",
    Egypt: "EG",
    "El Salvador": "SV",
    "Equatorial Guinea": "GQ",
    Eritrea: "ER",
    Estonia: "EE",
    Ethiopia: "ET",
    "Falkland Islands": "FK",
    "Faroe Islands": "FO",
    Fiji: "FJ",
    Finland: "FI",
    France: "FR",
    "French Guiana": "GF",
    "French Polynesia": "PF",
    "French Southern Territories": "TF",
    Gabon: "GA",
    Gambia: "GM",
    Georgia: "GE",
    Germany: "DE",
    Ghana: "GH",
    Gibraltar: "GI",
    Greece: "GR",
    Greenland: "GL",
    Grenada: "GD",
    Guadeloupe: "GP",
    Guam: "GU",
    Guatemala: "GT",
    Guernsey: "GG",
    Guinea: "GN",
    "Guinea-Bissau": "GW",
    Guyana: "GY",
    Haiti: "HT",
    "Heard Island & Mcdonald Islands": "HM",
    "Holy See (Vatican City State)": "VA",
    Honduras: "HN",
    "Hong Kong": "HK",
    Hungary: "HU",
    Iceland: "IS",
    India: "IN",
    Indonesia: "ID",
    "Iran, Islamic Republic Of": "IR",
    Iraq: "IQ",
    Ireland: "IE",
    "Isle Of Man": "IM",
    Israel: "IL",
    Italy: "IT",
    Jamaica: "JM",
    Japan: "JP",
    Jersey: "JE",
    Jordan: "JO",
    Kazakhstan: "KZ",
    Kenya: "KE",
    Kiribati: "KI",
    Korea: "KR",
    Kuwait: "KW",
    Kyrgyzstan: "KG",
    "Lao People's Democratic Republic": "LA",
    Latvia: "LV",
    Lebanon: "LB",
    Lesotho: "LS",
    Liberia: "LR",
    "Libyan Arab Jamahiriya": "LY",
    Liechtenstein: "LI",
    Lithuania: "LT",
    Luxembourg: "LU",
    Macao: "MO",
    Macedonia: "MK",
    Madagascar: "MG",
    Malawi: "MW",
    Malaysia: "MY",
    Maldives: "MV",
    Mali: "ML",
    Malta: "MT",
    "Marshall Islands": "MH",
    Martinique: "MQ",
    Mauritania: "MR",
    Mauritius: "MU",
    Mayotte: "YT",
    Mexico: "MX",
    "Micronesia, Federated States Of": "FM",
    Moldova: "MD",
    Monaco: "MC",
    Mongolia: "MN",
    Montenegro: "ME",
    Montserrat: "MS",
    Morocco: "MA",
    Mozambique: "MZ",
    Myanmar: "MM",
    Namibia: "NA",
    Nauru: "NR",
    Nepal: "NP",
    Netherlands: "NL",
    "Netherlands Antilles": "AN",
    "New Caledonia": "NC",
    "New Zealand": "NZ",
    Nicaragua: "NI",
    Niger: "NE",
    Nigeria: "NG",
    Niue: "NU",
    "Norfolk Island": "NF",
    "Northern Mariana Islands": "MP",
    Norway: "NO",
    Oman: "OM",
    Pakistan: "PK",
    Palau: "PW",
    "Palestinian Territory, Occupied": "PS",
    Panama: "PA",
    "Papua New Guinea": "PG",
    Paraguay: "PY",
    Peru: "PE",
    Philippines: "PH",
    Pitcairn: "PN",
    Poland: "PL",
    Portugal: "PT",
    "Puerto Rico": "PR",
    Qatar: "QA",
    Reunion: "RE",
    Romania: "RO",
    "Russian Federation": "RU",
    Rwanda: "RW",
    "Saint Barthelemy": "BL",
    "Saint Helena": "SH",
    "Saint Kitts And Nevis": "KN",
    "Saint Lucia": "LC",
    "Saint Martin": "MF",
    "Saint Pierre And Miquelon": "PM",
    "Saint Vincent And Grenadines": "VC",
    Samoa: "WS",
    "San Marino": "SM",
    "Sao Tome And Principe": "ST",
    "Saudi Arabia": "SA",
    Senegal: "SN",
    Serbia: "RS",
    Seychelles: "SC",
    "Sierra Leone": "SL",
    Singapore: "SG",
    Slovakia: "SK",
    Slovenia: "SI",
    "Solomon Islands": "SB",
    Somalia: "SO",
    "South Africa": "ZA",
    "South Georgia And Sandwich Isl.": "GS",
    Spain: "ES",
    "Sri Lanka": "LK",
    Sudan: "SD",
    Suriname: "SR",
    "Svalbard And Jan Mayen": "SJ",
    Swaziland: "SZ",
    Sweden: "SE",
    Switzerland: "CH",
    "Syrian Arab Republic": "SY",
    Taiwan: "TW",
    Tajikistan: "TJ",
    Tanzania: "TZ",
    Thailand: "TH",
    "Timor-Leste": "TL",
    Togo: "TG",
    Tokelau: "TK",
    Tonga: "TO",
    "Trinidad And Tobago": "TT",
    Tunisia: "TN",
    Turkey: "TR",
    Turkmenistan: "TM",
    "Turks And Caicos Islands": "TC",
    Tuvalu: "TV",
    Uganda: "UG",
    Ukraine: "UA",
    "United Arab Emirates": "AE",
    "United Kingdom": "GB",
    "United States": "US",
    "United States Outlying Islands": "UM",
    Uruguay: "UY",
    Uzbekistan: "UZ",
    Vanuatu: "VU",
    Venezuela: "VE",
    Vietnam: "VN",
    "Virgin Islands, British": "VG",
    "Virgin Islands, U.S.": "VI",
    "Wallis And Futuna": "WF",
    "Western Sahara": "EH",
    Yemen: "YE",
    Zambia: "ZM",
    Zimbabwe: "ZW",
  };

  let maxNumberCountryMap = 0;
  let maxValueCountryMap = "";

  function getCountryLabel(countryCode) {
    if (isoCountries[countryCode]) {
      return isoCountries[countryCode];
    }
    return countryCode;
  }

  function getColorFromValue(value, maxValue) {
    const valuePercentage = value / maxValue;
    if (valuePercentage <= 0.1) {
      return "#3b4081";
    }
    if (valuePercentage > 0.1 && valuePercentage <= 0.2) {
      return "#4977b4";
    }
    if (valuePercentage > 0.2 && valuePercentage <= 0.3) {
      return "#6fa4cd";
    }
    if (valuePercentage > 0.3 && valuePercentage <= 0.4) {
      return "#b0d9e9";
    }
    if (valuePercentage > 0.4 && valuePercentage <= 0.5) {
      return "#e8f7e0";
    }
    if (valuePercentage > 0.5 && valuePercentage <= 0.6) {
      return "#feeca4";
    }
    if (valuePercentage > 0.6 && valuePercentage <= 0.7) {
      return "#fa9a5c";
    }
    if (valuePercentage > 0.7 && valuePercentage <= 0.8) {
      return "#ee653e";
    }
    if (valuePercentage > 0.8 && valuePercentage <= 0.9) {
      return "#d2302a";
    }
    if (valuePercentage > 0.9) {
      return "#ac0b27";
    }
    return "#3b4081";
  }

  function getOpacityFromValue(value, maxValue) {
    const valuePercentage = value / maxValue;
    // console.log("opacity", (valuePercentage * 10) % 1);
    if (valuePercentage >= 0.2) {
      const valueToCheck = (valuePercentage * 10) % 1;
      if (valueToCheck > 0 && valueToCheck <= 0.4) {
        return 0.6;
      }
      if (valueToCheck > 0.4 && valueToCheck <= 0.8) {
        return 0.8;
      }
      return 1;
    }
    if ((valuePercentage * 10) % 1) {
      return (valuePercentage * 10) % 1;
    }
    return 1;
  }

  // eslint-disable-next-line
  const mapStyle = ({ countryValue, countryCode, minValue, maxValue, color }) => ({
    fill: countryValue ? getColorFromValue(countryValue, maxValue) : "#fff",
    // fillOpacity: countryValue ? getOpacityFromValue(countryValue) : 0,
    fillOpacity: countryValue ? getOpacityFromValue(countryValue, maxValue) : 0,
    stroke: "green",
    strokeWidth: 1,
    strokeOpacity: 0.2,
    cursor: "pointer",
  });

  const dataMap = [];
  let dataNumeriPerNazioni = null;
  let attacchiPerCountryToUse = "attacchi_per_country";
  if (statisticsChoice === "combos") {
    attacchiPerCountryToUse = "combo_per_country";
  }
  if (
    allStatistics &&
    allStatistics[attacchiPerCountryToUse] &&
    allStatistics[attacchiPerCountryToUse].length > 0
  ) {
    const labelsNazioni = [];
    const valuesNazioni = [];
    dataNumeriPerNazioni = allStatistics[attacchiPerCountryToUse];
    allStatistics[attacchiPerCountryToUse].forEach((country) => {
      if (country.value > maxNumberCountryMap) {
        maxNumberCountryMap = country.value;
        maxValueCountryMap = getCountryLabel(country.label);
      }
      if (
        dataMap.some((countryInside) => countryInside.country === getCountryLabel(country.label))
      ) {
        const indexElementToImprove = dataMap.findIndex(
          (countryInside) => countryInside.country === getCountryLabel(country.label)
        );
        if (indexElementToImprove >= 0) {
          dataMap[indexElementToImprove].value += country.value;
          if (maxValueCountryMap === getCountryLabel(country.label)) {
            maxNumberCountryMap += country.value;
          }
        }
      }
      if (country.value > 15) {
        labelsNazioni.push(country.label);
        valuesNazioni.push(country.value);
      }
    });
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {allStatistics && allStatistics.data ? (
        <MDBox pt={3} style={{ fontSize: "16px" }}>
          Aggiornato al: {moment(allStatistics.data).format("L")}
        </MDBox>
      ) : null}
      <div>
        Visualizza statistiche per:
        <button
          style={statisticsChoice === "stealers" ? { fontWeight: "bold" } : {}}
          className="emply-label-button"
          onClick={() => {
            if (statisticsChoice !== "stealers") {
              setStatisticsChoice("stealers");
              setStatisticsElement(dispatch, "stealers");
            }
          }}
          type="button"
        >
          stealers
        </button>
        <button
          style={statisticsChoice === "combos" ? { fontWeight: "bold" } : {}}
          className="emply-label-button"
          onClick={() => {
            if (statisticsChoice !== "combos") {
              setStatisticsChoice("combos");
              setStatisticsElement(dispatch, "combos");
            }
          }}
          type="button"
        >
          combos
        </button>
      </div>
      <MDBox py={3}>
        {dataMap && dataMap.length > 0 ? (
          <MDBox>
            <Grid>
              <Card style={{ paddingBottom: "20px", marginBottom: "30px" }}>
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  pt={2}
                  px={2}
                  pb={3}
                >
                  <MDTypography variant="h6">Attacchi per paesi</MDTypography>
                </MDBox>
                {/* <WorldMap
                  color="red"
                  value-suffix="attacks"
                  size="xxl"
                  data={dataMap}
                  styleFunction={mapStyle}
                  onClickFunction={(e) => {
                    console.log("e", e);
                  }}
                /> */}
                <div style={{ width: "calc(100% - 60px)", margin: "30px auto" }}>
                  Leggenda:
                  <div className="map-heat-color-legend" />
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>0</div>
                    <div>{maxNumberCountryMap}</div>
                  </div>
                </div>
              </Card>
            </Grid>
          </MDBox>
        ) : null}
        <MDBox mb={3} mt={1.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} lg={6}>
              {/* {dataPerNazioni ? (
                <OSBarChart title="Nazioni" chart={dataPerNazioni} height="40rem" />
              ) : null} */}
              {dataNumeriPerNazioni ? (
                <TabNumbers
                  title="Nazioni"
                  data={dataNumeriPerNazioni}
                  showGoToDetails={false}
                  height="40rem"
                />
              ) : null}
            </Grid>
          </Grid>
        </MDBox>
        {!dataNumeriPerNazioni && (!dataMap || dataMap.length === 0)
          ? "Non ci sono ancora dati a disposizione"
          : null}
      </MDBox>
    </DashboardLayout>
  );
}

export default detailStatisticsNations;
