import config from "config/config";
import getTokens from "api/getTokens";

const { baseUrl } = config;

/* *********************

********************** */

export default function logout(refreshToken, user) {
  const clientUrl = process.env.REACT_APP_ACCESS_CONTROL_ALLOW_ORIGIN || "";
  const endpoint = `${baseUrl}/v1/auth/logout`;

  let refresh = refreshToken ? refreshToken.token : "";
  if (!refresh) {
    const tokens = getTokens();
    if (tokens && tokens.refresh && tokens.refresh.token) {
      refresh = tokens.refresh.token;
    }
  }
  const options = {
    method: "POST",
    mode: "cors",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `${clientUrl}`,
      "Access-Control-Allow-Credentials": true,
    },
    body: JSON.stringify({
      refreshToken: refresh,
      user,
    }),
  };
  // console.log('logout endpoint', {endpoint, options, baseUrl });
  return (
    fetch(endpoint, options)
      .then((response) => {
        // console.log("logout response", response);
        if ((response && response.status === 200) || (response && response.status === 204)) {
          localStorage.removeItem("refresh");
          localStorage.removeItem("access");
          localStorage.removeItem("userid");
          localStorage.removeItem("mfa");
          return true;
        }
        return false;
      })
      // .then((responseJson) => {
      //   console.log('logout responseJson', responseJson);
      //   // if(responseJson && responseJson.tokens && responseJson.tokens.access) {
      //   //   localStorage.removeItem("refresh");
      //   //   localStorage.removeItem("access");
      //   // }
      //   return responseJson;
      // })
      .catch((e) => {
        if (e.message === "Network request failed") {
          // updateNetworkFailed(true);
        }
      })
  );
}
