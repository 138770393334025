import { useEffect, useState } from "react";
import getAntivirusCounter from "api/statistics/getAntivirusCounter";
import MDBox from "components/MDBox";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
// import { useTranslation } from "react-i18next";

// react-chartjs-2 components
import { Bar } from "react-chartjs-2";
import moment from "moment";

// import { useMaterialUIController } from "context";

// Chart options
export const options = {
  plugins: {
    title: {
      display: false,
      text: "Chart.js Bar Chart - Stacked",
    },
    legend: {
      display: false,
    },
    tooltip: {
      itemSort(a, b) {
        return b.raw - a.raw;
      },
      filter(tooltipItem) {
        const value = tooltipItem.formattedValue;
        return value > 0;
      },
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

// Chart labels
// const labels = ["January", "February", "March", "April", "May", "June", "July", "hello"];

// Chart data
// export const data = {
//   labels,
//   datasets: [
//     {
//       label: "Dataset 1",
//       data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
//       backgroundColor: "rgb(255, 99, 132)",
//     },
//     {
//       label: "Dataset 2",
//       data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
//       backgroundColor: "rgb(75, 192, 192)",
//     },
//     {
//       label: "Dataset 3",
//       data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
//       backgroundColor: "rgb(53, 162, 235)",
//     },
//   ],
// };

function getDates(startDate, stopDate) {
  const dateArray = [];
  let currentDate = startDate;
  while (currentDate.isSameOrBefore(stopDate)) {
    dateArray.push(currentDate.format("DD/MM"));
    currentDate = currentDate.add(1, "days");
  }

  return dateArray;
}

function antivirusBox() {
  const [antivirusCounter, setAntivirusCounter] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    getAntivirusCounter()
      .then((res) => {
        if (res && res.dataset && res.dataset.length > 0) {
          setAntivirusCounter(res.dataset);
        }
        if (res && res.dateFrom) {
          setStartDate(res.dateFrom);
        }
        if (res && res.dateTo) {
          setEndDate(res.dateTo);
        }
      })
      .catch((err) => {
        // eslint-disable-next-line
        console.log("error while getting antivirus counter", err);
      });
  }, []);

  let labels = [];
  let datasets = [];

  if (startDate && endDate) {
    labels = getDates(moment(startDate), moment(endDate));
  }

  if (antivirusCounter && antivirusCounter.length > 0) {
    datasets = antivirusCounter;
  }

  const data = { labels, datasets };

  // const [controller] = useMaterialUIController();
  if (antivirusCounter && antivirusCounter.length > 0) {
    return (
      <MDBox pt={2}>
        <MDBox className="home-box-header" sx={{ background: "#2C74E1" }}>
          <MDTypography variant="header3" fontWeight="bold" color="light">
            Antivirus
          </MDTypography>
        </MDBox>
        <Card sx={{ padding: "50px", background: "white", borderRadius: "0 0 0.75rem 0.75rem" }}>
          <Bar options={options} data={data} />
        </Card>
      </MDBox>
    );
  }
  return null;
}

export default antivirusBox;
