// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import MDInput from "components/MDInput";
import Icon from "@mui/material/Icon";
import Button from "@mui/material/Button";
import CookieIcon from "@mui/icons-material/Cookie";
import PasswordIcon from "@mui/icons-material/Password";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

function TableContentFull({ elements, type }) {
  const [isOpen, setIsOpen] = useState(true);
  const [filterDomain, setFilterDomain] = useState("");
  const [filterName, setFilterName] = useState("");
  const [elementsToView, setElementsToView] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {}, [elements]);

  function setContent() {
    const elementsCopy = [].concat(elements);
    const realElements = [];
    if (type === "passwords") {
      elementsCopy.forEach((item) => {
        if (filterDomain) {
          if (item.URL.toLowerCase().includes(filterDomain.toLowerCase())) {
            realElements.push({
              label: item.username,
              value: item.password,
              domain: item.URL,
              length: item.length,
              strength: item.strength,
            });
          }
        } else {
          realElements.push({
            label: item.username,
            value: item.password,
            domain: item.URL,
            length: item.length,
            strength: item.strength,
          });
        }
      });
    } else {
      elementsCopy.forEach((item) => {
        if (filterDomain || filterName) {
          if (
            item.domain.toLowerCase().includes(filterDomain.toLowerCase()) &&
            item.name.toLowerCase().includes(filterName.toLowerCase())
          ) {
            realElements.push({ domain: item.domain, value: item.value, label: item.name });
          }
        } else {
          realElements.push({ domain: item.domain, value: item.value, label: item.name });
        }
      });
    }
    setElementsToView(realElements);
  }

  useEffect(() => {
    setContent();
  }, []);

  useEffect(() => {
    setContent();
  }, [filterDomain, filterName]);

  function getContent(contents) {
    return contents.map((elem, index) => (
      <tr key={`${type}-for-client-${index.toString()}`}>
        {/* className={`detailsStealerTableRow ${index % 2 === 0 ? "table-even-row" : ""}`} */}
        <td>
          <Tooltip title={`${t("copy")} (click)`} placement="top">
            <Button
              className="stealers-details-domains-copy-btn"
              style={{
                width: "100%",
                color: "#344767",
                fontWeight: "400",
                textTransform: "none",
                fontSize: "14px",
                padding: "0px",
                justifyContent: "flex-start",
              }}
              variant="text"
              onClick={() => {
                navigator.clipboard.writeText(elem.domain);
              }}
            >
              <span style={{ overflow: "hidden", textOverflow: "ellipsis" }}>{elem.domain}</span>
            </Button>
          </Tooltip>
        </td>
        <td>{elem.label}</td>
        <td>
          {type === "passwords" ? (
            <>
              {elem && elem.length ? `${elem.length} ${t("stealers.characters_length")}` : "******"}
            </>
          ) : (
            <div className="details-stealer-cookie-value">
              <div className="label">{elem.value}</div>
              <div className="icon">
                <Tooltip title={t("copy")} placement="top">
                  <IconButton
                    onClick={() => {
                      navigator.clipboard.writeText(elem.value);
                    }}
                    size="small"
                    disableRipple
                  >
                    <Icon fontSize="medium">copy</Icon>
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          )}
        </td>
        {type === "passwords" && elem.strength ? <td>{elem.strength}</td> : null}
      </tr>
    ));
  }

  return (
    <div className="details-stealer-table-container">
      <div className="popupLabel">
        <IconButton
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          style={{ color: "#344767" }}
          size="small"
          disableRipple
        >
          {type === "passwords" ? (
            <PasswordIcon fontSize="medium" style={{ marginRight: "10px" }} />
          ) : (
            <CookieIcon fontSize="medium" style={{ marginRight: "10px" }} />
          )}
          <span className="details-stealer-table-title">
            {type === "passwords" ? "Password:" : "Cookies:"}
          </span>
          {isOpen ? <ArrowDropUpIcon fontSize="medium" /> : <ArrowDropDownIcon fontSize="medium" />}
        </IconButton>
      </div>
      {isOpen ? (
        <>
          <span>
            <MDInput
              type="input"
              label="dominio"
              value={filterDomain}
              onChange={(e) => {
                setFilterDomain(e.target.value);
              }}
              style={{ marginBottom: "10px", marginRight: "10px" }}
            />
          </span>
          <span style={{ border: "none", marginBottom: "10px" }}>
            {type === "cookies" ? (
              <MDInput
                type="input"
                label="nome"
                value={filterName}
                onChange={(e) => {
                  setFilterName(e.target.value);
                }}
                style={{ marginBottom: "10px" }}
              />
            ) : null}
          </span>
          <div
            className="table_users_list"
            style={type === "passwords" ? { marginBottom: "50px" } : {}}
          >
            <span className="elem-cell" />
            <table className="table-content" cellSpacing="0" cellPadding="0">
              <thead>
                <tr>
                  <th>{type === "passwords" ? t("stealers.domain") : t("stealers.domain")}</th>
                  <th>{type === "passwords" ? t("stealers.login") : t("stealers.cookie_name")}</th>
                  <th>
                    {type === "passwords" ? t("stealers.password") : t("stealers.cookie_value")}
                  </th>
                  {type === "passwords" ? <th>{t("stealers.password_strength")}</th> : null}
                </tr>
              </thead>
              <tbody>
                {elementsToView && elementsToView.length > 0 ? getContent(elementsToView) : null}
              </tbody>
            </table>
          </div>
        </>
      ) : null}
    </div>
  );
}

// Typechecking props for the TableContentFull
TableContentFull.propTypes = {
  // eslint-disable-next-line
  elements: PropTypes.arrayOf(PropTypes.object).isRequired,
  type: PropTypes.string,
};

TableContentFull.defaultProps = {
  type: "password",
};

export default TableContentFull;
