// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

function LanguageSelector({ onSelect, user }) {
  return (
    <select
      defaultValue={user.language || "it"}
      onChange={(e) => {
        onSelect(e.target.value);
      }}
      className="headerCustomersDropdown"
    >
      <option value="it">IT</option>
      <option value="en">EN</option>
    </select>
  );
}

// Typechecking props for the LanguageSelector
LanguageSelector.propTypes = {
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default LanguageSelector;
