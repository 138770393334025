/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav.
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 PRO React layouts
import Analytics from "layouts/dashboards/analytics";
// import Combos from "layouts/dashboards/combos";
import Stealers from "layouts/dashboards/stealers";
import DuplicateStealers from "layouts/dashboards/duplicateStealers";
import StealersToHandle from "layouts/dashboards/stealersToHandle";
import DetailsStealer from "layouts/dashboards/detailsStealer";
import DetailsLensPhishing from "layouts/dashboards/detailLensPhishing";
import SignInBasic from "layouts/authentication/sign-in/basic";
import ResetPassword from "layouts/authentication/reset-password";
import ForgotPassword from "layouts/authentication/forgot-password";
import Mfa from "layouts/authentication/mfa";
import UserList from "layouts/backoffice/userlist/index";
import AddUser from "layouts/backoffice/adduser/index";
import LogList from "layouts/backoffice/logsList/index";
import Apikey from "layouts/backoffice/apikey/index";
import Worker from "layouts/backoffice/worker/index";
import AlertsPage from "layouts/dashboards/alertsPage";
// import LoggerAttackList from "layouts/backoffice/loggerAttacksList/index";
import DetailStatisticsNations from "layouts/dashboards/detailStatisticsNations";
import ReportsRequests from "layouts/backoffice/reportsRequests/index";
// Material Dashboard 2 PRO React components
// @mui icons
import Icon from "@mui/material/Icon";

const routes = [
  {
    type: "collapse",
    name: "dashboard",
    key: "dashboard",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    route: "/dashboard",
    component: <Analytics />,
  },
  {
    type: "collapse",
    name: "passwords",
    key: "passwords",
    icon: <Icon fontSize="medium">password</Icon>,
    route: "/identity",
    component: <Stealers />,
  },
  {
    type: "collapse",
    name: "alerts",
    key: "alerts",
    icon: <Icon fontSize="medium">notifications</Icon>,
    route: "/alerts",
    component: <AlertsPage />,
  },
  {
    name: "Login",
    key: "login",
    route: "/login",
    component: <SignInBasic />,
  },
  {
    name: "ResetPassword",
    key: "resetPassword",
    route: "/resetPassword",
    component: <ResetPassword />,
  },
  {
    name: "ForgotPassword",
    key: "forgotPassword",
    route: "/forgotPassword",
    component: <ForgotPassword />,
  },
  {
    name: "mfa",
    key: "mfa",
    route: "/mfa",
    component: <Mfa />,
  },
  {
    name: "DetailsStealer",
    key: "detailsStealer",
    route: "/detailsStealer",
    component: <DetailsStealer />,
  },
  {
    name: "DetailsLensPhishing",
    key: "detailsLensPhishing",
    route: "/detailsLensPhishing",
    component: <DetailsLensPhishing />,
  },
  {
    name: "detailStatisticsNations",
    key: "detailStatisticsNations",
    route: "/detailStatisticsNations",
    component: <DetailStatisticsNations />,
  },
  {
    type: "collapse",
    name: "worker",
    key: "worker",
    route: "/worker",
    component: <Worker />,
  },
  {
    type: "collapse",
    name: "userlist",
    key: "userlist",
    route: "/userlist",
    component: <UserList />,
  },
  {
    type: "collapse",
    name: "add_user",
    key: "addUser",
    route: "/adduser",
    component: <AddUser />,
  },
  {
    type: "collapse",
    name: "stealers_to_manage",
    key: "stealers_to_manage",
    route: "/dashboards/stealersToHandle",
    component: <StealersToHandle />,
  },
  {
    type: "collapse",
    name: "duplicate_stealers",
    key: "StealerDuplicates",
    route: "/dashboards/stealerDuplicates",
    component: <DuplicateStealers />,
  },
  {
    type: "collapse",
    name: "reportsrequests",
    key: "reportsrequests",
    route: "/reportsrequests",
    component: <ReportsRequests />,
  },
  {
    type: "collapse",
    name: "logs",
    key: "logs",
    route: "/logs",
    component: <LogList />,
  },
  {
    type: "collapse",
    name: "apikeys",
    key: "apikeys",
    route: "/apikeys",
    component: <Apikey />,
  },
  // {
  //   type: "collapse",
  //   name: "admin",
  //   key: "admin",
  //   icon: <Icon fontSize="medium">content_paste</Icon>,
  //   collapse: [
  //     {
  //       name: "worker",
  //       key: "worker",
  //       route: "/worker",
  //       component: <Worker />,
  //     },
  //     {
  //       name: "userlist",
  //       key: "userlist",
  //       route: "/userlist",
  //       component: <UserList />,
  //     },
  //     {
  //       name: "add_user",
  //       key: "addUser",
  //       route: "/adduser",
  //       component: <AddUser />,
  //     },
  //     {
  //       name: "customers_list",
  //       key: "clientList",
  //       route: "/clientlist",
  //       component: <ClientList />,
  //     },
  //     {
  //       name: "add_customer",
  //       key: "addClient",
  //       route: "/addclient",
  //       component: <AddClient />,
  //     },
  //     {
  //        name: "reportsrequests",
  //        key: "reportsrequests",
  //   route: "/reportsrequests",
  //   component: <ReportsRequests />,
  // },
  //     {
  //       name: "logs",
  //       key: "logs",
  //       route: "/logs",
  //       component: <LogList />,
  //     },
  //     {
  //       name: "Attack Logs",
  //       key: "logsAttack",
  //       route: "/logsattack",
  //       component: <LoggerAttackList />,
  //     },
  //     {
  //       name: "apikeys",
  //       key: "apikeys",
  //       route: "/apikeys",
  //       component: <Apikey />,
  //     },
  //   ],
  // },
];

export default routes;
