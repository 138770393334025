// @mui material components
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import getWorkerStatistics from "api/statistics/getWorkerStatistics";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import moment from "moment";

// Material Dashboard 2 PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Pagination from "layouts/dashboards/stealers/pagination";

function WorkerStatistics() {
  const [stats, setStats] = useState([]);
  const [pages, setPages] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(50);
  const [fieldsShown, setFieldsShown] = useState(["Tempo", "DuplicatiGenerico"]);
  const [isRequestReady, setIsRequestReady] = useState(false);
  // eslint-disable-next-line
  const [orderBy, setOrderBy] = useState("dateReverse");
  const { t } = useTranslation();

  function setRequest(res) {
    if (res.results && res.results.length > 0) {
      setStats(res.results.sort((a, b) => a.data && b.data && b.data.localeCompare(a.data)));
      setIsRequestReady(true);
    }
    if (res && res.length > 0) {
      setStats(res.sort((a, b) => a.date && b.date && b.date.localeCompare(a.date)));
      setIsRequestReady(true);
    } else if (res.length === 0 || (res.results && res.results.length === 0)) {
      setStats([]);
      setIsRequestReady(true);
    }
  }

  function chooseFieldsShown() {
    const choiceArray = [
      { value: "Tempo", label: t("admin.time") },
      { value: "Tag", label: t("admin.tag") },
      { value: "DuplicatiGenerico", label: t("admin.duplicates_generic") },
      { value: "DuplicatiDettaglio", label: t("admin.duplicates_details") },
    ];
    return choiceArray.map((el, index) => (
      <div
        className="choice-view-tabel-option input-checkbox"
        key={`choice-view-table-${el}${index.toString()}`}
        style={index === 0 ? { paddingLeft: "0" } : {}}
      >
        <label htmlFor={el.value}>
          <input
            type="checkbox"
            id={el.value}
            checked={fieldsShown.includes(el.value)}
            onChange={() => {
              const newFieldsShown = [].concat(fieldsShown);
              if (fieldsShown.includes(el.value)) {
                const indexToDelete = fieldsShown.indexOf(el.value);
                if (indexToDelete >= 0) {
                  newFieldsShown.splice(indexToDelete, 1);
                }
                // lo tolgo
              } else {
                newFieldsShown.push(el.value);
              }
              setFieldsShown(newFieldsShown);
            }}
          />
          <span className="checkmark" />
          {el.label}
        </label>
      </div>
    ));
  }

  function refreshWorker() {
    setIsRequestReady(false);
    getWorkerStatistics(page, limit, orderBy)
      .then((res) => {
        if (res.totalPages) {
          setPages(res.totalPages);
        }
        setRequest(res);
      })
      // eslint-disable-next-line
    .catch((err) => console.log("error", err));
  }

  useEffect(() => {
    refreshWorker();
  }, [limit, page, orderBy]);

  function getElapsedTime(time) {
    let timeOutput = "";
    const seconds = time % 60;
    const minutes = Math.floor(time / 60);
    const minutesOutput = minutes % 60;
    const hours = Math.floor(minutes / 60);
    if (hours && hours > 0) {
      timeOutput += `${hours} h `;
    }
    if (minutesOutput && minutesOutput > 0) {
      timeOutput += `${minutesOutput} m `;
    }
    if (seconds && seconds > 0) {
      timeOutput += `${seconds} s`;
    }
    return timeOutput;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div style={{ margin: "30px 0", width: "100%", textAlign: "right" }}>
        <IconButton
          onClick={() => {
            refreshWorker();
          }}
          size="small"
          disableRipple
        >
          {t("refresh")} <Icon fontSize="medium">replay</Icon>
        </IconButton>
      </div>
      <div style={{ display: "flex" }}>
        <div style={{ lineHeight: "40px", marginRight: "15px" }}>
          {t("admin.elements_displayed")}:
        </div>
        <select
          onChange={(e) => {
            setLimit(e.target.value);
          }}
          value={limit}
          className="selectDefault"
          style={{
            padding: "0.75rem",
            borderRadius: "0.375rem",
            borderColor: "#d2d6da",
            marginBottom: "30px",
          }}
        >
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </select>
      </div>
      <div style={{ display: "flex" }}>{chooseFieldsShown()}</div>
      <div className="popupInfoContainer">
        <div>
          <div className="table_users_list">
            <table style={{ border: "solid 1px #dedede", width: "100%" }}>
              <thead>
                <tr>
                  <th
                    style={{
                      padding: "10px 0",
                      borderBottom: "solid 1px #dedede",
                      width: "8%",
                      textAlign: "center",
                    }}
                  >
                    {t("data")}
                  </th>
                  {fieldsShown.includes("Tempo") ? (
                    <th
                      style={{
                        padding: "10px 0",
                        borderBottom: "solid 1px #dedede",
                        width: "8%",
                        textAlign: "center",
                      }}
                    >
                      {t("admin.time_elapsed")}
                    </th>
                  ) : null}
                  {fieldsShown.includes("Tag") ? (
                    <th
                      style={{
                        padding: "10px 0",
                        borderBottom: "solid 1px #dedede",
                        width: "5%",
                        textAlign: "center",
                      }}
                    >
                      {t("admin.tag")}
                    </th>
                  ) : null}
                  <th
                    style={{
                      padding: "10px 0",
                      borderBottom: "solid 1px #dedede",
                      width: "5%",
                      textAlign: "center",
                    }}
                  >
                    {t("admin.elements")}
                  </th>
                  <th
                    style={{
                      padding: "10px 0",
                      borderBottom: "solid 1px #dedede",
                      width: "5%",
                      textAlign: "center",
                    }}
                  >
                    {t("admin.stealers")}
                  </th>
                  <th
                    style={{
                      padding: "10px 0",
                      borderBottom: "solid 1px #dedede",
                      width: "5%",
                      textAlign: "center",
                    }}
                  >
                    {t("admin.ransomware")}
                  </th>
                  {fieldsShown.includes("DuplicatiGenerico") ? (
                    <th
                      style={{
                        padding: "10px 0",
                        borderBottom: "solid 1px #dedede",
                        width: "8%",
                        textAlign: "center",
                      }}
                    >
                      {t("admin.duplicates")}
                    </th>
                  ) : null}
                  {fieldsShown.includes("DuplicatiDettaglio") ? (
                    <th
                      style={{
                        padding: "10px 0",
                        borderBottom: "solid 1px #dedede",
                        width: "5%",
                        textAlign: "center",
                      }}
                    >
                      {t("admin.duplicates_cc")}
                    </th>
                  ) : null}
                  {fieldsShown.includes("DuplicatiDettaglio") ? (
                    <th
                      style={{
                        padding: "10px 0",
                        borderBottom: "solid 1px #dedede",
                        width: "5%",
                        textAlign: "center",
                      }}
                    >
                      {t("admin.duplicates_ci")}
                    </th>
                  ) : null}
                  {fieldsShown.includes("DuplicatiDettaglio") ? (
                    <th
                      style={{
                        padding: "10px 0",
                        borderBottom: "solid 1px #dedede",
                        width: "5%",
                        textAlign: "center",
                      }}
                    >
                      {t("admin.duplicates_ic")}
                    </th>
                  ) : null}
                  {fieldsShown.includes("DuplicatiDettaglio") ? (
                    <th
                      style={{
                        padding: "10px 0",
                        borderBottom: "solid 1px #dedede",
                        width: "5%",
                        textAlign: "center",
                      }}
                    >
                      {t("admin.duplicates_ii")}
                    </th>
                  ) : null}
                </tr>
              </thead>
              <tbody style={{ fontSize: "14px", textAlign: "center" }}>
                {isRequestReady ? (
                  <>
                    {stats && stats.length > 0 ? (
                      <>
                        {stats.map((el, index) => (
                          <tr
                            style={{
                              borderBottom: "solid 1px #dedede",
                              backgroundColor: index % 2 === 0 ? "#fff" : "#eeeeee",
                            }}
                            key={`worker-instance-${index.toString()}`}
                          >
                            <td style={{ borderBottom: "solid 1px #dedede", padding: "10px 0" }}>
                              {el.data
                                ? `${moment(el.data).format("DD")}/${moment(el.data).format(
                                    "MM"
                                  )}/${moment(el.data).format("YYYY")} - ${moment(el.data).format(
                                    "HH"
                                  )}:${moment(el.data).format("mm")}:${moment(el.data).format(
                                    "ss"
                                  )}`
                                : ""}
                            </td>
                            {fieldsShown.includes("Tempo") ? (
                              <td style={{ borderBottom: "solid 1px #dedede", padding: "10px 0" }}>
                                {el.tempo_trascorso ? getElapsedTime(el.tempo_trascorso) : "0"}
                              </td>
                            ) : null}
                            {fieldsShown.includes("Tag") ? (
                              <td style={{ borderBottom: "solid 1px #dedede", padding: "10px 0" }}>
                                {el.tag ? el.tag : ""}
                              </td>
                            ) : null}
                            <td style={{ borderBottom: "solid 1px #dedede", padding: "10px 0" }}>
                              {el.attacchi_totali ? el.attacchi_totali : "0"}
                            </td>
                            <td style={{ borderBottom: "solid 1px #dedede", padding: "10px 0" }}>
                              {el.stealers_totali ? el.stealers_totali : "0"}
                            </td>
                            {fieldsShown.includes("DuplicatiGenerico") ? (
                              <td style={{ borderBottom: "solid 1px #dedede", padding: "10px 0" }}>
                                {el.stealers_duplicati_completi_su_completi ||
                                el.stealers_duplicati_completi_su_incompleti ||
                                el.stealers_duplicati_incompleti_su_completi ||
                                el.stealers_duplicati_incompleti_su_incompleti
                                  ? (Number(el.stealers_duplicati_completi_su_completi) || 0) +
                                    (Number(el.stealers_duplicati_completi_su_incompleti) || 0) +
                                    (Number(el.stealers_duplicati_incompleti_su_completi) || 0) +
                                    (Number(el.stealers_duplicati_incompleti_su_incompleti) || 0)
                                  : "0"}
                              </td>
                            ) : null}
                            {fieldsShown.includes("DuplicatiDettaglio") ? (
                              <td style={{ borderBottom: "solid 1px #dedede", padding: "10px 0" }}>
                                {el.stealers_duplicati_completi_su_completi
                                  ? el.stealers_duplicati_completi_su_completi
                                  : "0"}
                              </td>
                            ) : null}
                            {fieldsShown.includes("DuplicatiDettaglio") ? (
                              <td style={{ borderBottom: "solid 1px #dedede", padding: "10px 0" }}>
                                {el.stealers_duplicati_completi_su_incompleti
                                  ? el.stealers_duplicati_completi_su_incompleti
                                  : "0"}
                              </td>
                            ) : null}
                            {fieldsShown.includes("DuplicatiDettaglio") ? (
                              <td style={{ borderBottom: "solid 1px #dedede", padding: "10px 0" }}>
                                {el.stealers_duplicati_incompleti_su_completi
                                  ? el.stealers_duplicati_incompleti_su_completi
                                  : "0"}
                              </td>
                            ) : null}
                            {fieldsShown.includes("DuplicatiDettaglio") ? (
                              <td style={{ borderBottom: "solid 1px #dedede", padding: "10px 0" }}>
                                {el.stealers_duplicati_incompleti_su_incompleti
                                  ? el.stealers_duplicati_incompleti_su_incompleti
                                  : "0"}
                              </td>
                            ) : null}
                          </tr>
                        ))}
                      </>
                    ) : (
                      <tr>
                        <td />
                        <td />
                        <td style={{ width: "100%", display: "block", padding: "10px 0" }}>
                          {t("admin.no_worker_yet")}
                        </td>
                        <td />
                        <td />
                        <td />
                      </tr>
                    )}
                  </>
                ) : (
                  <tr>
                    <td />
                    <td />
                    <td
                      style={{
                        width: "100%",
                        display: "block",
                        padding: "10px 0",
                        position: "relative",
                      }}
                    >
                      <div className="loader" />
                    </td>
                    <td />
                    <td />
                    <td />
                  </tr>
                )}
              </tbody>
            </table>
            {pages && pages > 1 ? (
              <Pagination setPage={setPage} pages={pages} active={page} />
            ) : null}
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default WorkerStatistics;
