// @mui material components
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import refreshApikeyTokens from "api/auth/refreshApikeyTokens";
import deleteApikeyToken from "api/auth/deleteApikeyToken";
import getAllUser from "api/users/getAllUser";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import MDInput from "components/MDInput";
import { useMaterialUIController } from "context";
import SweetAlert from "react-bootstrap-sweetalert";

// Material Dashboard 2 PRO React components
import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Pagination from "layouts/dashboards/stealers/pagination";

function Apikey() {
  // eslint-disable-next-line
  const [controller, dispatch] = useMaterialUIController();
  // const { user } = controller;
  const [users, setUsers] = useState([]);
  const [userToDelete, setUserToDelete] = useState(null);
  // const [originalUsers, setOriginalUsers] = useState([]);
  const [orderBy, setOrderBy] = useState("email");
  const [popupDelete, setPopupDelete] = useState(false);
  const [popup, setPopup] = useState(false);
  const [emailFilter, setEmailFilter] = useState("");
  const [roleFilter, setRoleFilter] = useState("");
  // eslint-disable-next-line
  const [pages, setPages] = useState(0);
  // eslint-disable-next-line
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(50);
  const [popupAddedToken, setPopupAddedToken] = useState(false);
  const [tokenAdded, setTokenAdded] = useState("");
  const [popupTitle, setPopupTitle] = useState("");
  const [popupText, setPopupText] = useState("");
  const [requestSucceded, setRequestSucceded] = useState(true);
  const { t } = useTranslation();

  function refreshLogs() {
    getAllUser(page, limit, emailFilter, roleFilter, orderBy)
      .then((res) => {
        // console.log("tutto apposto", res);
        if (res.results && res.results.length > 0) {
          if (res.totalPages) {
            setPages(res.totalPages);
          }
          // setOriginalUsers(res.results);
          setUsers(res.results.sort((a, b) => a.email.localeCompare(b.email)));
        }
      })
      // eslint-disable-next-line
      .catch((err) => console.log("error", err));
  }

  useEffect(() => {
    refreshLogs();
  }, []);

  useEffect(() => {
    refreshLogs();
  }, [page, limit, emailFilter, roleFilter, orderBy]);

  function createTokenKey(userToRefresh) {
    return refreshApikeyTokens(userToRefresh)
      .then((res) => {
        if (res && res.access && res.access.token) {
          setTokenAdded(res.access.token);
          setPopupAddedToken(true);
          setTimeout(() => {
            setPopupAddedToken(false);
          }, 60000);
        }
      })
      .catch((err) => {
        // eslint-disable-next-line
        console.log("error adding new key", err);
      });
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div
        style={{
          margin: "30px 0",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <IconButton
          onClick={() => {
            refreshLogs();
          }}
          size="small"
          disableRipple
        >
          {t("refresh")} <Icon fontSize="medium">replay</Icon>
        </IconButton>
      </div>
      {popupAddedToken ? (
        <SweetAlert
          style={{ display: "block" }}
          title={t("admin.your_new_token")}
          success
          onConfirm={() => {
            setPopupAddedToken(false);
          }}
          confirmBtnText={t("admin.close_now")}
          confirmBtnCssClass="popupCloseButton"
          confirmBtnStyle={{
            boxShadow: "none",
          }}
        >
          <div style={{ fontSize: "16px" }}>
            {tokenAdded}
            <Tooltip title={t("copy")} placement="top">
              <IconButton
                onClick={() => {
                  navigator.clipboard.writeText(tokenAdded);
                }}
                size="small"
                disableRipple
              >
                <Icon fontSize="medium">copy</Icon>
              </IconButton>
            </Tooltip>
          </div>
          <div style={{ fontSize: "16px" }}>{t("admin.token_visible_60_seconds")}</div>
        </SweetAlert>
      ) : null}
      {popup ? (
        <SweetAlert
          style={{ display: "block" }}
          title={popupTitle}
          success={requestSucceded}
          error={!requestSucceded}
          onConfirm={() => {
            setPopup(false);
          }}
          confirmBtnText={t("ok")}
          confirmBtnCssClass="popupCloseButton"
          confirmBtnStyle={{
            boxShadow: "none",
          }}
        >
          {popupText}
        </SweetAlert>
      ) : null}
      {popupDelete ? (
        <SweetAlert
          style={{ display: "block" }}
          title={t("admin.delete")}
          onConfirm={() => {
            deleteApikeyToken(userToDelete)
              .then(() => {
                setPopup(true);
                setPopupDelete(false);
                setRequestSucceded(true);
                setPopupTitle("Successo");
                setPopupText(t("admin.delete_success_text"));
              })
              .catch((err) => {
                // eslint-disable-next-line
                console.log("errore eliminazione", err);
                setPopup(true);
                setPopupDelete(false);
                setRequestSucceded(false);
                setPopupTitle("Errore");
                setPopupText(t("admin.delete_error_text"));
                setUserToDelete(null);
              });
            setPopup(false);
          }}
          showCancel
          onCancel={() => {
            setPopupDelete(false);
            setUserToDelete(null);
          }}
          cancelBtnText="No"
          confirmBtnText="Ok"
          cancelBtnCssClass="popupCloseButton"
          cancelBtnStyle={{
            boxShadow: "none",
            color: "red",
          }}
          confirmBtnCssClass="popupCloseButton"
          confirmBtnStyle={{
            boxShadow: "none",
          }}
        >
          {`${t("admin.confirmation_delete_text")} ${
            userToDelete && userToDelete.email ? userToDelete.email : ""
          }?`}
        </SweetAlert>
      ) : null}
      <div style={{ display: "flex", marginTop: "30px", justifyContent: "end" }}>
        <div style={{ lineHeight: "40px", marginRight: "15px" }}>{t("displayed")}:</div>
        <select
          onChange={(e) => {
            setLimit(e.target.value);
          }}
          value={limit}
          className="selectDefault"
          style={{
            padding: "0.75rem",
            borderRadius: "0.375rem",
            borderColor: "#d2d6da",
            marginBottom: "30px",
          }}
        >
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </select>
      </div>
      {users && users.length > 0 ? (
        <div className="table_users_list">
          <table style={{ border: "solid 1px #dedede", width: "100%" }}>
            <thead>
              <tr>
                <th
                  style={{
                    padding: "10px 0",
                    borderBottom: "solid 1px #dedede",
                    width: "25%",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (orderBy === "email") {
                      setOrderBy("emailReverse");
                    } else {
                      setOrderBy("email");
                    }
                  }}
                >
                  {t("admin.email")}
                  {orderBy === "email" ? <Icon fontSize="medium">south</Icon> : null}
                  {orderBy === "emailReverse" ? <Icon fontSize="medium">north</Icon> : null}
                </th>
                <th
                  style={{
                    padding: "10px 0",
                    borderBottom: "solid 1px #dedede",
                    width: "25%",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (orderBy === "role") {
                      setOrderBy("roleReverse");
                    } else {
                      setOrderBy("role");
                    }
                  }}
                >
                  {t("admin.role")}
                  {orderBy === "role" ? <Icon fontSize="medium">south</Icon> : null}
                  {orderBy === "roleReverse" ? <Icon fontSize="medium">north</Icon> : null}
                </th>
                <th
                  style={{
                    padding: "10px 0",
                    borderBottom: "solid 1px #dedede",
                    width: "25%",
                    textAlign: "center",
                  }}
                >
                  {t("admin.restore_token")}
                </th>
                <th
                  style={{
                    padding: "10px 0",
                    borderBottom: "solid 1px #dedede",
                    width: "25%",
                    textAlign: "center",
                  }}
                >
                  {t("delete")}
                </th>
              </tr>
            </thead>
            <tbody style={{ fontSize: "14px", textAlign: "center" }}>
              <tr>
                <th
                  style={{
                    padding: "10px 0",
                    borderBottom: "solid 1px #dedede",
                    width: "25%",
                    textAlign: "center",
                  }}
                >
                  <MDInput
                    type="input"
                    label={t("admin.email")}
                    fullWidth
                    onChange={(e) => {
                      setEmailFilter(e.target.value);
                    }}
                  />
                </th>
                <th
                  style={{
                    padding: "10px",
                    borderBottom: "solid 1px #dedede",
                    width: "25%",
                    textAlign: "center",
                  }}
                >
                  <MDInput
                    type="input"
                    label={t("admin.role")}
                    fullWidth
                    onChange={(e) => {
                      setRoleFilter(e.target.value);
                    }}
                  />
                </th>
                <th
                  style={{
                    padding: "10px",
                    borderBottom: "solid 1px #dedede",
                    width: "25%",
                    textAlign: "center",
                  }}
                >
                  /
                </th>
                <th
                  style={{
                    padding: "10px 0",
                    borderBottom: "solid 1px #dedede",
                    width: "25%",
                    textAlign: "center",
                  }}
                >
                  /
                </th>
              </tr>
              {users.map((el, index) => (
                <tr style={{ borderBottom: "solid 1px #dedede" }} key={`user-${index.toString()}`}>
                  <td style={{ borderBottom: "solid 1px #dedede", padding: "10px 0" }}>
                    {el.email}
                  </td>
                  {/* TODO select */}
                  <td style={{ borderBottom: "solid 1px #dedede", padding: "10px 0" }}>
                    {el.role}
                  </td>
                  <td style={{ borderBottom: "solid 1px #dedede", padding: "10px 0" }}>
                    <IconButton
                      onClick={() => {
                        createTokenKey(el);
                      }}
                      size="small"
                      disableRipple
                    >
                      <Icon fontSize="medium">replay</Icon>
                    </IconButton>
                  </td>
                  <td style={{ borderBottom: "solid 1px #dedede", padding: "10px 0" }}>
                    <Icon
                      fontSize="medium"
                      onClick={() => {
                        // TODO elimina log
                        setPopupDelete(true);
                        setUserToDelete(el);
                      }}
                    >
                      delete
                    </Icon>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {pages && pages > 1 ? <Pagination setPage={setPage} pages={pages} active={page} /> : null}
        </div>
      ) : null}
    </DashboardLayout>
  );
}

export default Apikey;
