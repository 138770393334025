// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

// Material Dashboard 2 PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import StealersStatistics from "layouts/dashboards/analytics/components/stealersStatistics";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import Alerts from "layouts/dashboards/analytics/components/alertsBox";
import AntivirusBox from "layouts/dashboards/analytics/components/antivirusBox";
// import HeatMap from "layouts/dashboards/analytics/components/HeatMap";
import countWeekPasswords from "api/statistics/countWeekPasswords";
import getRegionCounter from "api/statistics/getRegionCounter";
// import countWeekPasswordsClient from "api/statistics/countWeekPasswordsClient";
import countDarkwebSitesIndexed from "api/statistics/countDarkwebSitesIndexed";
// import PieChart from "examples/Charts/PieChart";
// import OSBarChart from "examples/Charts/BarCharts/OSBarChart";
// import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
// import TabNumbers from "layouts/dashboards/sales/components/TabNumbers";
// import ContatoriStealers from "layouts/dashboards/sales/components/ContatoriStealers";
// import Datetime from "react-datetime";

// Import images
// import emailBlurred from "assets/images/email-blurred.png";

import {
  useMaterialUIController,
  // setStatisticsElement,
  // setStatisticsStart,
  // setStatisticsEnd,
} from "context";

// import moment from "moment";

// import DefaultDoughnutChart from "examples/Charts/DoughnutCharts/DefaultDoughnutChart";
// import { Calculate } from "@mui/icons-material";

function Analytics() {
  // eslint-disable-next-line
  const [controller, dispatch] = useMaterialUIController();
  // eslint-disable-next-line
  const [statisticFromUrl, setStatisticFromUrl] = useState("stealers");
  const [stolenCredentials, setStolenCredentials] = useState(0);
  const [regionCounter, setRegionCounter] = useState([]);
  const [stolenCredentialsPerWeek, setStolenCredentialsPerWeek] = useState(null);
  const [sitesIndexed, setSitesIndexed] = useState(0);
  const [callsCount, setCallsCount] = useState(0);
  let count = callsCount;
  // const [topFive, setTopFive] = useState([]);
  // const [showStatisticsDate, setShowStatisticsDate] = useState(false);
  // const { user } = controller;
  const { t } = useTranslation();

  const urlNow = window.location.href;
  if (urlNow && urlNow.endsWith("stealer") && statisticFromUrl !== "stealers") {
    setStatisticFromUrl("stealers");
  }

  useEffect(() => {
    // countWeekPasswords()
    //   .then((resTwo) => {
    //     setCallsCount((count += 1));
    //     if (resTwo && resTwo.counter) {
    //       setStolenCredentials(String(res.counter).replace(/(.)(?=(\d{3})+$)/g, "$1."));
    //     }
    //     if (resTwo && resTwo.arrayCounter) {
    //       setStolenCredentialsPerWeek(resTwo.arrayCounter);
    //     }
    //   })
    //   .catch((err) => {
    //     setCallsCount((count += 1));
    //     // eslint-disable-next-line
    //   console.log("error while counting count week passwords", err);
    //   })

    countDarkwebSitesIndexed()
      .then((res) => {
        setCallsCount((count += 1));
        if (res && res.counter) {
          setSitesIndexed(String(res.counter).replace(/(.)(?=(\d{3})+$)/g, "$1."));
        }
        countWeekPasswords()
          .then((resTwo) => {
            setCallsCount((count += 1));
            if (resTwo && resTwo.counter) {
              setStolenCredentials(String(resTwo.counter).replace(/(.)(?=(\d{3})+$)/g, "$1."));
            }
            if (resTwo && resTwo.arrayCounter) {
              setStolenCredentialsPerWeek(resTwo.arrayCounter);
            }
          })
          .catch((err) => {
            setCallsCount((count += 1));
            // eslint-disable-next-line
            console.log("error while getting count week passwords", err);
          });
        getRegionCounter()
          .then((resThree) => {
            setCallsCount((count += 1));
            if (resThree && resThree.datas && resThree.datas.length > 0) {
              // eslint-disable-next-line
              setRegionCounter(resThree.datas.map((inner) => ({ value: inner.value, label: inner._id })));
            }
          })
          .catch((err) => {
            setCallsCount((count += 1));
            // eslint-disable-next-line
            console.log("error while getting antivirus counter", err);
          });
      })
      .catch((err) => {
        setCallsCount((count += 1));
        // eslint-disable-next-line
        console.log("error while counting stolen credentials", err);
      });
  }, []);

  let maxNumberCountryMap = 0;
  let maxValueCountryMap = "";

  let dataToDays = null;

  if (stolenCredentialsPerWeek && stolenCredentialsPerWeek.length > 0) {
    const labelsGiorno = [
      { label: t("statistics.monday_short"), day: 1 },
      { label: t("statistics.tuesday_short"), day: 2 },
      { label: t("statistics.wednesday_short"), day: 3 },
      { label: t("statistics.thursday_short"), day: 4 },
      { label: t("statistics.friday_short"), day: 5 },
      { label: t("statistics.saturday_short"), day: 6 },
      { label: t("statistics.sunday_short"), day: 0 },
    ];
    const labelsGiornoFinal = [];
    stolenCredentialsPerWeek.forEach((giorno) => {
      const dayToFindIndex = labelsGiorno.findIndex((day) => day.day === giorno.label);
      if (dayToFindIndex >= 0) {
        labelsGiornoFinal.push(labelsGiorno[dayToFindIndex].label);
      }
    });
    const valuesGiornoFinal = stolenCredentialsPerWeek.map((el) => el.value);
    dataToDays = {
      labels: labelsGiornoFinal,
      datasets: {
        label: t("statistics.attacks"),
        data: valuesGiornoFinal,
      },
    };
  }

  function getColorFromValue(value, maxValue) {
    const valuePercentage = value / maxValue;
    if (valuePercentage <= 0.01) {
      // return "#3b4081";
      return "#fafafa";
    }
    if (valuePercentage > 0.01 && valuePercentage <= 0.1) {
      return "#F6FAFF";
    }
    if (valuePercentage > 0.1 && valuePercentage <= 0.2) {
      return "#E5F0FF";
    }
    if (valuePercentage > 0.2 && valuePercentage <= 0.3) {
      return "#CFE0FA";
    }
    if (valuePercentage > 0.3 && valuePercentage <= 0.4) {
      return "#B3D2FF";
    }
    if (valuePercentage > 0.4 && valuePercentage <= 0.5) {
      return "#8BB5F3";
    }
    if (valuePercentage > 0.5 && valuePercentage <= 0.6) {
      return "#AB8AEC";
    }
    if (valuePercentage > 0.6 && valuePercentage <= 0.7) {
      return "#9965FF";
    }
    if (valuePercentage > 0.7 && valuePercentage <= 0.8) {
      return "#8550EE";
    }
    if (valuePercentage > 0.8 && valuePercentage <= 0.95) {
      return "#7547D1";
    }
    if (valuePercentage > 0.95) {
      return "#541ACF";
    }
    return "#f0f0f0";
  }

  // function getOpacityFromValue(value, maxValue) {
  //   const valuePercentage = value / maxValue;
  //   // console.log("opacity", (valuePercentage * 10) % 1);
  //   if (valuePercentage >= 0.2) {
  //     const valueToCheck = (valuePercentage * 10) % 1;
  //     if (valueToCheck > 0 && valueToCheck <= 0.4) {
  //       return 0.6;
  //     }
  //     if (valueToCheck > 0.4 && valueToCheck <= 0.8) {
  //       return 0.8;
  //     }
  //     return 1;
  //   }
  //   // const valueToCheck = (valuePercentage * 10) % 1;
  //   // if (valueToCheck > 0 && valueToCheck <= 0.4) {
  //   //   return 0.6;
  //   // }
  //   // if (valueToCheck > 0.4 && valueToCheck <= 0.8) {
  //   //   return 0.8;
  //   // }
  //   // return 1;
  //   if ((valuePercentage * 10) % 1) {
  //     return (valuePercentage * 10) % 1;
  //   }
  //   return 1;
  // }

  // eslint-disable-next-line
  const mapStyle = ({ countryValue, countryCode, minValue, maxValue, color }) => ({
    fill: countryValue ? getColorFromValue(countryValue, maxValue) : "#fff",
    // fillOpacity: countryValue ? getOpacityFromValue(countryValue, maxValue) : 0,
    fillOpacity: 1,
    stroke: "#2C74E1",
    strokeWidth: 1,
    strokeOpacity: 0.2,
    cursor: "pointer",
  });

  const dataMap = [];

  // function getTopCountriesThreats() {
  //   let result;
  //   if (dataMap) {
  //     const sorted = dataMap.sort((a, b) => a.value - b.value).reverse();
  //     result = sorted.filter((item, index) => index < 15);
  //   }
  //   return result;
  // }

  // let dataPerNazioni = null;
  // let dataNumeriPerNazioni = null;
  // let attacchiPerCountryToUse = "attacchi_per_country";
  if (regionCounter && regionCounter.length > 0) {
    const labelsNazioni = [];
    const valuesNazioni = [];

    // dataNumeriPerNazioni = statisticsToUse[attacchiPerCountryToUse];
    regionCounter.forEach((country) => {
      if (country.value > maxNumberCountryMap) {
        maxNumberCountryMap = country.value;
        maxValueCountryMap = country.label;
      }
      if (dataMap.some((countryInside) => countryInside.country === country.label)) {
        const indexElementToImprove = dataMap.findIndex(
          (countryInside) => countryInside.country === country.label
        );
        if (indexElementToImprove >= 0) {
          dataMap[indexElementToImprove].value += country.value;
          if (maxValueCountryMap === country.label) {
            maxNumberCountryMap += country.value;
          }
        }
      }
      if (country.value > 15) {
        labelsNazioni.push(country.label);
        valuesNazioni.push(country.value);
      }
    });
    // dataPerNazioni = {
    //   labels: labelsNazioni,
    //   datasets: [
    //     {
    //       label: "Attacchi",
    //       color: "success",
    //       data: valuesNazioni,
    //     },
    //   ],
    // };
  }
  // const topCountriesThreats = getTopCountriesThreats();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {callsCount >= 3 ? (
        <MDBox>
          <MDBox>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={7}>
                <Grid
                  container
                  className="boxes-border-radius"
                  sx={{
                    background: "white",
                    height: "100%",
                    boxShadow:
                      "0rem 0.25rem 0.375rem -0.0625rem rgb(0 0 0 / 10%), 0rem 0.125rem 0.25rem -0.0625rem rgb(0 0 0 / 6%)",
                  }}
                >
                  <MDBox
                    className="home-box-header"
                    sx={{
                      width: "100%",
                      background: "#2C74E1",
                    }}
                  >
                    <MDTypography variant="header3" color="light" fontWeight="bold">
                      {t("analytics.title_digital_identity")}
                    </MDTypography>
                  </MDBox>
                  <Grid container className="overview-box-grid">
                    <StealersStatistics
                      columns={6}
                      boxShadow="none"
                      border="1px solid rgba(0,0,0,0.1)"
                      padding="0px"
                    />
                  </Grid>
                </Grid>
              </Grid>
              {dataToDays ? <Alerts /> : <div className="home loader" />}
              {dataToDays && (
                <Grid item>
                  <Grid
                    item
                    container
                    sm={12}
                    md={12}
                    lg={12}
                    className="boxes-border-radius"
                    sx={{
                      background: "white",
                      boxShadow:
                        "0rem 0.25rem 0.375rem -0.0625rem rgb(0 0 0 / 10%), 0rem 0.125rem 0.25rem -0.0625rem rgb(0 0 0 / 6%)",
                      alignItems: "flex-start",
                    }}
                  >
                    <MDBox className="home-box-header" width="100%" sx={{ background: "#2C74E1" }}>
                      <MDTypography variant="header3" color="white" fontWeight="bold">
                        {t("analytics.title_cyber_isecurity_threats")}
                      </MDTypography>
                    </MDBox>
                    <Grid
                      item
                      container
                      display="flex"
                      justifyContent="space-between"
                      sx={{ height: "370px" }}
                      lg={6}
                    >
                      {stolenCredentials ? (
                        <MDBox
                          className="dashboard-credentials-boxes"
                          mb={2}
                          mr={1}
                          pl={4}
                          pt={2}
                          pb={2}
                        >
                          <MDTypography variant="h6" color="dark" fontWeight="bold" mb={1}>
                            {t("analytics.title_credentials")}
                          </MDTypography>
                          <MDTypography
                            variant="h3"
                            fontSize="1.5em"
                            color="dark"
                            fontWeight="bold"
                            sx={{ lineHeight: "1" }}
                          >
                            {stolenCredentials}
                          </MDTypography>
                          <MDTypography
                            component="div"
                            variant="caption"
                            fontSize="small"
                            fontWeight="regular"
                            sx={{ color: "#669FF6" }}
                          >
                            {t("analytics.last_week")}
                          </MDTypography>
                        </MDBox>
                      ) : null}
                      {sitesIndexed && sitesIndexed > 0 ? (
                        <MDBox
                          className="dashboard-credentials-boxes"
                          mb={2}
                          ml={1}
                          pl={4}
                          pt={2}
                          pb={2}
                        >
                          <MDTypography variant="h6" color="dark" fontWeight="bold" mb={1}>
                            {t("analytics.title_darkweb_sites")}
                          </MDTypography>
                          <MDTypography
                            variant="h3"
                            fontSize="1.5em"
                            color="dark"
                            fontWeight="bold"
                            sx={{ lineHeight: "1" }}
                          >
                            {sitesIndexed}
                          </MDTypography>
                          <MDTypography
                            component="div"
                            variant="caption"
                            fontSize="small"
                            fontWeight="regular"
                            sx={{ color: "#669FF6" }}
                          >
                            {t("analytics.last_24h")}
                          </MDTypography>
                        </MDBox>
                      ) : null}
                      <MDBox width="100%">
                        <ReportsLineChart
                          color="dark"
                          title={t("statistics.attacks_per_hour")}
                          chart={dataToDays}
                        />
                      </MDBox>
                    </Grid>
                    <Grid
                      item
                      container
                      id="global-box-line"
                      lg={0.5}
                      justifyContent="center"
                      alignSelf="center"
                    >
                      <hr id="home-global-line-divider-one" />
                    </Grid>
                    <hr id="home-global-line-divider-two" />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </MDBox>
          {/* <HeatMap
            mapStyle={mapStyle}
            dataMap={dataMap}
            topCountriesThreats={topCountriesThreats}
            maxNumberCountryMap={maxNumberCountryMap}
          /> */}
          <AntivirusBox />
        </MDBox>
      ) : (
        <div className="loader home" />
      )}
    </DashboardLayout>
  );
}

export default Analytics;
