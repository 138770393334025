// @mui material components
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import getTokens from "api/getTokens";
import getAllLogs from "api/logs/getAllLogs";
import moment from "moment";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import MDInput from "components/MDInput";

// Material Dashboard 2 PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

function LogList() {
  const [logs, setLogs] = useState([]);
  const [originalLogs, setOriginalLogs] = useState([]);
  const [orderBy, setOrderBy] = useState("dateReverse");
  const [type, setType] = useState("");
  const [email, setEmail] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    const tokens = getTokens();
    getAllLogs(tokens.userid)
      .then((res) => {
        if (res.results && res.results.length > 0) {
          setOriginalLogs(res.results);
          setLogs(res.results.sort((a, b) => b.date.localeCompare(a.date)));
        }
      })
      // eslint-disable-next-line
      .catch((err) => console.log("error", err));
  }, []);

  function refreshLogs() {
    const tokens = getTokens();
    getAllLogs(tokens.userid)
      .then((res) => {
        if (res.results && res.results.length > 0) {
          setOriginalLogs(res.results);
          setLogs(res.results.sort((a, b) => b.date.localeCompare(a.date)));
          setOrderBy("dateReverse");
        }
      })
      // eslint-disable-next-line
      .catch((err) => console.log("error", err));
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div style={{ margin: "30px 0", width: "100%", textAlign: "right" }}>
        <IconButton
          onClick={() => {
            refreshLogs();
          }}
          size="small"
          disableRipple
        >
          {t("refresh")} <Icon fontSize="medium">replay</Icon>
        </IconButton>
      </div>
      {originalLogs && originalLogs.length > 0 ? (
        <div className="table_users_list">
          <table style={{ border: "solid 1px #dedede", width: "100%" }}>
            <thead>
              <tr>
                <th
                  style={{
                    padding: "10px 0",
                    borderBottom: "solid 1px #dedede",
                    width: "25%",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    const filterLogs = [].concat(logs);
                    if (orderBy === "type") {
                      setLogs(filterLogs.sort((a, b) => b.type.localeCompare(a.type)));
                      setOrderBy("typeReverse");
                    } else {
                      setLogs(filterLogs.sort((a, b) => a.type.localeCompare(b.type)));
                      setOrderBy("type");
                    }
                  }}
                >
                  {t("admin.type")}
                  {orderBy === "type" ? <Icon fontSize="medium">south</Icon> : null}
                  {orderBy === "typeReverse" ? <Icon fontSize="medium">north</Icon> : null}
                </th>
                <th
                  style={{
                    padding: "10px 0",
                    borderBottom: "solid 1px #dedede",
                    width: "25%",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    const filterLogs = [].concat(logs);
                    if (orderBy === "email") {
                      setLogs(filterLogs.sort((a, b) => b.email.localeCompare(a.email)));
                      setOrderBy("emailReverse");
                    } else {
                      setLogs(filterLogs.sort((a, b) => a.email.localeCompare(b.email)));
                      setOrderBy("email");
                    }
                  }}
                >
                  {t("admin.email")}
                  {orderBy === "email" ? <Icon fontSize="medium">south</Icon> : null}
                  {orderBy === "emailReverse" ? <Icon fontSize="medium">north</Icon> : null}
                </th>
                <th
                  style={{
                    padding: "10px 0",
                    borderBottom: "solid 1px #dedede",
                    width: "25%",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    const filterLogs = [].concat(logs);
                    if (orderBy === "date") {
                      setLogs(filterLogs.sort((a, b) => b.date.localeCompare(a.date)));
                      setOrderBy("dateReverse");
                    } else {
                      setLogs(filterLogs.sort((a, b) => a.date.localeCompare(b.date)));
                      setOrderBy("date");
                    }
                  }}
                >
                  {t("date")}
                  {orderBy === "date" ? <Icon fontSize="medium">south</Icon> : null}
                  {orderBy === "dateReverse" ? <Icon fontSize="medium">north</Icon> : null}
                </th>
              </tr>
            </thead>
            <tbody style={{ fontSize: "14px", textAlign: "center" }}>
              <tr>
                <th
                  style={{
                    padding: "10px 0",
                    borderBottom: "solid 1px #dedede",
                    width: "25%",
                    textAlign: "center",
                  }}
                >
                  <MDInput
                    type="input"
                    label={t("admin.type")}
                    fullWidth
                    value={type}
                    onChange={(e) => {
                      setType(e.target.value);
                      const filterLogs = [].concat(originalLogs);
                      setLogs(
                        filterLogs.filter((el) =>
                          el.type.toLowerCase().includes(e.target.value.toLowerCase())
                        )
                      );
                    }}
                  />
                </th>
                <th
                  style={{
                    padding: "10px",
                    borderBottom: "solid 1px #dedede",
                    width: "25%",
                    textAlign: "center",
                  }}
                >
                  <MDInput
                    type="input"
                    label={t("admin.email")}
                    fullWidth
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      const filterLogs = [].concat(originalLogs);
                      setLogs(
                        filterLogs.filter((el) =>
                          el.email.toLowerCase().includes(e.target.value.toLowerCase())
                        )
                      );
                    }}
                  />
                </th>
                <th
                  style={{
                    padding: "10px 0",
                    borderBottom: "solid 1px #dedede",
                    width: "25%",
                    textAlign: "center",
                  }}
                >
                  /
                </th>
              </tr>
              {logs.map((el, index) => (
                <tr style={{ borderBottom: "solid 1px #dedede" }} key={`user-${index.toString()}`}>
                  <td style={{ borderBottom: "solid 1px #dedede", padding: "10px 0" }}>
                    {el.type}
                  </td>
                  <td style={{ borderBottom: "solid 1px #dedede", padding: "10px 0" }}>
                    {el.email}
                  </td>
                  <td style={{ borderBottom: "solid 1px #dedede", padding: "10px 0" }}>
                    {moment(el.date).format("lll")}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : null}
    </DashboardLayout>
  );
}

export default LogList;
