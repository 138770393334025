const analytics = {
  en: {
    translation: {
      title_digital_identity: "Overview",
      no_alerts: "No alerts found!",
      title_cyber_isecurity_threats: "Global cyber security threats",
      title_credentials: "Stolen credentials analyzed",
      last_week: "last week",
      title_darkweb_sites: "Active Darkweb sites scanned",
      last_24h: "last 24h",
      title_stolen_credentials: "Stolen identity",
      credentials_question_mark: "EN Info",
      title_ransomware_attacks: "Ransomware attacks",
      worldwide: "worldwide",
      ransomware_attacks: "Ransomware Incident",
      last_12_months: "last 12 months",
      ransomware_question_mark: "EN Info",
      alerts: "Alerts",
      title_attacks_trend: "Attacks trend",
      title_stealer_cookie_found: "Stolen credentials risk",
      title_credentials_found: "Stolen credentials risk",
      text_credentials_found:
        "credentials were found on attacks on your account, credentials may have been compromised",
      text_stealer_cookie_found:
        "We have found an attack on your account, a cookie may have been compromised",
      title_stealer_password_found: "Stolen credentials risk",
      text_stealer_password_found:
        "We have found an attack on your account, a password may have been compromised",
    },
  },
  it: {
    translation: {
      title_digital_identity: "Panoramica",
      no_alerts: "Nessuna notifica trovata!",
      title_cyber_isecurity_threats: "Minacce globali",
      title_credentials: "Credenziali rubate analizzate",
      last_week: "nell'ultima settimana",
      title_darkweb_sites: "Siti nel darkweb scansionati",
      last_24h: "nelle ultime 24 ore",
      title_stolen_credentials: "Identità rubate",
      credentials_question_mark: "Info ITA",
      title_ransomware_attacks: "Attacchi ransomware",
      worldwide: "nel mondo",
      ransomware_attacks: "Attacchi Ransomware",
      last_12_months: "negli ultimi 12 mesi",
      ransomware_question_mark: "Info ITA",
      title_attacks_trend: "Andamento attacchi",
      alerts: "Notifiche",
      title_stealer_cookie_found: "Rischio di furto credenziali",
      title_credentials_found: "Rischio di furto credenziali",
      text_credentials_found:
        "credenzali sono state trovate in attacchi sul tuo account, le credenziali potrebbero essere state compromesse",
      text_stealer_cookie_found:
        "Abbiamo riscontrato un attacco nel tuo account, un cookie potrebbe essere stato compromesso",
      title_stealer_password_found: "Rischio di furto credenziali",
      text_stealer_password_found:
        "Abbiamo riscontrato un attacco nel tuo account, una password potrebbe essere stato compromesso",
    },
  },
};

export default analytics;
