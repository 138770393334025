import ransomware from "language/pages/ransomware";
import stealers from "language/pages/stealers";
import lensPhishing from "language/pages/lensPhishing";
import admin from "language/pages/admin";
import countryCode from "language/countryCode";
import countryList from "language/countryList";
import sidenav from "language/sidenav";
import statistics from "language/pages/statistics";
import auth from "language/pages/auth";
import analytics from "language/pages/analytics";

const resources = {
  lng: "it",
  defaultLanguage: "it",
  otherLanguages: ["it"],
  resources: {
    en: {
      translation: {
        test: "I18next test: english",
        hello: "Hello",
        cancel: "Cancel",
        close: "Close",
        save: "Save",
        ok: "Ok",
        yes: "Yes",
        no: "No",
        edit: "Edit",
        delete: "Delete",
        filters: "Filters",
        details: "Details",
        date: "Date",
        search: "Search",
        show: "Show",
        select_columns: "Customize columns shown",
        copy: "Copy",
        refresh: "Refresh",
        displayed: "Displayed",
        minutes: "Minutes",
        seconds: "Seconds",
        all_customers: "All customers",
        viewing: "Viewing",
        you_have: "You have",
        stealers_to_handle: "stealers to handle",
        token_expired: "Token expired",
        token_expired_text: "It seems your token expired, please, refresh your page.",
        filter: "Filter",
        ransomware: ransomware.en.translation,
        stealers: stealers.en.translation,
        lensPhishing: lensPhishing.en.translation,
        admin: admin.en.translation,
        statistics: statistics.en.translation,
        auth: auth.en.translation,
        analytics: analytics.en.translation,
        sidenav: sidenav.en.translation,
        countryCode: countryCode.en.translation,
        countryList: countryList.en.translation,
      },
    },
    it: {
      translation: {
        test: "I18next test: italian",
        hello: "Ciao",
        cancel: "Annulla",
        close: "Chiudi",
        save: "Salva",
        ok: "Ok",
        yes: "Si",
        no: "No",
        edit: "Modifica",
        filters: "Filtri",
        details: "Dettagli",
        delete: "Elimina",
        date: "Data",
        search: "Cerca",
        show: "Visualizza",
        select_columns: "Personalizza colonne visualizzate",
        copy: "Copia",
        refresh: "Aggiorna",
        displayed: "Visualizzati",
        minutes: "Minuti",
        seconds: "Secondi",
        all_customers: "Tutti i clienti",
        viewing: "Visualizzando",
        you_have: "Hai",
        stealers_to_handle: "stealers da gestire",
        token_expired: "Token scaduto",
        token_expired_text: "Il tuo token è scaduto, per favore, ricarica la pagina.",
        filter: "Filtri",
        ransomware: ransomware.it.translation,
        stealers: stealers.it.translation,
        lensPhishing: lensPhishing.it.translation,
        admin: admin.it.translation,
        statistics: statistics.it.translation,
        auth: auth.it.translation,
        analytics: analytics.it.translation,
        sidenav: sidenav.it.translation,
        countryCode: countryCode.it.translation,
        countryList: countryList.it.translation,
      },
    },
  },
};

export default resources;
