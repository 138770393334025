const auth = {
  en: {
    translation: {
      reset_password: "Reset password",
      forgot_password_error_title: "Ops, something went wrong!",
      forgot_password_error_text: "Check the email you wrote or try again in a moment",
      passwords_not_matching: "Passwords are not matching",
      email_sent: "Check you email",
      mfa_instruction: "lorem ipsum",
    },
  },
  it: {
    translation: {
      reset_password: "Reset password",
      forgot_password_error_title: "Ops, qualcosa è andato storto!",
      forgot_password_error_text: "Controlla i dati immessi o riprova fra qualche minuto",
      passwords_not_matching: "Le passwords non coincidono",
      email_sent: "Email inviata",
      mfa_instruction: "lorem ipsum",
    },
  },
};

export default auth;
