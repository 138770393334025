const sidenav = {
  en: {
    translation: {
      dashboard: "Dashboard",
      analytics: "Analytics",
      analytics_stealer: "Analytics stealer",
      analytics_ransomware: "Analytics ransomware",
      ransomware: "Ransomware",
      stealers: "Stealers",
      combos: "Combos",
      stealers_to_manage: "Stealers to manage",
      duplicate_stealers: "Duplicate stealers",
      lens_phishing: "Lens Phishing",
      admin: "Admin",
      worker: "Worker",
      userlist: "Userlist",
      add_user: "Add user",
      customers_list: "Customers list",
      add_customer: "Add customer",
      logs: "Logs",
      apikeys: "Apikeys",
      email: "Email",
      alerts: "Alerts",
      credentials: "Credentials",
      passwords: "Digital Identity",
      reportsrequests: "Requests report",
    },
  },
  it: {
    translation: {
      dashboard: "Dashboard",
      analytics: "Statistiche",
      analytics_stealer: "Statistiche stealer",
      analytics_ransomware: "Statistiche ransomware",
      ransomware: "Ransomware",
      stealers: "Stealer",
      combos: "Combo",
      stealers_to_manage: "Stealer da gestire",
      duplicate_stealers: "Stealers duplicati",
      lens_phishing: "Lens Phishing",
      admin: "Amministrator",
      worker: "Worker",
      userlist: "Lista utenti",
      add_user: "Aggiungi utente",
      customers_list: "List clienti",
      add_customer: "Aggiungi cliente",
      logs: "Log",
      apikeys: "Apikeys",
      email: "Email",
      alerts: "Notifiche",
      credentials: "Credenziali",
      passwords: "Identità digitale",
      reportsrequests: "Richieste report",
    },
  },
};

export default sidenav;
