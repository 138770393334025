// @mui material components
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import getRequests from "api/reportsrequests/getRequests";
import moment from "moment";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";

// Material Dashboard 2 PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

function ReportsRequests() {
  const [requests, setRequests] = useState([]);
  // eslint-disable-next-line
  const [pages, setPages] = useState(0);
  // eslint-disable-next-line
  const [page, setPage] = useState(1);
  // eslint-disable-next-line
  const [limit, setLimit] = useState(50);
  const [isRequestReady, setIsRequestReady] = useState(false);
  const { t } = useTranslation();

  function refreshRequests() {
    setIsRequestReady(false);
    getRequests()
      .then((res) => {
        if (res.totalPages) {
          setPages(res.totalPages);
        }
        if (res.results && res.results.length > 0) {
          setRequests(res.results);
          setIsRequestReady(true);
        } else {
          setIsRequestReady(true);
        }
      })
      // eslint-disable-next-line
      .catch((err) => {
        // eslint-disable-next-line
        console.log("error", err);
        setIsRequestReady(true);
      });
  }

  useEffect(() => {
    refreshRequests();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div style={{ margin: "30px 0", width: "100%", textAlign: "right" }}>
        <IconButton
          onClick={() => {
            refreshRequests();
          }}
          size="small"
          disableRipple
        >
          {t("refresh")} <Icon fontSize="medium">replay</Icon>
        </IconButton>
      </div>
      {isRequestReady ? (
        <div>
          {requests && requests.length > 0 ? (
            <div className="table_users_list">
              <table style={{ border: "solid 1px #dedede", width: "100%" }}>
                <thead>
                  <tr>
                    <th
                      style={{
                        padding: "10px 0",
                        borderBottom: "solid 1px #dedede",
                        width: "20%",
                        textAlign: "center",
                      }}
                    >
                      {t("date")}
                    </th>
                    <th
                      style={{
                        padding: "10px 0",
                        borderBottom: "solid 1px #dedede",
                        width: "20%",
                        textAlign: "center",
                      }}
                    >
                      {t("admin.email")}
                    </th>
                    <th
                      style={{
                        padding: "10px 0",
                        borderBottom: "solid 1px #dedede",
                        width: "20%",
                        textAlign: "center",
                      }}
                    >
                      {t("admin.stealer")}
                    </th>
                    <th
                      style={{
                        padding: "10px 0",
                        borderBottom: "solid 1px #dedede",
                        width: "20%",
                        textAlign: "center",
                      }}
                    >
                      {t("admin.status")}
                    </th>
                    <th
                      style={{
                        padding: "10px 0",
                        borderBottom: "solid 1px #dedede",
                        width: "20%",
                        textAlign: "center",
                      }}
                    >
                      {t("admin.status")}
                    </th>
                    <th
                      style={{
                        padding: "10px 0",
                        borderBottom: "solid 1px #dedede",
                        width: "20%",
                        textAlign: "center",
                      }}
                    >
                      {t("edit")}
                    </th>
                  </tr>
                </thead>
                <tbody style={{ fontSize: "14px", textAlign: "center" }}>
                  {requests.map((el, index) => (
                    <tr
                      style={{ borderBottom: "solid 1px #dedede" }}
                      key={`user-${index.toString()}`}
                    >
                      <td style={{ borderBottom: "solid 1px #dedede", padding: "10px 0" }}>
                        {moment(el.date).format("lll")}
                      </td>
                      <td style={{ borderBottom: "solid 1px #dedede", padding: "10px 0" }}>
                        {el.user && el.user.email ? el.user.email : ""}
                      </td>
                      <td style={{ borderBottom: "solid 1px #dedede", padding: "10px 0" }}>
                        <IconButton
                          onClick={() => {
                            window.open(`/detailsStealer?${el.stealer}`, "_blank").focus();
                          }}
                          size="small"
                          disableRipple
                        >
                          <Icon fontSize="medium">info</Icon>
                        </IconButton>
                      </td>
                      <td style={{ borderBottom: "solid 1px #dedede", padding: "10px 0" }}>
                        {el.status}
                      </td>
                      <td style={{ borderBottom: "solid 1px #dedede", padding: "10px 0" }}>
                        {/* TODO edit/cancella/conferma/fatta/gestita... */}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div>{t("admin.no_requests_yet")}</div>
          )}
        </div>
      ) : (
        <div className="loader" />
      )}
    </DashboardLayout>
  );
}

export default ReportsRequests;
