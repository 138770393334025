/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { I18nextProvider, useTranslation /* , Translation */ } from "react-i18next";

import getTokens from "api/getTokens";
import usersPresent from "api/users/usersPresent";
import refreshTokens from "api/auth/refreshTokens";
import getClientsChanged from "api/clients/getClientsChanged";
// import addLog from "api/logs/addLog";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Dashboard 2 PRO React example components
import Sidenav from "examples/Sidenav";

// Material Dashboard 2 PRO React themes
// eslint-disable-next-line
import theme from "assets/theme";

// Material Dashboard 2 PRO React routes
import routesAdmin from "routesAdmin";

import TokenExpiredPopup from "components/TokenExpiredPopup";
import StartPastWorkerPopup from "components/StartPastWorkerPopup";

// Material Dashboard 2 PRO React contexts
import {
  useMaterialUIController,
  setMiniSidenav,
  setUser,
  setCustomers,
  setAllStatistics,
} from "context";

import getClientsByIds from "api/clients/getClientsByIds";
import getUser from "api/users/getUser";
import getAllStatistics from "api/statistics/getAllStatistics";
import counterStealers from "api/statistics/counterStealers";

// Images
import brandDark from "assets/images/logo-light.png";
import brandSmall from "assets/images/loghetto_DEFENDA.png";
// import isLocale from "./translations/types";
// import getInitialLocale from "./translations/getInitialLocale";
import i18next from "./language/i18next";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    sidenavColor,
    user,
    allStatistics,
    // customers,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [isPageReady, setIsPageReady] = useState(false);
  const [couldStartPastWorker, setCouldStartPastWorker] = useState(false);
  const { pathname } = useLocation();
  // eslint-disable-next-line
  const { t, i18n } = useTranslation();

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  const changeLanguage = (lng) => {
    i18next.changeLanguage(lng);
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  useEffect(() => {
    const tokens = getTokens();
    if (
      window.location.href.endsWith("/login") ||
      window.location.href.endsWith("/forgotPassword") ||
      window.location.href.includes("/resetPassword")
    ) {
      setIsPageReady(true);
    }
    if (
      (window.location.href.endsWith("/login") ||
        window.location.href.endsWith("/forgotPassword") ||
        window.location.href.includes("/resetPassword")) &&
      tokens.access &&
      tokens.refresh
    ) {
      window.location.href = "/";
    } else if (
      !window.location.href.endsWith("/login") &&
      !window.location.href.endsWith("/forgotPassword") &&
      !window.location.href.includes("/resetPassword") &&
      !tokens.access &&
      !tokens.refresh
    ) {
      window.location.href = "/login";
    } else if (tokens.userid) {
      refreshTokens().then((responseJson) => {
        if (responseJson && responseJson.access && responseJson.refresh) {
          getUser(tokens.userid, responseJson.access)
            .then((res) => {
              if (res && res.language && res.language !== "it") {
                changeLanguage(res.language);
              }
              if (!user || !user.id) {
                setUser(dispatch, res);
              }
              if (
                (!res || !res.mfaVerified || !tokens.mfa) &&
                !window.location.href.endsWith("/mfa")
              ) {
                window.location.href = "/mfa";
              } else {
                setIsPageReady(true);
                if (
                  res &&
                  res.role &&
                  res.role === "admin" &&
                  !(!res || !res.mfaVerified || !tokens.mfa)
                ) {
                  // IF USER OPENING THE DASHBOARD IS AN ADMIN WE CHECK IF SOME CLIENT HAS CHANGED OR HAS BEEN ADDED
                  // IF SO, WE OPEN A POPUP TO ASK THE ADMIN IF STARTING THE WORKER FOR OLD RESULTS OR NOT
                  getClientsChanged()
                    .then((clientsChanged) => {
                      if (clientsChanged && clientsChanged.clients && clientsChanged.clients > 0) {
                        setCouldStartPastWorker(true);
                      }
                    })
                    .catch((errClientsChanged) => {
                      // eslint-disable-next-line
                      console.log("error while checking for clients changes", errClientsChanged);
                    });
                }
                if (res && res.clients && res.clients.length > 0) {
                  getClientsByIds({ ids: res.clients })
                    .then((clients) => {
                      if (clients && clients.length > 0) {
                        setCustomers(dispatch, clients);
                      }
                    })
                    // eslint-disable-next-line
                    .catch((err) => console.log("error", err));
                }
              }
            })
            // eslint-disable-next-line
            .catch((err) => console.log("error", err));
          if (!allStatistics) {
            getAllStatistics()
              .then((res) => {
                if (res && res.results && res.results.length > 0) {
                  const objectToSave = { ...res.results[0] };
                  counterStealers().then((counters) => {
                    if (counters && counters.completeStealers) {
                      Object.assign(objectToSave, { completeStealers: counters.completeStealers });
                    }
                    if (counters && counters.incompleteStealers) {
                      Object.assign(objectToSave, {
                        incompleteStealers: counters.incompleteStealers,
                      });
                    }
                    if (counters && counters.stealersToHandle) {
                      Object.assign(objectToSave, {
                        stealersToHandle: counters.stealersToHandle,
                      });
                    }
                    setAllStatistics(dispatch, objectToSave);
                  });
                }
              })
              // eslint-disable-next-line
            .catch((err) => console.log("error", err));
          }
        } else {
          localStorage.removeItem("refresh");
          localStorage.removeItem("access");
          localStorage.removeItem("userid");
          localStorage.removeItem("mfa");
          window.location.href = "/login";
        }
      });
    } else {
      // window.location.href = "/login";
    }
  }, [controller.user]);

  useEffect(() => {
    if (controller.user && controller.user.language) {
      i18next.changeLanguage(controller.user.language);
    }
  }, [controller.user]);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  useEffect(() => {
    usersPresent();
  }, []);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  return (
    <>
      <I18nextProvider i18n={i18next}>
        {isPageReady ? (
          <ThemeProvider theme={theme}>
            <CssBaseline />
            {couldStartPastWorker ? (
              // POPUP FOR OLD RESULTS
              <StartPastWorkerPopup
                closePopup={() => {
                  setCouldStartPastWorker(false);
                }}
              />
            ) : null}
            <TokenExpiredPopup />
            {layout === "dashboard" && (
              <>
                <Sidenav
                  color={sidenavColor}
                  brand={!miniSidenav ? brandDark : brandSmall}
                  brandName="Defenda"
                  routes={routesAdmin}
                  onMouseEnter={handleOnMouseEnter}
                  onMouseLeave={handleOnMouseLeave}
                />
              </>
            )}
            <Routes>
              {getRoutes(routesAdmin)}
              <Route path="*" element={<Navigate to="/dashboard" />} />
            </Routes>
          </ThemeProvider>
        ) : null}
      </I18nextProvider>
    </>
  );
}
