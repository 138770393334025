import config from "config/config";
import getTokens from "api/getTokens";

const { baseUrl } = config;

/* *********************
  email
  password
********************** */

export default function updateClientsDomains() {
  const clientUrl = process.env.REACT_APP_ACCESS_CONTROL_ALLOW_ORIGIN || "";
  const endpoint = `${baseUrl}/v1/clients/start-worker-back`;
  let access = "";
  const tokens = getTokens();
  if (tokens && tokens.access && tokens.access.token) {
    access = tokens.access.token;
  } else {
    window.location.href = "/login";
  }
  const options = {
    method: "GET",
    mode: "cors",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `${clientUrl}`,
      "Access-Control-Allow-Credentials": true,
      Authorization: `bearer ${access}`,
    },
  };
  // console.log('login endpoint', {endpoint, BODY });
  return fetch(endpoint, options)
    .then((response) => {
      // console.log('login response', response);
      if (response.status !== 200 && response.status !== 201 && response.status !== 204) {
        throw new Error();
      }
      return response.json();
    })
    .catch((e) => {
      // eslint-disable-next-line
      console.log("error", e);
    });
}
