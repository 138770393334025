const admin = {
  en: {
    translation: {
      time: "Time",
      tag: "Tag",
      add: "Add",
      elements_displayed: "Elements displayed",
      duplicates_generic: "Duplicates (generic)",
      duplicates_details: "Duplicates (details)",
      time_elapsed: "Time elapsed",
      elements: "Imported elements",
      stealers: "Stealers",
      ransomware: "Ransomware",
      duplicates: "Duplicates",
      duplicates_cc: "Duplicates c/c",
      duplicates_ci: "Duplicates c/i",
      duplicates_ic: "Duplicates i/c",
      duplicates_ii: "Duplicates i/i",
      no_worker_yet: "No worker statistics yet available",
      email: "Email",
      role: "Role",
      verified_email: "Email verified",
      two_factors: "Two factors",
      customer: "Customer",
      name: "Name",
      vatid_cf: "VAT ID/FC",
      passwords_domains: "Passwords domains",
      cookies_url: "Cookies url",
      cookies_name: "Cookies name",
      password: "Password",
      type: "Type",
      your_new_token: "Your new token",
      close_now: "Close now",
      token_visible_60_seconds: "Your token will be visible for 60 seconds",
      delete: "Delete",
      stealer: "Stealer",
      status: "Status",
      no_requests_yet: "There are no reports requests, yet",
      delete_success_text: "Token deleted succesfully",
      delete_error_text: "Ops, something went wrong, try again later",
      confirmation_delete_text: "Do you really want to delete token for user",
      restore_token: "Refresh token",
      user_updated: "User updated",
      user_updated_text: "User was updated succesfully",
      user_created: "User created",
      user_created_text: "User was created succesfully",
      customer_edited: "Customer updated",
      customer_edited_text: "Customer was updated succesfully",
      customer_created: "Customer creted",
      customer_created_text: "Customer was created succesfully",
      error_create_customer_text: "Something went wrong, please try again soon",
      error_create_customer_text_data: "Please, add the customer's name",
      error_create_user_text:
        "Something went wrong, check that email was not registered and that password has at least one capital letter, one number and at least 8 characters long",
      start_script_domains: "Refresh customers domains",
      start_script_domains_text:
        "It seems like some of the customers were added or updated recently, do you want to start the worker to update their results?",
      start_script_domains_confirm: "Start worker now",
      start_script_domains_cancel: "Maybe later",
      worker_requesting_title: "Requesting",
      worker_requesting_text: "Requesting to start worker, it may take time",
      worker_requesting_error_title: "Error",
      worker_requesting_error_text: "Error while starting worker, please try again in some minutes",
      worker_started_title: "Worker started",
      worker_started_text: "Worker is now elaborating the new customers domains, it may take time",
      usernames: "Usernames",
      url_already_in_title: "Content already under control",
      url_already_in_text:
        "It seems like the parameter you want to add to this customer is already present in another customer",
      invalid_email: "Invalid email format",
      password_info: "lorem ipsum",
      cookies_info: "lorem ipsum",
    },
  },
  it: {
    translation: {
      time: "Tempo",
      tag: "Tag",
      add: "Aggiungi",
      elements_displayed: "Elementi visualizzati",
      duplicates_generic: "Duplicati (generico)",
      duplicates_details: "Duplicati (dettaglio)",
      time_elapsed: "Tempo trascorso",
      elements: "Elementi importati",
      stealers: "Stealer",
      ransomware: "Ransomware",
      duplicates: "Duplicati",
      duplicates_cc: "Duplicati c/c",
      duplicates_ci: "Duplicati c/i",
      duplicates_ic: "Duplicati i/c",
      duplicates_ii: "Duplicati i/i",
      no_worker_yet: "Non ci sono ancora statistiche per il worker",
      email: "Email",
      role: "Ruolo",
      verified_email: "Email verificata",
      two_factors: "Due fattori",
      customer: "Cliente",
      name: "Nome",
      vatid_cf: "P.IVA/CF",
      passwords_domains: "Domini passwords",
      cookies_url: "Cookies url",
      cookies_name: "Cookies name",
      password: "Password",
      type: "Tipo",
      your_new_token: "Il tuo nuovo token",
      close_now: "Chiudi ora",
      token_visible_60_seconds: "Il token rimarrà visibile per 60 secondi",
      delete: "Eliminazione",
      stealer: "Stealer",
      status: "Status",
      no_requests_yet: "There are no reports requests, yet",
      delete_success_text: "Il token è stato eliminato con successo",
      delete_error_text: "Ops, qualcosa è andato storto, riprova a breve",
      confirmation_delete_text: "Vuoi davvero eliminare il token dell'utente",
      restore_token: "Rigenera token",
      user_updated: "Utente aggiornato",
      user_updated_text: "L'utente è stato aggiornato con successo",
      user_created: "Utente creato",
      user_created_text: "L'utente è stato creato con successo",
      customer_edited: "Cliente modificato",
      customer_edited_text: "Il cliente è stato modificato con successo",
      customer_created: "Cliente creato",
      customer_created_text: "Il cliente è stato creato con successo",
      error_create_customer_text: "Qualcosa è andato storto, ti preghiamo di riprovare più tardi",
      error_create_customer_text_data: "Per favore, inserisci il nome del cliente per continuare",
      error_create_user_text:
        "Qualcosa è andato storto, controlla che la mail non sia già stata registrata e che la password contenga almeno una lettera maiuscola ed un numero e sia di almeno 8 caratteri",
      start_script_domains: "Aggiorna domini associati ai clienti",
      start_script_domains_text:
        "Sembra che alcuni clienti siano stati aggiunti o aggiornati recentemente, vuoi avviare il worker per aggiorare i loro risultati?",
      start_script_domains_confirm: "Avvia il worker adesso",
      start_script_domains_cancel: "Forse più tardi",
      worker_requesting_title: "Richiesta in corso",
      worker_requesting_text: "Richiesto di avviare il worker, potrebbe volerci un po'",
      worker_requesting_error_title: "Errore",
      worker_requesting_error_text: "Errore durante l'avvio del worker, riprova fra qualche minuto",
      worker_started_title: "Il worker è partito",
      worker_started_text:
        "Il worker sta ora elaborando i nuovi domini dei clienti, potrebbe volerci un po'",
      usernames: "Usernames",
      url_already_in_title: "Contenuto già presente",
      url_already_in_text:
        "Sembra che il parametro che vuoi aggiungere per questo cliente è già presente in un altro cliente",
      invalid_email: "Formato email non valido",
      password_info: "lorem ipsum",
      cookies_info: "lorem ipsum",
    },
  },
};

export default admin;
