import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMaterialUIController } from "context";

// Material Dashboard 2 PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// @mui material components
import Grid from "@mui/material/Grid";

// import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
// import MailOutlineIcon from "@mui/icons-material/MailOutline";
// import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

// Import icons images
import Sender from "../../../assets/images/email-details-icon/sender.png";
import To from "../../../assets/images/email-details-icon/to.png";
import Email from "../../../assets/images/email-details-icon/email.png";
import Date from "../../../assets/images/email-details-icon/date.png";
import Checks from "../../../assets/images/email-details-icon/checks.png";

function detailsLensPhishing() {
  // eslint-disable-next-line
  const [controller, dispatch] = useMaterialUIController();
  const [lensPhishing, setLensPhishing] = useState([]);
  // eslint-disable-next-line
  const [showPasswordsAdmin, setShowPasswordsAdmin] = useState(null);
  // eslint-disable-next-line
  const [showCookiesAdmin, setShowCookiesAdmin] = useState(null);
  // eslint-disable-next-line
  const { client, user } = controller;
  const { t } = useTranslation();

  useEffect(() => {
    setLensPhishing({
      client: "Green Energy Bank spa",
      user: "test@greenenergybank.it",
      date: "2022-11-10T03:24:00",
      object: "Attenzione: comportamento sospetto sul tuo account! Verifca subito la password",
      response: "danger",
      time: "18 minuti",
      operator: "Mario Rossi",
      sender: "info@infoinfo.net",
      threat_found: "Business Email Compromise",
      controls: [
        { level: "low", check: "Domain age", score: "55-20" },
        { level: "medium", check: "Domain TTL", score: "10" },
        { level: "high", check: "Domain reputaion (blacklist)", score: "10" },
        { level: "medium", check: "Syntax keywords", score: "35" },
        { level: "high", check: "External link", score: "15" },
        { level: "low", check: "Attachment", score: "40" },
        { level: "low", check: "Syntax keywords", score: "35" },
      ],
    });
  }, []);

  if (!lensPhishing) {
    return null;
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div className="attack-details-container">
        <h2 className="page-title">{t("lensPhishing.lensPhishing_details")}</h2>
      </div>
      <MDBox>
        <Grid
          container
          spacing={2}
          className="email-details-top-box"
          justifyContent="space-between"
          pt={2}
          mb={4}
        >
          <Grid item xs={12} md={6} lg={5} mb={2} display="flex" alignItems="center">
            <img src={To} alt="to-icon" className="details-pages-icons" />
            <MDTypography ml={2} fontSize="medium">
              {lensPhishing.sender}
            </MDTypography>
          </Grid>
          <Grid item xs={12} md={6} lg={5} mb={2} display="flex" alignItems="center">
            <img
              src={Sender}
              alt="sender-icon"
              className="details-pages-icons"
              id="email-details-icon-sender"
            />
            <MDTypography ml={2} fontSize="medium">
              {lensPhishing.user}
            </MDTypography>
          </Grid>
          <Grid item xs={12} md={6} lg={5} mb={2} display="flex" alignItems="flex-start">
            <img src={Email} alt="email-icon" className="details-pages-icons" />
            <MDTypography ml={2} fontSize="medium">
              {lensPhishing.object}
            </MDTypography>
          </Grid>
          <Grid item xs={12} md={6} lg={5} mb={2} display="flex" alignItems="flex-start">
            <img src={Date} alt="date-icon" className="details-pages-icons" />
            <MDTypography ml={2} fontSize="medium">
              {lensPhishing.date}
            </MDTypography>
          </Grid>
        </Grid>
      </MDBox>
      <div id="email-details-checks-box">
        <img src={Checks} alt="email-icon" className="details-pages-icons" />
        <h3>{t("lensPhishing.checks")}</h3>
      </div>
      <div className="table_users_list">
        <table className="table-content" cellSpacing="0" cellPadding="0">
          <thead>
            <tr>
              <th>{t("lensPhishing.level")}</th>
              <th>{t("lensPhishing.check")}</th>
              <th>{t("lensPhishing.score")}</th>
            </tr>
          </thead>
          <tbody>
            {lensPhishing && lensPhishing.controls && lensPhishing.controls.length > 0
              ? lensPhishing.controls.map((con, index) => (
                  <tr key={`control-key-${index.toString()}`}>
                    <td className={`email-details-risk-${con.level}`}>
                      {con.level ? con.level : ""}
                    </td>
                    <td>{con.check ? con.check : ""}</td>
                    <td>{con.score ? con.score : ""}</td>
                  </tr>
                ))
              : null}
          </tbody>
        </table>
      </div>
    </DashboardLayout>
  );
}

export default detailsLensPhishing;
