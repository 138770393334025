// @mui material components
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import getStealersToHandle from "api/stealer/getStealersToHandle";
import editStealer from "api/stealer/editStealer";
import getStealersToHandleBySites from "api/stealer/getStealersToHandleBySites";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import moment from "moment";
import Tooltip from "@mui/material/Tooltip";
import MDInput from "components/MDInput";
import SweetAlert from "react-bootstrap-sweetalert";
import Datetime from "react-datetime";
import { useMaterialUIController } from "context";
import { useNavigate } from "react-router-dom";

// Material Dashboard 2 PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Pagination from "layouts/dashboards/stealers/pagination";
import Autocomplete from "layouts/dashboards/stealers/autocomplete";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import countryCode from "config/countryCode";

function StealersToHandle() {
  const [attacks, setAttacks] = useState([]);
  const [originalAttacks, setOriginalAttacks] = useState([]);
  // eslint-disable-next-line
  const [pages, setPages] = useState(0);
  // eslint-disable-next-line
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(50);
  const [nation, setNation] = useState("");
  // eslint-disable-next-line
  const [domain, setDomain] = useState("");
  const [reason, setReason] = useState("");
  const [hash, setHash] = useState("");
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  // eslint-disable-next-line
  const [fieldsShown, setFieldsShown] = useState([
    "Nazione",
    "Dominio",
    "Motivo",
    "Hash",
  ]);
  const [showDomains, setShowDomains] = useState(false);
  const [domainsToShow, setDomainsToShow] = useState(null);
  const [isRequestReady, setIsRequestReady] = useState(false);
  const [elementToDuplicate, setElementToDuplicate] = useState("");
  // eslint-disable-next-line
  const [cookieToView, setCookieToView] = useState(null);
  const [orderBy, setOrderBy] = useState("dateReverse");
  const [showHidden, setShowHidden] = useState(true);
  // eslint-disable-next-line
  const [controller, dispatch] = useMaterialUIController();
  const { client } = controller;
  const navigate = useNavigate();
  const { t } = useTranslation();

  function setRequest(res) {
    if (res.results && res.results.length > 0) {
      setOriginalAttacks(res.results);
      setAttacks(res.results.sort((a, b) => a.date && b.date && b.date.localeCompare(a.date)));
      setIsRequestReady(true);
    }
    if (res && res.length > 0) {
      setOriginalAttacks(res);
      setAttacks(res.sort((a, b) => a.date && b.date && b.date.localeCompare(a.date)));
      setIsRequestReady(true);
    } else if (res.length === 0 || (res.results && res.results.length === 0)) {
      setOriginalAttacks([]);
      setAttacks([]);
      setIsRequestReady(true);
    }
  }

  function refreshAttacks() {
    setIsRequestReady(false);
    if (!client || client === "*") {
      getStealersToHandle(
        page,
        limit,
        nation,
        domain,
        reason,
        hash,
        showHidden,
        dateFrom,
        dateTo,
        orderBy
      )
        .then((res) => {
          if (res.totalPages) {
            setPages(res.totalPages);
          }
          setRequest(res);
        })
        // eslint-disable-next-line
      .catch((err) => console.log("error", err));
    } else if (client && client.domains && client.domains.length > 0) {
      getStealersToHandleBySites(
        client.domains,
        page,
        limit,
        nation,
        domain,
        reason,
        hash,
        "",
        dateFrom,
        dateTo,
        orderBy
      )
        .then((res) => {
          if (res.totalPages) {
            setPages(res.totalPages);
          }
          setRequest(res);
        })
        // eslint-disable-next-line
        .catch((err) => console.log("error", err));
    }
  }

  useEffect(() => {
    refreshAttacks();
  }, [client]);

  useEffect(() => {
    refreshAttacks();
  }, [limit, page, nation, domain, reason, showHidden, dateFrom, dateTo, orderBy, hash]);

  useEffect(() => {
    setFieldsShown(["MachineId", "SistemaOperativo"]);
  }, []);

  function getDomainShown(cookie, passwords) {
    const domains = [];
    if (cookie && cookie.length > 0) {
      cookie.forEach((ck) => {
        if (ck.domain) {
          domains.push(ck.domain);
        }
      });
    }
    if (passwords && passwords.length > 0) {
      passwords.forEach((pw) => {
        if (pw.URL) {
          domains.push(pw.URL);
        }
      });
    }
    let output = "";
    if (domains.length > 0) {
      if (client && client.domains && client.domains.length > 0) {
        const indexTofind = domains.findIndex((el) => client.domains.some((inner) => inner === el));
        if (indexTofind >= 0) {
          output =
            domains[indexTofind].length > 30
              ? `${domains[indexTofind].slice(0, 27)}...`
              : domains[indexTofind];
        } else {
          output = "";
        }
      } else {
        const outputArray = [].concat(domains[0]);
        // eslint-disable-next-line
        output = outputArray[0];
        if (domains.length > 1) {
          output += ` + ${domains.length - 1}`;
        }
      }
    } else {
      output = t("stealers.no_customer_associated");
    }
    return output;
  }

  function getTags(tags) {
    let tagsDisplayed = "";
    tags.forEach((tag, i) => {
      if (i > 0) {
        tagsDisplayed += `, ${tag}`;
      } else {
        tagsDisplayed += tag;
      }
    });
    return tagsDisplayed;
  }

  function chooseFieldsShown() {
    const choiceArray = [
      { value: "Nazione", label: t("stealers.nation") },
      { value: "Dominio", label: t("stealers.domain") },
      { value: "Motivo", label: t("stealers.reason") },
      { value: "Completo", label: t("stealers.complete") },
      { value: "Tags", label: t("stealers.tags") },
      { value: "MachineId", label: t("stealers.machine_id") },
      { value: "Hash", label: t("stealers.hash") },
      { value: "SistemaOperativo", label: t("stealers.so") },
    ];
    return choiceArray.map((el, index) => (
      <div
        className="choice-view-tabel-option input-checkbox"
        key={`choice-view-table-${el}${index.toString()}`}
        style={index === 0 ? { paddingLeft: "0" } : {}}
      >
        <label htmlFor={el.value}>
          <input
            type="checkbox"
            id={el.value}
            checked={fieldsShown.includes(el.value)}
            onChange={() => {
              const newFieldsShown = [].concat(fieldsShown);
              if (fieldsShown.includes(el.value)) {
                const indexToDelete = fieldsShown.indexOf(el.value);
                if (indexToDelete >= 0) {
                  newFieldsShown.splice(indexToDelete, 1);
                }
                // lo tolgo
              } else {
                newFieldsShown.push(el.value);
              }
              setFieldsShown(newFieldsShown);
            }}
          />
          <span className="checkmark" />
          {el.label}
        </label>
      </div>
    ));
  }

  function changeShowHidden() {
    return (
      <div className="choice-view-tabel-option input-checkbox" style={{ paddingLeft: "0" }}>
        <label htmlFor="addHidden">
          <input
            type="checkbox"
            id="addHidden"
            checked={showHidden}
            onChange={() => {
              setShowHidden(!showHidden);
            }}
          />
          <span className="checkmark" />
          {t("stealers.add_hidden_results")}
        </label>
      </div>
    );
  }

  function getCalendar(value, changeFunction, label) {
    return (
      <div style={{ position: "relative" }}>
        <Datetime
          inputProps={{
            placeholder: label,
            value,
          }}
          closeOnSelect
          timeFormat={false}
          value={value}
          onChange={(e) => {
            changeFunction(moment(e).format("L"));
          }}
        />
        {value ? (
          <IconButton
            onClick={() => {
              changeFunction("");
            }}
            style={{ position: "absolute", right: "0", top: "6px" }}
            size="small"
            disableRipple
          >
            <Icon fontSize="medium">close</Icon>
          </IconButton>
        ) : null}
      </div>
    );
  }

  const selectAutocomplete = (element) => {
    setNation(element);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div style={{ margin: "30px 0", width: "100%", textAlign: "right" }}>
        <IconButton
          onClick={() => {
            refreshAttacks();
          }}
          size="small"
          disableRipple
        >
          {t("refresh")} <Icon fontSize="medium">replay</Icon>
        </IconButton>
      </div>
      <div>
        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "30px" }}>
          <div className="tabs-view-container">
            <button
              className="emply-label-button"
              onClick={() => {
                navigate("/dashboards/stealers");
              }}
              type="button"
            >
              {t("stealers.all").toLowerCase()}
            </button>
            <button
              className="emply-label-button"
              onClick={() => {
                navigate("/dashboards/stealers?completi");
              }}
              type="button"
            >
              {t("stealers.completes").toLowerCase()}
            </button>
            <button
              className="emply-label-button"
              onClick={() => {
                navigate("/dashboards/stealers?incompleti");
              }}
              type="button"
            >
              {t("stealers.incompletes").toLowerCase()}
            </button>
            <button
              className="emply-label-button tab-button-selected"
              onClick={() => {
                // navigate("/stealersToHandle");
              }}
              type="button"
            >
              {t("stealers.to_manage").toLowerCase()}
            </button>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "30px" }}>
          <div style={{ display: "flex" }}>
            <Autocomplete
              label={t("stealers.nation")}
              onSelect={selectAutocomplete}
              list={Object.keys(countryCode).map((el) => ({
                label: t(`countryCode.${el}`),
                value: el,
              }))}
            />
            <MDInput
              type="input"
              label={t("stealers.reason")}
              value={reason}
              fullWidth
              onChange={(e) => {
                setReason(e.target.value);
              }}
            />
            <MDInput
              type="input"
              label={t("stealers.hash")}
              value={hash}
              fullWidth
              onChange={(e) => {
                setHash(e.target.value);
              }}
            />
            {getCalendar(dateFrom, setDateFrom, t("stealers.date_from"))}
            {getCalendar(dateTo, setDateTo, t("stealers.date_to"))}
          </div>
          <div style={{ display: "flex" }}>
            <div style={{ lineHeight: "40px", marginRight: "15px" }}>
              {t("stealers.elements_displayed")}
            </div>
            <select
              onChange={(e) => {
                setLimit(e.target.value);
              }}
              value={limit}
              className="selectDefault"
              style={{
                padding: "0.75rem",
                borderRadius: "0.375rem",
                borderColor: "#d2d6da",
                marginBottom: "30px",
              }}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </div>
        </div>
      </div>
      <div style={{ display: "flex" }}>{chooseFieldsShown()}</div>
      <div>{changeShowHidden()}</div>
      <div className="popupInfoContainer">
        {showDomains ? (
          <SweetAlert
            style={{ display: "block", position: "relative", textAlign: "left" }}
            title=""
            onConfirm={() => {
              setShowDomains(false);
              setDomainsToShow(null);
            }}
            confirmBtnText={t("close")}
            confirmBtnCssClass="popupCloseButton"
            confirmBtnStyle={{
              boxShadow: "none",
            }}
          >
            {domainsToShow && domainsToShow.length > 0 ? (
              <div className="detail-domains-to-show">
                {domainsToShow.map((dom, ind) => (
                  <div
                    className={`detail-domain-single ${ind % 2 === 0 ? "" : "even"}`}
                    key={`dom-to-show-${ind.toString()}`}
                  >
                    {dom}
                  </div>
                ))}
              </div>
            ) : null}
            <div className="popupButtonEdit">
              <Tooltip title={t("close")} placement="top">
                <IconButton
                  onClick={() => {
                    setShowDomains(false);
                    setDomainsToShow(null);
                  }}
                  size="small"
                  disableRipple
                >
                  <Icon fontSize="medium">close</Icon>
                </IconButton>
              </Tooltip>
            </div>
          </SweetAlert>
        ) : null}
        {elementToDuplicate ? (
          <SweetAlert
            style={{ display: "block", position: "relative" }}
            title={t("stealers.duplicate")}
            onConfirm={() => {
              editStealer({ managed: true }, elementToDuplicate)
                .then(() => {
                  refreshAttacks();
                  setElementToDuplicate("");
                })
                // eslint-disable-next-line
                  .catch((err) => console.log("error", err));
            }}
            onCancel={() => {
              editStealer({ managed: true, mayBeDuplicate: false }, elementToDuplicate)
                .then(() => {
                  refreshAttacks();
                  setElementToDuplicate("");
                })
                // eslint-disable-next-line
                  .catch((err) => console.log("error", err));
            }}
            closeOnClickOutside
            showCancel
            cancelBtnText={t("no")}
            confirmBtnText={t("yes")}
            confirmBtnCssClass="popupCloseButton"
            confirmBtnStyle={{
              boxShadow: "none",
            }}
            cancelBtnCssClass="popupCloseButton"
            cancelBtnStyle={{
              boxShadow: "none",
            }}
          >
            {t("stealers.is_really_duplicate")}
            <div className="popupButtonEdit">
              <Tooltip title={t("close")} placement="top">
                <IconButton
                  onClick={() => {
                    setElementToDuplicate("");
                  }}
                  size="small"
                  disableRipple
                >
                  <Icon fontSize="medium">close</Icon>
                </IconButton>
              </Tooltip>
            </div>
          </SweetAlert>
        ) : null}
        <div>
          <div className="table_users_list">
            <table style={{ border: "solid 1px #dedede", width: "100%" }}>
              <thead>
                <tr>
                  <th
                    style={{
                      padding: "10px 0",
                      borderBottom: "solid 1px #dedede",
                      width: "10%",
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (orderBy === "dateReverse") {
                        setOrderBy("date");
                      } else {
                        setOrderBy("dateReverse");
                      }
                    }}
                  >
                    {t("stealers.date")}
                    {orderBy === "date" ? <Icon fontSize="medium">south</Icon> : null}
                    {orderBy === "dateReverse" ? <Icon fontSize="medium">north</Icon> : null}
                  </th>
                  {fieldsShown.includes("Nazione") ? (
                    <th
                      style={{
                        padding: "10px 0",
                        borderBottom: "solid 1px #dedede",
                        width: "10%",
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        if (orderBy === "nation") {
                          setOrderBy("nationReverse");
                        } else {
                          setOrderBy("nation");
                        }
                      }}
                    >
                      {t("stealers.nation")}
                      {orderBy === "nation" ? <Icon fontSize="medium">south</Icon> : null}
                      {orderBy === "nationReverse" ? <Icon fontSize="medium">north</Icon> : null}
                    </th>
                  ) : null}
                  {fieldsShown.includes("Dominio") ? (
                    <th
                      style={{
                        padding: "10px 0",
                        borderBottom: "solid 1px #dedede",
                        width: "20%",
                        textAlign: "center",
                      }}
                    >
                      {t("stealers.domain")}
                    </th>
                  ) : null}
                  {fieldsShown.includes("Tags") ? (
                    <th
                      style={{
                        padding: "10px 0",
                        borderBottom: "solid 1px #dedede",
                        width: "10%",
                        textAlign: "center",
                      }}
                    >
                      {t("stealers.tags")}
                    </th>
                  ) : null}
                  {fieldsShown.includes("MachineId") ? (
                    <th
                      style={{
                        padding: "10px 0",
                        borderBottom: "solid 1px #dedede",
                        width: "20%",
                        textAlign: "center",
                      }}
                    >
                      {t("stealers.machine_id")}
                    </th>
                  ) : null}
                  {fieldsShown.includes("SistemaOperativo") ? (
                    <th
                      style={{
                        padding: "10px 0",
                        borderBottom: "solid 1px #dedede",
                        width: "20%",
                        textAlign: "center",
                      }}
                    >
                      {t("stealers.so")}
                    </th>
                  ) : null}
                  {fieldsShown.includes("Hash") ? (
                    <th
                      style={{
                        padding: "10px 0",
                        borderBottom: "solid 1px #dedede",
                        width: "10%",
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        if (orderBy === "hash") {
                          setOrderBy("hashReverse");
                        } else {
                          setOrderBy("hash");
                        }
                      }}
                    >
                      {t("stealers.hash")}
                      {orderBy === "hash" ? <Icon fontSize="medium">south</Icon> : null}
                      {orderBy === "hashReverse" ? <Icon fontSize="medium">north</Icon> : null}
                    </th>
                  ) : null}
                  {fieldsShown.includes("Motivo") ? (
                    <th
                      style={{
                        padding: "10px 0",
                        borderBottom: "solid 1px #dedede",
                        width: "10%",
                        textAlign: "center",
                      }}
                    >
                      {t("stealers.reason")}
                    </th>
                  ) : null}
                  {fieldsShown.includes("Completo") ? (
                    <th
                      style={{
                        padding: "10px 0",
                        borderBottom: "solid 1px #dedede",
                        width: "10%",
                        textAlign: "center",
                      }}
                    >
                      {t("stealers.complete")}
                    </th>
                  ) : null}
                  <th
                    style={{
                      padding: "10px 0",
                      borderBottom: "solid 1px #dedede",
                      width: "10%",
                      textAlign: "center",
                    }}
                  >
                    {t("stealers.info")}
                  </th>
                </tr>
              </thead>
              <tbody style={{ fontSize: "14px", textAlign: "center" }}>
                {isRequestReady ? (
                  <>
                    {originalAttacks && originalAttacks.length > 0 ? (
                      <>
                        {attacks.map((el, index) => {
                          let reasonToShow = "";
                          if (el.reason) {
                            reasonToShow = el.reason;
                          }
                          return (
                            <tr
                              style={{
                                borderBottom: "solid 1px #dedede",
                                backgroundColor: index % 2 === 0 ? "#fff" : "#eeeeee",
                              }}
                              key={`stealer-${index.toString()}`}
                            >
                              <td style={{ borderBottom: "solid 1px #dedede", padding: "10px 0" }}>
                                {el.date
                                  ? `${moment(el.date).format("DD")}/${moment(el.date).format(
                                      "MM"
                                    )}/${moment(el.date).format("YYYY")}`
                                  : ""}
                              </td>
                              {fieldsShown.includes("Nazione") ? (
                                <td
                                  style={{ borderBottom: "solid 1px #dedede", padding: "10px 0" }}
                                >
                                  {el.countryCode ? t(`countryCode.${el.countryCode}`) : ""}
                                </td>
                              ) : null}
                              {fieldsShown.includes("Dominio") ? (
                                <td
                                  style={{ borderBottom: "solid 1px #dedede", padding: "10px 0" }}
                                >
                                  {(el.cookies && el.cookies.length > 0) ||
                                  (el.passwords && el.passwords.length > 0) ? (
                                    <button
                                      type="button"
                                      className="emply-label-button"
                                      onClick={() => {
                                        const domains = [];
                                        if (el.cookies && el.cookies.length > 0) {
                                          el.cookies.forEach((ck) => {
                                            if (ck.domain) {
                                              domains.push(ck.domain);
                                            }
                                          });
                                        }
                                        if (el.passwords && el.passwords.length > 0) {
                                          el.passwords.forEach((pw) => {
                                            if (pw.URL) {
                                              domains.push(pw.URL);
                                            }
                                          });
                                        }
                                        if (domains && domains.length > 0) {
                                          setShowDomains(true);
                                          setDomainsToShow(domains);
                                        }
                                      }}
                                    >
                                      {getDomainShown(el.cookies, el.passwords)}
                                    </button>
                                  ) : (
                                    t("stealers.no_customer_associated")
                                  )}
                                </td>
                              ) : null}
                              {fieldsShown.includes("Tags") ? (
                                <td
                                  style={{ borderBottom: "solid 1px #dedede", padding: "10px 0" }}
                                >
                                  {el.tags && el.tags.length > 0 ? getTags(el.tags) : ""}
                                </td>
                              ) : null}
                              {fieldsShown.includes("MachineId") ? (
                                <td
                                  style={{ borderBottom: "solid 1px #dedede", padding: "10px 0" }}
                                >
                                  {el.machineID ? el.machineID : ""}
                                </td>
                              ) : null}
                              {fieldsShown.includes("SistemaOperativo") ? (
                                <td
                                  style={{ borderBottom: "solid 1px #dedede", padding: "10px 0" }}
                                >
                                  {el.OS ? el.OS : ""}
                                </td>
                              ) : null}
                              {fieldsShown.includes("Hash") ? (
                                <td
                                  style={{ borderBottom: "solid 1px #dedede", padding: "10px 0" }}
                                >
                                  {el.filelistHash ? el.filelistHash : ""}
                                </td>
                              ) : null}
                              {fieldsShown.includes("Motivo") ? (
                                <td
                                  style={{ borderBottom: "solid 1px #dedede", padding: "10px 0" }}
                                >
                                  {reasonToShow}
                                </td>
                              ) : null}
                              {fieldsShown.includes("Completo") ? (
                                <td
                                  style={{ borderBottom: "solid 1px #dedede", padding: "10px 0" }}
                                >
                                  {el.tags && el.tags.length > 0 && el.tags.includes("complete") ? (
                                    <Icon fontSize="medium">check</Icon>
                                  ) : null}
                                  {el.tags &&
                                  el.tags.length > 0 &&
                                  el.tags.includes("incomplete") ? (
                                    <Icon fontSize="medium">close</Icon>
                                  ) : null}
                                </td>
                              ) : null}
                              <td style={{ borderBottom: "solid 1px #dedede", padding: "10px 0" }}>
                                <IconButton
                                  onClick={() => {
                                    window.open(`/detailsStealer?${el.id}`, "_blank").focus();
                                  }}
                                  size="small"
                                  disableRipple
                                >
                                  <Icon fontSize="medium">info</Icon>
                                </IconButton>
                                <Tooltip title="Duplicato" placement="top">
                                  <IconButton
                                    onClick={() => {
                                      setElementToDuplicate(el.id);
                                    }}
                                    size="small"
                                    disableRipple
                                  >
                                    <Icon fontSize="medium">settings</Icon>
                                  </IconButton>
                                </Tooltip>
                                <IconButton
                                  onClick={() => {
                                    editStealer({ isHidden: !el.isHidden }, el.id)
                                      .then(() => {
                                        refreshAttacks();
                                      })
                                      // eslint-disable-next-line
                                    .catch((err) => console.log("error", err));
                                  }}
                                  size="small"
                                  disableRipple
                                >
                                  {el.isHidden ? (
                                    <VisibilityOffIcon fontSize="medium" />
                                  ) : (
                                    <Icon fontSize="medium">visibility</Icon>
                                  )}
                                </IconButton>
                              </td>
                            </tr>
                          );
                        })}
                      </>
                    ) : (
                      <tr>
                        <td />
                        <td />
                        <td style={{ width: "100%", display: "block", padding: "10px 0" }}>
                          {t("stealers.no_stealer_yet")}
                        </td>
                        <td />
                        <td />
                        <td />
                      </tr>
                    )}
                  </>
                ) : (
                  <tr>
                    <td />
                    <td />
                    <td
                      style={{
                        width: "100%",
                        display: "block",
                        padding: "10px 0",
                        position: "relative",
                      }}
                    >
                      <div className="loader" />
                    </td>
                    <td />
                    <td />
                    <td />
                  </tr>
                )}
              </tbody>
            </table>
            {pages && pages > 1 ? (
              <Pagination setPage={setPage} pages={pages} active={page} />
            ) : null}
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default StealersToHandle;
