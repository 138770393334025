/**
=========================================================
* Material Dashboard 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
// import Switch from "@mui/material/Switch";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { Link } from "react-router-dom";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
// import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import logo from "assets/images/logo-light.png";

import login from "api/auth/login";
import addLog from "api/logs/addLog";
import MDTypography from "components/MDTypography";

function Basic() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);

  const performLogin = () => {
    // console.log("email ", email);
    // console.log("password ", password);
    const dataToSend = {
      email,
      password,
    };
    // console.log("passo da qui", dataToSend);
    return login(dataToSend)
      .then((resp) => {
        // eslint-disable-next-line
        console.log("login success", resp);
        setError(false);
        const logToSend = {
          email,
          type: "Login Success",
          date: new Date(),
        };
        addLog(logToSend).then(() => {
          window.location.href = "/mfa";
        });
      })
      .catch((err) => {
        // eslint-disable-next-line
        console.log("error login", err);
        setError(true);
        const logToSend = {
          email,
          type: "Login Failed",
          date: new Date(),
        };
        addLog(logToSend);
      });
  };

  // console.log("error?", error);

  // const handleSetRememberMe = () => setRememberMe(!rememberMe);
  // TODO gestire errori

  return (
    <BasicLayout>
      <Card>
        <img src={logo} className="auth-defenda-logo" alt="defenda-logo" />
        <MDBox pt={5} pb={3} px={3}>
          <MDBox component="form" role="form" sx={{ textAlign: "center" }}>
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                autoFocus
                fullWidth
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    performLogin();
                  }
                }}
                onChange={(e) => {
                  setEmail(e.target.value);
                  // console.log("targetttttt", e.target.value);
                }}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                fullWidth
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    performLogin();
                  }
                }}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </MDBox>
            {/* <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox> */}
            <Link to="/forgotPassword">
              <MDTypography
                component="span"
                variant="body2"
                color="dark"
                opacity={0.5}
                sx={{ lineHeight: 0, fontSize: "13px" }}
              >
                Forgot password?
              </MDTypography>
            </Link>
            <MDBox mt={4} mb={1}>
              <MDButton
                style={{ background: "#669FF6", color: "white" }}
                fullWidth
                onClick={() => {
                  performLogin();
                }}
              >
                sign in
              </MDButton>
            </MDBox>
            {error ? (
              <MDBox mt={4} mb={1}>
                <MDTypography variant="h6" fontSize="13px" color="error">
                  Ops, qualcosa è andato storto!
                </MDTypography>
                <MDTypography fontSize="11px" color="error">
                  Controlla i dati immessi o riprova fra qualche minuto
                </MDTypography>
              </MDBox>
            ) : null}
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
