import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMaterialUIController } from "context";
import getNotificationsByCustomers from "api/notification/getNotificationsByCustomers";
import Pagination from "layouts/dashboards/stealers/pagination";

// Material Dashboard 2 PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// @mui material components
import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import moment from "moment";

// @mui material Icons
// import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";

function AlertsPage() {
  // eslint-disable-next-line
  const [controller, dispatch] = useMaterialUIController();
  const { user, customers } = controller;
  const [notifications, setNotifications] = useState([]);
  // eslint-disable-next-line
  const [pages, setPages] = useState(0);
  // eslint-disable-next-line
  const [page, setPage] = useState(1);
  // eslint-disable-next-line
  const [limit, setLimit] = useState(50);
  const { t } = useTranslation();

  function refreshNotifications() {
    if (user && customers && customers.length > 0) {
      getNotificationsByCustomers(page, limit)
        .then((res) => {
          if (res.totalPages) {
            setPages(res.totalPages);
          }
          if (res && res.results && res.results.length > 0) {
            setNotifications(res.results);
          }
        })
        .catch((err) => {
          // eslint-disable-next-line
          console.log("error while getting notifications", err);
        });
      // setNotifications(
      //   [
      //     {
      //       customer: "greenenergybank.it",
      //       date: "2022-11-22T11:15:52.181Z",
      //       id: "637caf68c325b3474639807e",
      //       stealer: "637caf3724d3ce0652f28990",
      //       type: "stealer_cookie_found",
      //     },
      //     {
      //       customer: "greenenergybank.it",
      //       date: "2022-11-22T11:15:52.181Z",
      //       id: "637caf68c325b3474639807d",
      //       stealer: "637caf3724d3ce0652f28990",
      //       type: "stealer_cookie_found",
      //     },
      //     {
      //       customer: "greenenergybank.it",
      //       date: "2022-11-22T11:15:52.178Z",
      //       id: "637caf68c325b3474639807b",
      //       stealer: "637caf3724d3ce0652f28990",
      //       type: "stealer_password_found",
      //     },
      //   ]
      // );
    }
  }

  useEffect(() => {
    // console.log("customers", customers);
    refreshNotifications();
  }, [user, customers, page, limit]);

  function checkNumber(type) {
    const firstChar = type.charAt(0);
    if (Number(firstChar)) {
      const arr = type.split("-");
      return arr[1];
    }
    return type;
  }

  function getTitle(type) {
    return t(`analytics.title_${checkNumber(type)}`);
  }

  function getText(type) {
    if (type.includes("-")) {
      return `${type.split("-")[0]} ${t(`analytics.text_${type.split("-")[1]}`)}`;
    }
    return t(`analytics.text_${checkNumber(type)}`);
  }

  function getTime(date) {
    return `${moment(date).format("DD")}/${moment(date).format("MM")}/${moment(date).format(
      "YY"
    )} - ${moment(date).format("HH")}:${moment(date).format("mm")}`;
  }

  function getColor(type) {
    const str = type.replace(/[0-9]/g, "");
    if (str === "-credentials_found") {
      return "#CF1A1A";
    }
    return "#0BC0A0";
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <h2>Alerts</h2>
      <MDBox className="boxes-border-radius" padding={2.3} sx={{ background: "white" }}>
        {notifications.length > 0 ? (
          notifications.map((item) => (
            <div key={`notification-${item.id}`}>
              <MDBox mt={2}>
                <MDTypography
                  mb={1}
                  fontSize="large"
                  fontWeight="bold"
                  sx={{ color: getColor(item.type) }}
                >
                  {getTitle(item.type)}
                </MDTypography>
                <MDTypography mb={1} fontSize="medium">
                  {getText(item.type)}
                </MDTypography>
                <MDBox display="flex" alignItems="center" mb={1}>
                  <PersonOutlineIcon fontSize="small" />
                  <MDTypography ml={1} fontSize="medium" fontWeight="bold">
                    {item.client.name}
                  </MDTypography>
                </MDBox>
                <MDBox display="flex" alignItems="center">
                  <CalendarTodayIcon />
                  <MDTypography
                    variant="button"
                    color="dark"
                    fontSize="small"
                    fontWeight="light"
                    ml={1}
                  >
                    {getTime(item.date)}
                  </MDTypography>
                </MDBox>
              </MDBox>
              <Divider sx={{ border: "1px solid lightgrey", marginTop: "17px" }} />
            </div>
          ))
        ) : (
          <h5>{t("analytics.no_alerts")}</h5>
        )}
      </MDBox>
      {pages && pages > 1 ? <Pagination setPage={setPage} pages={pages} active={page} /> : null}
    </DashboardLayout>
  );
}

export default AlertsPage;
