// @mui material components
import { useState } from "react";
import { useTranslation } from "react-i18next";
import createUser from "api/users/createUser";
import addLog from "api/logs/addLog";
import MDInput from "components/MDInput";
import Grid from "@mui/material/Grid";
import SweetAlert from "react-bootstrap-sweetalert";

// Material Dashboard 2 PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import { useMaterialUIController } from "context";
import { useNavigate } from "react-router-dom";

function AddUser() {
  // eslint-disable-next-line
  const [controller, dispatch] = useMaterialUIController();
  const { user } = controller;
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("user");
  const [errorMessage, setErrorMessage] = useState("");
  const [popup, setPopup] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const navigate = useNavigate();
  const { t } = useTranslation();

  function getInput(type, editFunction, defaultValue) {
    if (type === "password" || type === "Password") {
      return (
        <div style={{ position: "relative" }}>
          <MDInput
            type={!showPassword ? "password" : "input"}
            label={type}
            fullWidth
            defaultValue={defaultValue}
            onChange={(e) => {
              editFunction(e.target.value);
            }}
            style={{ marginBottom: "30px" }}
          />
          <div className="clientInputArrayButton">
            <IconButton
              onClick={() => {
                setShowPassword(!showPassword);
              }}
              size="small"
              disableRipple
            >
              {showPassword ? (
                <Icon fontSize="medium">visibility</Icon>
              ) : (
                <Icon fontSize="medium">lock</Icon>
              )}
            </IconButton>
          </div>
        </div>
      );
    }
    return (
      <MDInput
        type="input"
        label={type}
        fullWidth
        defaultValue={defaultValue}
        onChange={(e) => {
          editFunction(e.target.value);
        }}
        style={{ marginBottom: "30px" }}
      />
    );
  }

  function getSelect(options, editFunction, defaultValue) {
    return (
      <select
        onChange={(e) => {
          editFunction(e.target.value);
        }}
        defaultValue={defaultValue}
        className="selectDefault"
        style={{
          width: "100%",
          padding: "0.75rem",
          borderRadius: "0.375rem",
          borderColor: "#d2d6da",
          marginBottom: "30px",
        }}
      >
        {options.map((el, index) => (
          <option value={el} key={`option-role-${index.toString()}`}>
            {el}
          </option>
        ))}
      </select>
    );
  }

  const validateEmail = (em) =>
    String(em)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );

  return (
    <DashboardLayout>
      {popup ? (
        <SweetAlert
          style={{ display: "block" }}
          title={t("admin.user_created")}
          success
          onConfirm={() => {
            setPopup(false);
            navigate("/userlist");
          }}
          confirmBtnCssClass="popupCloseButton"
          confirmBtnStyle={{
            boxShadow: "none",
          }}
        >
          {t("admin.user_created_text")}
        </SweetAlert>
      ) : null}
      <DashboardNavbar />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Card>
            <MDBox variant="gradient">
              <MDBox p={5}>
                <MDBox display="flex" justifyContent="space-between">
                  <MDBox>
                    {getInput(t("admin.name"), setName, name)}
                    {getInput(t("admin.email"), setEmail, email)}
                    {getInput(t("admin.password"), setPassword, password)}
                    {getSelect(["user", "admin", "operator"], setRole, role)}
                  </MDBox>
                </MDBox>
              </MDBox>
            </MDBox>
            <MDBox px={5} pb={5}>
              <IconButton
                onClick={() => {
                  if (email && validateEmail(email)) {
                    setIsEmailValid(true);
                    if (name && email && password) {
                      const userToCreate = {
                        name,
                        email,
                        password,
                        role,
                      };
                      createUser(userToCreate)
                        .then((res) => {
                          if (res) {
                            const logToSend = {
                              email: user && user.email ? user.email : "",
                              type: `Created new user ${email}`,
                              date: new Date(),
                            };
                            addLog(logToSend);
                            setName("");
                            setEmail("");
                            setPassword("");
                            setErrorMessage("");
                            setPopup(true);
                          } else {
                            setErrorMessage(t("admin.error_create_user_text"));
                          }
                        })
                        .catch((err) => {
                          // eslint-disable-next-line
                          console.log("errore creazione utente", err);
                          setErrorMessage(t("admin.error_create_user_text"));
                        });
                    }
                  } else if (email && !validateEmail(email)) {
                    setIsEmailValid(false);
                  }
                }}
                size="small"
                disableRipple
              >
                {t("admin.add")}
              </IconButton>
            </MDBox>
            {errorMessage ? (
              <MDBox px={5} pb={5}>
                <MDTypography fontSize="medium" color="error">
                  {errorMessage}
                </MDTypography>
              </MDBox>
            ) : null}
            {email && !isEmailValid ? (
              <MDBox px={5} pb={5}>
                <MDTypography fontSize="medium" color="error">
                  {t("admin.invalid_email")}
                </MDTypography>
              </MDBox>
            ) : null}
          </Card>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default AddUser;
