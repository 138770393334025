// @mui material components
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import getStealers from "api/stealer/getAllStealers";
import editStealer from "api/stealer/editStealer";
import getCompleteStealers from "api/stealer/getAllCompleteStealers";
import getIncompleteStealers from "api/stealer/getAllIncompleteStealers";
// import getStealersBySites from "api/stealer/getStealersBySites";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import moment from "moment";
import Tooltip from "@mui/material/Tooltip";
import MDInput from "components/MDInput";
import SweetAlert from "react-bootstrap-sweetalert";
import Datetime from "react-datetime";
import { useMaterialUIController } from "context";
import { useNavigate } from "react-router-dom";

// Material Dashboard 2 PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Pagination from "layouts/dashboards/stealers/pagination";
import Autocomplete from "layouts/dashboards/stealers/autocomplete";
// import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import countryCode from "config/countryCode";
import CsvDownloader from "react-csv-downloader";

import StealersStatistics from "layouts/dashboards/analytics/components/stealersStatistics";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

function Stealers() {
  const [attacks, setAttacks] = useState([]);
  // eslint-disable-next-line
  const [pages, setPages] = useState(0);
  // eslint-disable-next-line
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const urlNow = window.location.href;
  let stealerFromUrl = "all";
  if (urlNow && urlNow.endsWith("incompleti")) {
    stealerFromUrl = "incomplete";
  } else if (urlNow && urlNow.endsWith("completi")) {
    stealerFromUrl = "complete";
  }
  const [stealersType, setStealersType] = useState(stealerFromUrl);
  const [nation, setNation] = useState("");
  // eslint-disable-next-line
  const [domain, setDomain] = useState("");
  const [reason, setReason] = useState("");
  const [showFilters, setShowFilters] = useState(false);
  const [hash, setHash] = useState("");
  const [originalItemId, setOriginalItemId] = useState("");
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  // eslint-disable-next-line
  const [fieldsShown, setFieldsShown] = useState([
    "Nazione",
    "Dominio",
    "Motivo",
    "Completo",
  ]);
  const [showDomains, setShowDomains] = useState(false);
  const [domainsToShow, setDomainsToShow] = useState(null);
  const [isRequestReady, setIsRequestReady] = useState(false);
  // eslint-disable-next-line
  const [cookieToView, setCookieToView] = useState(null);
  const [orderBy, setOrderBy] = useState("dateReverse");
  const [showHidden, setShowHidden] = useState(false);
  // eslint-disable-next-line
  const [controller, dispatch] = useMaterialUIController();
  const { /* allStatistics, */ client } = controller;
  const navigate = useNavigate();
  const { t } = useTranslation();

  function setRequest(res) {
    if (res.results && res.results.length > 0) {
      setAttacks(res.results);
      setIsRequestReady(true);
    }
    if (res && res.length > 0) {
      setAttacks(res);
      setIsRequestReady(true);
    } else if ((res && res.length === 0) || (res.results && res.results.length === 0)) {
      setAttacks([]);
      setIsRequestReady(true);
    }
  }

  function refreshAttacks() {
    setIsRequestReady(false);
    let functionToUse = null;
    switch (stealersType) {
      case "all":
        functionToUse = getStealers;
        break;
      case "complete":
        functionToUse = getCompleteStealers;
        break;
      case "incomplete":
        functionToUse = getIncompleteStealers;
        break;
      default:
        functionToUse = getStealers;
    }
    functionToUse(
      page,
      limit,
      nation,
      domain,
      reason,
      hash,
      originalItemId,
      showHidden,
      dateFrom,
      dateTo,
      orderBy
    )
      .then((res) => {
        // console.log(res);
        if (res.totalPages) {
          setPages(res.totalPages);
        }
        setRequest(res);
      })
      // eslint-disable-next-line
    .catch((err) => console.log("error", err));
  }

  useEffect(() => {
    refreshAttacks();
  }, [
    limit,
    page,
    stealersType,
    nation,
    domain,
    reason,
    hash,
    originalItemId,
    showHidden,
    dateFrom,
    dateTo,
    orderBy,
  ]);

  function getDomainShown(domains) {
    const arr = [];
    if (domains[0] && domains[0].length > 0) {
      domains[0].forEach((item) => arr.push(item));
    }

    if (arr.length > 1) {
      return `${arr[0]} + ${arr.length - 1}`;
    }

    return arr[0];

    // let output = "";
    // if (domains.length > 0) {
    //   if (client && client.domains && client.domains.length > 0) {
    //     const indexTofind = domains.findIndex((el) =>
    //       client.domains.passwords.some((inner) => inner === el)
    //     );
    //     if (indexTofind >= 0) {
    //       output =
    //         domains[indexTofind].length > 30
    //           ? `${domains[indexTofind].slice(0, 27)}...`
    //           : domains[indexTofind];
    //     } else {
    //       output = "";
    //     }
    //   } else {
    //     const outputArray = [].concat(domains[0]);
    //     // eslint-disable-next-line
    //     output = outputArray[0];
    //     if (domains.length > 1) {
    //       output += ` + ${domains.length - 1}`;
    //     }
    //   }
    // } else {
    //   output = t("stealers.no_customer_associated");
    // }
    // return output;
  }

  function getTags(tags) {
    let tagsDisplayed = "";
    tags.forEach((tag, i) => {
      if (i > 0) {
        tagsDisplayed += `, ${tag}`;
      } else {
        tagsDisplayed += tag;
      }
    });
    return tagsDisplayed;
  }

  function chooseFieldsShown() {
    const choiceArray = [
      { value: "Nazione", label: t("stealers.nation") },
      { value: "Dominio", label: t("stealers.domain") },
      { value: "Motivo", label: t("stealers.reason") },
      { value: "Completo", label: t("stealers.complete") },
      { value: "Tags", label: t("stealers.tags") },
      { value: "MachineId", label: t("stealers.machine_id") },
      { value: "Hash", label: t("stealers.hash") },
      { value: "SistemaOperativo", label: t("stealers.so") },
    ];
    return choiceArray.map((el, index) => (
      <div
        className="choice-view-tabel-option input-checkbox"
        key={`choice-view-table-${el}${index.toString()}`}
        style={index === 0 ? { paddingLeft: "0" } : {}}
      >
        <label htmlFor={el.value}>
          <input
            type="checkbox"
            id={el.value}
            checked={fieldsShown.includes(el.value)}
            onChange={() => {
              const newFieldsShown = [].concat(fieldsShown);
              if (fieldsShown.includes(el.value)) {
                const indexToDelete = fieldsShown.indexOf(el.value);
                if (indexToDelete >= 0) {
                  newFieldsShown.splice(indexToDelete, 1);
                }
                // lo tolgo
              } else {
                newFieldsShown.push(el.value);
              }
              setFieldsShown(newFieldsShown);
            }}
          />
          <span className="checkmark" />
          {el.label}
        </label>
      </div>
    ));
  }

  function changeShowHidden() {
    return (
      <div className="choice-view-tabel-option input-checkbox" style={{ paddingLeft: "0" }}>
        <label htmlFor="addHidden">
          <input
            type="checkbox"
            id="addHidden"
            checked={showHidden}
            onChange={() => {
              setShowHidden(!showHidden);
            }}
          />
          <span className="checkmark" />
          {t("stealers.add_hidden_results")}
        </label>
      </div>
    );
  }

  function getCalendar(value, changeFunction, label) {
    return (
      <div style={{ position: "relative", marginRight: "10px" }}>
        <Datetime
          inputProps={{
            placeholder: label,
            value,
          }}
          closeOnSelect
          timeFormat={false}
          value={value}
          onChange={(e) => {
            changeFunction(moment(e).format("L"));
          }}
        />
        {value ? (
          <IconButton
            onClick={() => {
              changeFunction("");
            }}
            style={{ position: "absolute", right: "0", top: "6px" }}
            size="small"
            disableRipple
          >
            <Icon fontSize="medium">close</Icon>
          </IconButton>
        ) : null}
      </div>
    );
  }

  const selectAutocomplete = (element) => {
    setNation(element);
  };

  useEffect(() => {
    refreshAttacks();
    // refreshCountersClient();
  }, [client]);

  // let passwordsCountDisplayed = 0;
  // let cookiesCountDisplayed = 0;
  // if (
  //   (client && client.cookies && client.cookies.length > 0) ||
  //   (client && client.passwords && client.passwords.length > 0)
  // ) {
  //   passwordsCountDisplayed = passwordsCount;
  //   cookiesCountDisplayed = cookiesCount;
  // } else if (allStatistics && allStatistics.passwords_count && allStatistics.cookies_count) {
  //   passwordsCountDisplayed = allStatistics.passwords_count;
  //   cookiesCountDisplayed = allStatistics.cookies_count;
  // }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <h2 className="page-title">{t("stealers.stolen_credentials")}</h2>
      <MDBox py={3}>
        <MDBox>
          <Grid container spacing={3}>
            <StealersStatistics columns={3} />
          </Grid>
        </MDBox>
      </MDBox>
      <div style={{ margin: "30px 0", width: "100%", textAlign: "left" }}>
        <IconButton
          onClick={() => {
            setShowFilters(!showFilters);
          }}
          style={{ marginRight: "15px" }}
          className="default-button"
          size="small"
          disableRipple
        >
          {t("filter")}{" "}
          {showFilters ? (
            <ArrowDropUpIcon style={{ marginLeft: "10px" }} fontSize="medium" />
          ) : (
            <ArrowDropDownIcon style={{ marginLeft: "10px" }} fontSize="medium" />
          )}
        </IconButton>
        <IconButton
          onClick={() => {
            refreshAttacks();
          }}
          className="default-button"
          style={{ marginRight: "15px" }}
          size="small"
          disableRipple
        >
          {t("refresh")}{" "}
          <Icon style={{ marginLeft: "10px" }} fontSize="medium">
            replay
          </Icon>
        </IconButton>
        {attacks && attacks.length ? (
          <div className="csv-container-downloader">
            <CsvDownloader
              datas={attacks.map((att) =>
                // TODO fare una chiamata che prenda tutti gli elementi con i filtri,
                // da vedere quali campi devono essere restituiti
                ({
                  date: att.date
                    ? `${moment(att.date).format("DD")}/${moment(att.date).format("MM")}/${moment(
                        att.date
                      ).format("YYYY")}`
                    : "",
                  nation: att.countryCode ? t(`countryCode.${att.countryCode}`) : "",
                  reason: att.reason ? att.reason : "",
                  machine_id: att.machineID ? att.machineID : "",
                })
              )}
              columns={[
                {
                  id: "date",
                  displayName: t("stealers.date"),
                },
                {
                  id: "nation",
                  displayName: t("stealers.nation"),
                },
                {
                  id: "reason",
                  displayName: t("stealers.reason"),
                },
                {
                  id: "machine_id",
                  displayName: t("stealers.machine_id"),
                },
              ]}
              filename={`stealers ${moment().format("YYYY-MM-DD")}`}
              separator=";"
              text={t("stealers.download_stealers_csv")}
            >
              <button type="button" className="csv-label-button" style={{ fontSize: "16px" }}>
                {t("stealers.download_stealers_csv")}
              </button>
            </CsvDownloader>
          </div>
        ) : null}
      </div>
      {showFilters ? (
        <div style={{ marginBottom: "30px" }}>
          <div>
            <div className="label-filters" style={{ marginBottom: "10px" }}>
              {t("show")}:
            </div>
            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "30px" }}>
              <div className="tabs-view-container">
                <button
                  className={`emply-label-button ${
                    stealersType === "all" ? "tab-button-selected" : ""
                  }`}
                  onClick={() => {
                    if (stealersType !== "all") {
                      setStealersType("all");
                    }
                  }}
                  type="button"
                >
                  {t("stealers.all").toLowerCase()}
                </button>
                <button
                  className={`emply-label-button ${
                    stealersType === "complete" ? "tab-button-selected" : ""
                  }`}
                  onClick={() => {
                    if (stealersType !== "complete") {
                      setStealersType("complete");
                      setPage(1);
                    }
                  }}
                  type="button"
                >
                  {t("stealers.completes").toLowerCase()}
                </button>
                <button
                  className={`emply-label-button ${
                    stealersType === "incomplete" ? "tab-button-selected" : ""
                  }`}
                  onClick={() => {
                    if (stealersType !== "incomplete") {
                      setStealersType("incomplete");
                      setPage(1);
                    }
                  }}
                  type="button"
                >
                  {t("stealers.incompletes").toLowerCase()}
                </button>
                <button
                  className="emply-label-button"
                  onClick={() => {
                    navigate("/dashboards/stealersToHandle");
                  }}
                  type="button"
                >
                  {t("stealers.to_manage").toLowerCase()}
                </button>
              </div>
            </div>
            <div className="label-filters" style={{ marginBottom: "10px" }}>
              {t("search")}:
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ display: "flex", maxWidth: "600px" }}>
                <Autocomplete
                  label={t("stealers.nation")}
                  style={{ marginRight: "10px" }}
                  onSelect={selectAutocomplete}
                  list={Object.keys(countryCode).map((el) => ({
                    label: t(`countryCode.${el}`),
                    value: el,
                  }))}
                />
                <MDInput
                  type="input"
                  label={t("stealers.reason")}
                  style={{ marginRight: "10px" }}
                  value={reason}
                  fullWidth
                  onChange={(e) => {
                    setReason(e.target.value);
                  }}
                />
                <MDInput
                  type="input"
                  label={t("stealers.hash")}
                  style={{ marginRight: "10px" }}
                  value={hash}
                  fullWidth
                  onChange={(e) => {
                    setHash(e.target.value);
                  }}
                />
                <MDInput
                  type="input"
                  label={t("stealers.originalItemId")}
                  style={{ marginRight: "10px" }}
                  value={originalItemId}
                  fullWidth
                  onChange={(e) => {
                    setOriginalItemId(e.target.value);
                  }}
                />
                {getCalendar(dateFrom, setDateFrom, t("stealers.date_from"))}
                {getCalendar(dateTo, setDateTo, t("stealers.date_to"))}
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    lineHeight: "16px",
                    marginRight: "15px",
                    fontSize: "0.8em",
                    marginLeft: "10px",
                  }}
                >
                  {t("stealers.elements_displayed")}
                </div>
                <select
                  onChange={(e) => {
                    setLimit(e.target.value);
                  }}
                  value={limit}
                  className="selectDefault"
                  style={{
                    padding: "0.75rem",
                    borderRadius: "0.375rem",
                    borderColor: "#d2d6da",
                  }}
                >
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </div>
            </div>
          </div>
          <>
            <div className="label-filters" style={{ marginBottom: "10px", marginTop: "20px" }}>
              {t("select_columns")}:
            </div>
            <div style={{ display: "flex" }}>{chooseFieldsShown()}</div>
          </>
          <div>{changeShowHidden()}</div>
        </div>
      ) : null}
      <div className="popupInfoContainer">
        {showDomains ? (
          <SweetAlert
            style={{ display: "block", position: "relative", textAlign: "left" }}
            title=""
            onConfirm={() => {
              setShowDomains(false);
              setDomainsToShow(null);
            }}
            confirmBtnText={t("close")}
            confirmBtnCssClass="popupCloseButton"
            confirmBtnStyle={{
              boxShadow: "none",
            }}
          >
            <div className="popupButtonEdit">
              <Tooltip title={t("close")} placement="top">
                <IconButton
                  onClick={() => {
                    setShowDomains(false);
                    setDomainsToShow(null);
                  }}
                  size="small"
                  disableRipple
                >
                  <Icon fontSize="medium">close</Icon>
                </IconButton>
              </Tooltip>
            </div>
            {domainsToShow.map((item, index) => (
              <p key={index.toString()}>{item}</p>
            ))}
          </SweetAlert>
        ) : null}
        <div>
          <div className="table_users_list">
            <table className="table-content" cellSpacing="0" cellPadding="0">
              <thead>
                <tr>
                  <th
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (orderBy === "dateReverse") {
                        setOrderBy("date");
                      } else {
                        setOrderBy("dateReverse");
                      }
                    }}
                  >
                    {orderBy === "date" ? (
                      <ArrowDropDownIcon
                        style={{ marginBottom: "-7px", marginRight: "15px" }}
                        fontSize="medium"
                      />
                    ) : null}
                    {orderBy === "dateReverse" ? (
                      <ArrowDropUpIcon
                        style={{ marginBottom: "-7px", marginRight: "15px" }}
                        fontSize="medium"
                      />
                    ) : null}
                    {t("stealers.date")}
                  </th>
                  {fieldsShown.includes("Nazione") ? (
                    <th
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        if (orderBy === "nation") {
                          setOrderBy("nationReverse");
                        } else {
                          setOrderBy("nation");
                        }
                      }}
                    >
                      {orderBy === "nation" ? (
                        <ArrowDropDownIcon
                          style={{ marginBottom: "-7px", marginRight: "15px" }}
                          fontSize="medium"
                        />
                      ) : null}
                      {orderBy === "nationReverse" ? (
                        <ArrowDropUpIcon
                          style={{ marginBottom: "-7px", marginRight: "15px" }}
                          fontSize="medium"
                        />
                      ) : null}
                      {t("stealers.nation")}
                    </th>
                  ) : null}
                  {fieldsShown.includes("Dominio") ? <th>{t("stealers.domain")}</th> : null}
                  {fieldsShown.includes("Tags") ? <th>{t("stealers.tags")}</th> : null}
                  {fieldsShown.includes("MachineId") ? <th>{t("stealers.machine_id")}</th> : null}
                  {fieldsShown.includes("SistemaOperativo") ? <th>{t("stealers.so")}</th> : null}
                  {fieldsShown.includes("Hash") ? (
                    <th
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        if (orderBy === "hash") {
                          setOrderBy("hashReverse");
                        } else {
                          setOrderBy("hash");
                        }
                      }}
                    >
                      {orderBy === "hash" ? (
                        <ArrowDropDownIcon
                          style={{ marginBottom: "-7px", marginRight: "15px" }}
                          fontSize="medium"
                        />
                      ) : null}
                      {orderBy === "hashReverse" ? (
                        <ArrowDropUpIcon
                          style={{ marginBottom: "-7px", marginRight: "15px" }}
                          fontSize="medium"
                        />
                      ) : null}
                      {t("stealers.hash")}
                    </th>
                  ) : null}
                  {fieldsShown.includes("Motivo") ? <th>{t("stealers.reason")}</th> : null}
                  {fieldsShown.includes("Completo") ? (
                    <th
                      style={{
                        textAlign: "center",
                      }}
                    >
                      {t("stealers.complete")}
                    </th>
                  ) : null}
                  <th
                    style={{
                      textAlign: "center",
                    }}
                  >
                    {t("stealers.info")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {isRequestReady ? (
                  <>
                    {attacks && attacks.length > 0 ? (
                      <>
                        {attacks.map((el, index) => {
                          let reasonToShow = "";
                          let domainsToPass = [];
                          el.results.forEach((item) => {
                            if (item.results) {
                              domainsToPass = [
                                ...domainsToPass,
                                item.results[Object.keys(item.results)[0]],
                              ];
                            }
                          });
                          if (el.reason) {
                            reasonToShow = el.reason;
                          }
                          return (
                            <tr key={`stealer-${index.toString()}`}>
                              <td>
                                {el.mayBeDuplicate && !el.managed ? (
                                  <Tooltip title={t("stealers.stealer_to_manage")} placement="top">
                                    <IconButton
                                      onClick={() => {
                                        window.open(`/stealersToHandle`, "_blank").focus();
                                      }}
                                      size="small"
                                      disableRipple
                                    >
                                      <Icon fontSize="medium">warning</Icon>
                                    </IconButton>
                                  </Tooltip>
                                ) : null}
                                {el.updateDate ? (
                                  <Tooltip
                                    title={`${t("stealers.stealer_edited_on")}${moment(
                                      el.updateDate
                                    ).format("DD")}/${moment(el.updateDate).format("MM")}/${moment(
                                      el.updateDate
                                    ).format("YYYY")}`}
                                    placement="top"
                                  >
                                    <IconButton size="small" disableRipple>
                                      <Icon fontSize="medium">check</Icon>
                                    </IconButton>
                                  </Tooltip>
                                ) : null}
                                {el.date
                                  ? `${moment(el.date).format("DD")}/${moment(el.date).format(
                                      "MM"
                                    )}/${moment(el.date).format("YYYY")}`
                                  : ""}
                              </td>
                              {fieldsShown.includes("Nazione") ? (
                                <td>{el.countryCode ? t(`countryCode.${el.countryCode}`) : ""}</td>
                              ) : null}
                              {fieldsShown.includes("Dominio") ? (
                                <td>
                                  {el.results && el.results.some((clie) => clie.results) ? (
                                    <button
                                      type="button"
                                      className="emply-label-button"
                                      onClick={() => {
                                        if (domainsToPass && domainsToPass.length > 0) {
                                          setShowDomains(true);
                                          setDomainsToShow(domainsToPass[0]);
                                        }
                                      }}
                                    >
                                      {getDomainShown(domainsToPass)}
                                    </button>
                                  ) : (
                                    t("stealers.no_customer_associated")
                                  )}
                                </td>
                              ) : null}
                              {fieldsShown.includes("Tags") ? (
                                <td>{el.tags && el.tags.length > 0 ? getTags(el.tags) : ""}</td>
                              ) : null}
                              {fieldsShown.includes("MachineId") ? (
                                <td>{el.machineID ? el.machineID : ""}</td>
                              ) : null}
                              {fieldsShown.includes("SistemaOperativo") ? (
                                <td>{el.OS ? el.OS : ""}</td>
                              ) : null}
                              {fieldsShown.includes("Hash") ? (
                                <td>{el.filelistHash ? el.filelistHash : ""}</td>
                              ) : null}
                              {fieldsShown.includes("Motivo") ? <td>{reasonToShow}</td> : null}
                              {fieldsShown.includes("Completo") ? (
                                <td style={{ textAlign: "center" }}>
                                  {el.tags && el.tags.length > 0 && el.tags.includes("complete") ? (
                                    <Icon fontSize="medium">check</Icon>
                                  ) : null}
                                  {el.tags &&
                                  el.tags.length > 0 &&
                                  el.tags.includes("incomplete") ? (
                                    <Icon fontSize="medium">close</Icon>
                                  ) : null}
                                </td>
                              ) : null}
                              <td style={{ textAlign: "center" }}>
                                <IconButton
                                  onClick={() => {
                                    window.open(`/detailsStealer?${el.id}`, "_blank").focus();
                                  }}
                                  className="default-button"
                                  size="small"
                                  disableRipple
                                >
                                  {t("details")}
                                </IconButton>
                                <IconButton
                                  onClick={() => {
                                    editStealer({ isHidden: !el.isHidden }, el.id)
                                      .then(() => {
                                        refreshAttacks();
                                      })
                                      // eslint-disable-next-line
                                    .catch((err) => console.log("error", err));
                                  }}
                                  size="small"
                                  disableRipple
                                >
                                  {/* {el.isHidden ? (
                                    <VisibilityOffIcon fontSize="medium" />
                                  ) : (
                                    <Icon fontSize="medium">visibility</Icon>
                                  )} */}
                                </IconButton>
                              </td>
                            </tr>
                          );
                        })}
                      </>
                    ) : (
                      <tr className="table-tr-no-results">
                        <td />
                        <td />
                        <td />
                        <td className="table-no-results-box">{t("stealers.no_stealer_yet")}</td>
                        <td />
                        <td />
                      </tr>
                      // <tr>
                      //   <td />
                      //   <td />
                      //   <td style={{ width: "100%", display: "block", padding: "10px 0" }}>
                      //     {t("stealers.no_stealer_yet")}
                      //   </td>
                      //   <td />
                      //   {user && user.role === "admin" ? (
                      //     <>
                      //       <td />
                      //       <td />
                      //     </>
                      //   ) : null}
                      // </tr>
                    )}
                  </>
                ) : (
                  <tr>
                    <td />
                    <td />
                    <td
                      style={{
                        width: "100%",
                        display: "block",
                        padding: "10px 0",
                        position: "relative",
                      }}
                    >
                      <div className="loader" />
                    </td>
                    <td />
                    <td />
                    <td />
                  </tr>
                )}
              </tbody>
            </table>
            {pages && pages > 1 ? (
              <Pagination setPage={setPage} pages={pages} active={page} />
            ) : null}
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default Stealers;
